import { Injectable } from '@angular/core';
import { Service } from '@components/shared/service';
import { catchError, map, mergeMap} from 'rxjs/operators';
import { Observable, forkJoin, throwError} from 'rxjs';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from '@services/message.service';
import { environment } from 'environments/environment';
import { FbAdSet } from '@models/fbAdSet';
import {NewsArticle} from '@models/newsArticle';
import { SlugifyPipe } from '@pipes/slugify.pipe';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService  extends Service {
  private authenticationUrl = '';
  private newsserviceUrl = '';
  private httpNoCacheOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Cache':'true' })
  };
  private httpWithCacheOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };
  private fbAdSets: FbAdSet[];

  constructor(protected http: HttpClient,
    protected messageService: MessageService,
    private slugifyPipe: SlugifyPipe) {
    super(http, messageService);
    this.authenticationUrl = environment.authBaseUrl;
    this.newsserviceUrl = environment.apiBaseUrl;
 }

  registerUser(newUserName: string, newEmail: string, newPassword: string) {
    return this.http.post(
      this.authenticationUrl + 'api/User/',
      {userName: newUserName, email: newEmail, password: newPassword},
      {headers: {'No-Auth':'True'}}).pipe(
      catchError(this.handleError)
    );
  }

  changePassword(currentPassword: string, newPassword: string) {
    return this.http.put<any>(
      this.authenticationUrl + 'api/User/', {oldPassword: currentPassword, password: newPassword}, this.httpNoCacheOptions).pipe(
      catchError(this.handleError)
    );
  }

  login (loginDetails ) {
    return this.http.post(
      this.authenticationUrl + 'connect/token',
      'userName=' + encodeURIComponent(loginDetails.username) +
      '&password=' + encodeURIComponent(loginDetails.password) +
      '&grant_type=password' +
      '&client_id=spa' +
      '&client_secret=',
      {headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth':'True'}}).pipe(
      catchError(this.handleError)
    );
  }

  getUserInfo () {
    return this.http.get(
      this.authenticationUrl + 'connect/userinfo', this.httpNoCacheOptions).pipe(
      catchError(this.handleError)
    );
  }

  getStories(id: any): Observable<any[]> {
    return this.http.get<any[]>(
      this.newsserviceUrl + 'api/articleLink/'+id, this.httpNoCacheOptions).pipe(
      catchError(this.handleGetStoriesError)
      );
  }

  putStory(article: any): Observable<any> {
    return this.http.put<any>(
      this.newsserviceUrl + 'api/articleLink/'+article.article.articleId, article, this.httpNoCacheOptions).pipe(
      catchError(this.handleGetStoriesError)
    );
  }

  putStorySection(article: any): Observable<any> {
    console.log("putStorySection; articleId = " + article.articleId);
    return this.http.put<any>(
      this.newsserviceUrl + 'api/articleLink/section/'+article.articleId, article, this.httpNoCacheOptions).pipe(
      catchError(this.handleGetStoriesError)
    );
  }

  postPublishStory(article: any): Observable<any> {
    return this.http.post<any>(
      this.newsserviceUrl + 'api/articleLink/publish', article, this.httpNoCacheOptions).pipe(
      catchError(this.handleGetStoriesError)
    );
  }

  putFeaturedStories(articleID: any, frontPageSlot: any): Observable<any> {
    return this.http.put<any>(
      this.newsserviceUrl + 'api/featuredStories/'+frontPageSlot, {
        "id": frontPageSlot,
        "slotNumber": frontPageSlot,
        "storyId": articleID
    }, this.httpNoCacheOptions).pipe(
      catchError(this.handleGetStoriesError)
    );
  }

  postStory(article: any): Observable<any> {
    return this.http.post<any>(
      this.newsserviceUrl + 'api/articleLink/', article, this.httpNoCacheOptions ).pipe(
      catchError(this.handleGetStoriesError)
    );
  }

  handleGetStoriesError(error: HttpErrorResponse){
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong.

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.',
      error
    };
    return throwError(this.errorData);
  }

  getUsers(): Observable<any[]> {
    return this.http.get<any[]>(
      this.authenticationUrl + 'api/User/', this.httpNoCacheOptions).pipe(
        catchError(this.handleError)
      );
  }

  getUserRoles(userId: any): Observable<any[]> {
    return this.http.get<any[]>(
      this.authenticationUrl + 'api/Role/' + userId, this.httpNoCacheOptions).pipe(
        catchError(this.handleError)
      );
  }

  getUserWithRoles(): Observable<any[]> {
    return this.http.get<any>(this.authenticationUrl + 'api/User/', this.httpNoCacheOptions).pipe(
      mergeMap((users: any[]) => {
        return forkJoin(
          users.map(user => {
            return this.http.get<any>( this.authenticationUrl + 'api/Role/' + user.id, this.httpNoCacheOptions)
              .pipe(
                map((roles: any) => {
                  user.role = roles;
                  return user;
                })
              );
          })
        )
      }));
  }

  putUserRole(userId: any, userRole: any): Observable<any> {
    return this.http.put<any>(
      this.authenticationUrl + 'api/Role/' + userId,
      {id: userId, role: userRole}, this.httpNoCacheOptions).pipe(
      catchError(this.handleGetStoriesError)
    );
  }

  postFacebook(article: any): Observable<any> {
    return this.http.post<any>(
      this.newsserviceUrl + 'api/socialmedia/facebook', article, this.httpNoCacheOptions).pipe(
      catchError(this.handleGetStoriesError)
    );
  }

  deleteFacebook(article: any): Observable<any> {
    return this.http.delete<any>(
      this.newsserviceUrl + 'api/socialmedia/facebook/' + article.articleId, this.httpNoCacheOptions).pipe(
      catchError(this.handleGetStoriesError)
    );
  }
  
  getAdsByFbPostId(fbPostId: any): Observable<any[]> {
    return this.http.get<any[]>(
      this.newsserviceUrl + 'api/socialmedia/facebook/ad/' + fbPostId, this.httpNoCacheOptions).pipe(
        catchError(this.handleError)
      );
  }

  // Cache since not expected to change.
  getFbAdsetsFromService(): Observable<any[]> {
    return this.http.get<any[]>(
      this.newsserviceUrl + 'api/socialmedia/facebook/adset/', this.httpWithCacheOptions).pipe(
        catchError(this.handleError)
      );
  }

  getFbAdsets(){
    return this.fbAdSets;
  }

  getFbAdSetName(FbAdSetId: string): string {
    let foundfbAdSet = null;
    if (this.fbAdSets) {
      foundfbAdSet = this.fbAdSets.find(x => x.id == FbAdSetId);
    }
    if (foundfbAdSet) {
      return foundfbAdSet.name;
    } else {
      return FbAdSetId;
    }
  }
  
  postFbAd(fbAd: any): Observable<any> {
    return this.http.post<any>(
      this.newsserviceUrl + 'api/socialmedia/facebook/ad/', fbAd, this.httpNoCacheOptions ).pipe(
    );
  }

  deleteFbAd(fbAdId: any): Observable<any> {
    return this.http.delete<any>(
      this.newsserviceUrl + 'api/socialmedia/facebook/ad/' + fbAdId, this.httpNoCacheOptions).pipe(
      catchError(this.handleGetStoriesError)
    );
  }

  getFbAdStatus(paginate: any): Observable<any> {
    return this.http.get<any>(
      this.newsserviceUrl + 'api/socialmedia/facebook/ad/status/' + paginate, this.httpNoCacheOptions).pipe(
        catchError(this.handleError)
    );
  }

  postGetArticlebyArticleUrl(articleUrl: any): Observable<any> {
    return this.http.post<any>(
      this.newsserviceUrl + 'api/articleLink/articleUrl/', {articleUrl: articleUrl},this.httpNoCacheOptions).pipe(
    );
  }
  
  postPrepareData(): Observable<any> {
    return this.http.post<any>(
      this.newsserviceUrl + 'api/ChinaStories/sections/prepareData', this.httpNoCacheOptions).pipe();
  }

  postPrepareStories(): Observable<any> {
    return this.http.post<any>(
      this.newsserviceUrl + 'api/Stories/prepareStories', this.httpNoCacheOptions).pipe();
  }

  postGetArticleContentByUrl(articleUrl: any): Observable<any> {
    return this.http.post<NewsArticle>(
      this.newsserviceUrl + 'api/url/content/', {articleUrl: articleUrl},this.httpNoCacheOptions).pipe(
    );
  }

  postGetArticleSummaryByUrl(articleUrl: any): Observable<any> {
    return this.http.post<any>(
      this.newsserviceUrl + 'api/url/summary/', {articleUrl: articleUrl},this.httpNoCacheOptions).pipe(
    );
  }

  postGetArticleQuoteByUrl(articleUrl: any): Observable<any> {
    return this.http.post<any>(
      this.newsserviceUrl + 'api/url/quote/', {articleUrl: articleUrl},this.httpNoCacheOptions).pipe(
    );
  }

  // Cache since not expected to change.
  getNineCommentariesList(): Observable<any[]> {
    return this.http.get<any[]>(
      this.newsserviceUrl + 'api/admin/nineCommentariesList/', this.httpWithCacheOptions).pipe(
        catchError(this.handleError)
      );
  }

  loadFbAdSets() {
    this.getFbAdsetsFromService()
    .subscribe(
      fbAdSets => {
        this.fbAdSets = fbAdSets;
      }
    );
  }

  /** GET top quotes last 7 days */
  getTopQuotesLast7Days(): Observable<any[]> {
    return this.http.get<any[]>(this.newsserviceUrl + 'api/Stories/quote/last7days', this.httpNoCacheOptions)
    .pipe(
      map((topQuotes: any) => this.setArticleTitleSlug(topQuotes))
    );
  }

  putStoryQuoteWeight(storyQuote: any): Observable<any> {
    return this.http.put<any>(
      this.newsserviceUrl + 'api/Stories/quote/weight/'+storyQuote.id, storyQuote, this.httpNoCacheOptions).pipe(
      catchError(this.handleGetStoriesError)
    );
  }

  private setArticleTitleSlug(newsArticles: any) {
    return newsArticles.map((newsArticle) => {
      newsArticle.articleTitleSlug = this.slugifyPipe.transform(newsArticle.articleTitle);
      return newsArticle;
    });
  }

  postTwitter(article: any): Observable<any> {
    return this.http.post<any>(
      this.newsserviceUrl + 'api/socialmedia/twitter', article, this.httpNoCacheOptions).pipe(
    );
  }

  deleteTwitter(article: any): Observable<any> {
    return this.http.delete<any>(
      this.newsserviceUrl + 'api/socialmedia/twitter/' + article.articleId, this.httpNoCacheOptions).pipe(
    );
  }

  getPageViaSSR(articleUrl: string): Observable<any> {    
    return this.http.post<any[]>(this.newsserviceUrl + 'api/socialmedia/cacheStory/', {
        "articleUrl": articleUrl
      },
      this.httpNoCacheOptions
    )
    .pipe(
      catchError(this.handleError)
    );
  }
}
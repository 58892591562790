import { Component, Input } from '@angular/core';

@Component({
  selector: 'csl-spinner',
  templateUrl: './spinner.component.html'
})
export class SpinnerComponent {
  @Input() size: number = 25;
  @Input() show: boolean;

}

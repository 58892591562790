import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatTabsModule } from '@angular/material/tabs';
import { Globals } from 'app/globals';
import { HomeComponent } from '@components/home/home.component';
import { TopSlimBannerComponent } from '@components/top-slim-banner/top-slim-banner.component';
import { TopLogoBannerComponent } from '@components/top-logo-banner/top-logo-banner.component';
import { TopNavigationMenuComponent } from '@components/top-navigation-menu/top-navigation-menu.component';
import { AboutUsComponent } from '@components/about-us/about-us.component';
import { FlickrWidgetComponent } from '@components/flickr-widget/flickr-widget.component';
import { CommentWidgetComponent } from '@components/comment-widget/comment-widget.component';
import { NewsLetterWidgetComponent } from '@components/news-letter-widget/news-letter-widget.component';
import { ShortFullWidthAdvertComponent } from '@components/short-full-width-advert/short-full-width-advert.component';
import { FooterComponent } from '@components/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeaturePostMainComponent } from '@components/feature-post-main/feature-post-main.component';
import { StoryPageComponent } from '@components/story-page/story-page.component';
import { ThisIsRisingComponent } from '@components/this-is-rising/this-is-rising.component';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { AboutUsPageComponent } from '@components/about-us-page/about-us-page.component';
import { ContactUsComponent } from '@components/contact-us/contact-us.component';
import { PrivacyComponent } from '@components//privacy/privacy.component';
import { RegisterComponent } from '@components/admin/register/register.component';
import { SpinnerComponent } from '@components/shared/spinner/spinner.component';
import { LoginComponent } from '@components/admin/login/login.component';
import { ManageStoriesComponent } from '@components/admin/manage-stories/manage-stories.component';
import { EditStoryFacebookComponent } from '@components/admin/edit-story-facebook/edit-story-facebook.component';
import { AuthGuard } from 'app/auth/auth.guard';
import { RoleAuthGuard } from 'app/auth/role.auth.guard';
import {AuthInterceptor} from 'app/auth/auth.interceptor'
import { SlugifyPipe } from '@pipes/slugify.pipe';
import { SafePipe } from '@pipes/safe.pipe';
import { SafeHtmlPipe } from '@pipes/safe-html.pipe';
import { MediaNetAdComponent } from '@components/media-net-ad/media-net-ad.component';
import { ManageUsersComponent } from '@components/admin/manage-users/manage-users.component';
import { AdminHeaderComponent } from '@components/admin/admin-header/admin-header.component';
import { ChangePasswordComponent } from '@components/admin/change-password/change-password.component';
import { MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon'
import { EditCreateStoryComponent } from '@components/admin/edit-create-story/edit-create-story.component';
import { ManageSubscribersComponent } from '@components/admin/manage-subscribers/manage-subscribers.component';
import { ManageCommentsComponent } from '@components/admin/manage-comments/manage-comments.component';
import { MatSelectModule} from '@angular/material/select';
import { HomeLatestNewsComponent } from '@components/home-latest-news/home-latest-news.component';
import { MediumPostComponent } from '@components/medium-post/medium-post.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HomeSectionThreeBoxesComponent } from '@components/home-section-three-boxes/home-section-three-boxes.component';
import { HomeSectionFourBoxesComponent } from '@components/home-section-four-boxes/home-section-four-boxes.component';
import { HomeSectionFiveBoxesComponent } from '@components/home-section-five-boxes/home-section-five-boxes.component';
import { SmallPostComponent } from '@components/small-post/small-post.component';
import { PostComponent } from '@components/post/post.component';
import { FourStoriesBlockOneImageComponent } from '@components/four-stories-block-one-image/four-stories-block-one-image.component';
import { CountryPanelComponent } from '@components/country-panel/country-panel.component';
import { TruncateTextPipe } from '@pipes/truncate-text.pipe';
import { DateAgoPipe } from '@pipes/date-ago.pipe';
import { HomeVerticalSectionFiveComponent } from '@components/home-vertical-section-five/home-vertical-section-five.component';
import { ManageVideosComponent } from '@components/admin/manage-videos/manage-videos.component';
import { EditVideoComponent } from '@components/admin/edit-video/edit-video.component';
import { HomeVideoComponent } from '@components/home-video/home-video.component';
import { MediumVideoPostComponent } from '@components/medium-video-post/medium-video-post.component';
import { SmallVideoPostComponent } from '@components/small-video-post/small-video-post.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { CacheInterceptor } from 'app/interceptor/cache.interceptor';
import { ConfirmDialogComponent } from '@modals/confirm-dialog/confirm-dialog.component';
import { MessageDialogComponent } from '@modals/message-dialog/message-dialog.component';
import { FacebookAdStatusComponent } from '@components/admin/facebook-ad-status/facebook-ad-status.component';
import { NewsArticleService } from '@services/news-article.service';
import { StoryPageSectionComponent } from '@components/story-page-section/story-page-section.component';
import { PreviewStoryComponent } from '@components/admin/preview-story/preview-story.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TopQuotesComponent } from '@components/top-quotes/top-quotes.component';
import { EditQuoteComponent } from '@components/admin/edit-quote/edit-quote.component';
import { EditStoryTwitterComponent } from '@components/admin/edit-story-twitter/edit-story-twitter.component';
import { SubscribeNewsletterComponent } from '@components/subscribe-newsletter/subscribe-newsletter.component';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TopSlimBannerComponent,
    TopLogoBannerComponent,
    TopNavigationMenuComponent,
    AboutUsComponent,
    FlickrWidgetComponent,
    CommentWidgetComponent,
    NewsLetterWidgetComponent,
    ShortFullWidthAdvertComponent,
    FooterComponent,
    FeaturePostMainComponent,
    StoryPageComponent,
    ThisIsRisingComponent,
    AboutUsPageComponent,
    ContactUsComponent,
    PrivacyComponent,
    RegisterComponent,
    SpinnerComponent,
    LoginComponent,
    ManageStoriesComponent,
    EditStoryFacebookComponent,
    SlugifyPipe,
    SafeHtmlPipe,
    SafePipe,
    MediaNetAdComponent,
    ManageUsersComponent,
    AdminHeaderComponent,
    ChangePasswordComponent,
    EditCreateStoryComponent,
    ManageCommentsComponent,
    ManageSubscribersComponent,
    HomeLatestNewsComponent,
    MediumPostComponent,
    HomeSectionThreeBoxesComponent,
    HomeSectionFourBoxesComponent,
    HomeSectionFiveBoxesComponent,
    SmallPostComponent,
    PostComponent,
    TruncateTextPipe,
    FourStoriesBlockOneImageComponent,
    CountryPanelComponent,
    DateAgoPipe,
    HomeVerticalSectionFiveComponent,
    ManageVideosComponent,
    EditVideoComponent,
    HomeVideoComponent,
    MediumVideoPostComponent,
    SmallVideoPostComponent,
    ConfirmDialogComponent,
    MessageDialogComponent,
    FacebookAdStatusComponent,
    StoryPageSectionComponent,
    PreviewStoryComponent,
    TopQuotesComponent,
    EditQuoteComponent,
    EditStoryTwitterComponent,
    SubscribeNewsletterComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatDividerModule,
    MatSelectModule,
    BrowserAnimationsModule,
    CarouselModule,
    MatTabsModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    YouTubePlayerModule,
    NgSelectModule,
    InfiniteScrollModule,
    QuillModule.forRoot()

    // The HttpClientInMemoryWebApiModule module intercepts HTTP requests
    // and returns simulated server responses.
    // Remove it when a real server is ready to receive requests.
//    HttpClientInMemoryWebApiModule.forRoot(
//      InMemoryDataService, { dataEncapsulation: false })
  ],
  providers: [Globals, AuthGuard, RoleAuthGuard, SlugifyPipe, SafeHtmlPipe, SafePipe, NewsArticleService,{
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    // { provide: APP_INITIALIZER,
    //   useFactory: init_app,
    //   deps: [NewsArticleService],
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {  }

// export function init_app(newsArticleService: NewsArticleService) {
//   return () => newsArticleService.loadSections();
// }

export class NewsArticle {
  id: number;
  articleDomain: string;
  articleIntro: string;
  articleTitle: string;
  articleUrl: string;
  articleSection: string;
  articleBody: string;
  paragraph1: string;
  paragraph2: string;
  paragraph3: string;
  dateLoaded: Date;
  articleImage: string;
  featured: boolean;
  commentCount: number;
  likesCount: number;
  viewsCount: number;
  imageHeight: number;
  imageWidth: number;
  articleTitleSlug?: string;
  fbPostId?: string;
  fbPostDescription?: string;
  featuredStorySlot: number;
  relArticleId?: number;
  articleText?: string;
  routeGoal?: string;
  publishedFlag?: number;
  twitterPostId?: string;
  twitterPostDescription?: string;
}

import { Component, ViewEncapsulation , OnInit, PLATFORM_ID, Inject, Input } from '@angular/core';
import { NewsArticleService } from '@services/news-article.service';
import {NewsArticle} from '@models/newsArticle';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscribeNewsletterComponent } from '@components/subscribe-newsletter/subscribe-newsletter.component';
import { SubscribeTimerService } from '@services/subscribe-timer.service';
import { Subscription } from 'rxjs'; 

@Component({
  selector: 'csl-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  @Input()
  newsArticles: NewsArticle[];
  private timerSubscription: Subscription = Subscription.EMPTY;

  constructor(private newsArticleService: NewsArticleService,
              @Inject(PLATFORM_ID) private platform: Object,
              private modalService: NgbModal,
              private subscribeTimerService: SubscribeTimerService) { }

  ngOnInit() {
    //Only subscribe to the timer when it has not been prompted
    if (!this.subscribeTimerService.getSubcribePrompted() ) {
      this.timerSubscription = this.subscribeTimerService.subscribeTimerOver.subscribe((res) => {
        if (res) {
          this.goToSubscribe();
          this.subscribeTimerService.setSubcribePrompted();
        }
      })
    }
  }

  ngOnDestroy() {
    this.timerSubscription.unsubscribe();
  }

  goToSubscribe(): void {
    this.modalService.open(SubscribeNewsletterComponent, { centered: true, size: 'md', modalDialogClass: 'subscribe-modal' });
  }

}

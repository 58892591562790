import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscribeTimerService {
  private _subscribeTimerOver$ = new BehaviorSubject(false);
  private _subcribePrompted = false;
  private _subscribetimer = timer(30000);

  constructor() {
    this._subscribetimer.subscribe((res) => {
      this._subscribeTimerOver$.next(true);
    })
  }

  public get subscribeTimerOver(): Observable<boolean> {
    return this._subscribeTimerOver$;
  }
  
  public getSubcribePrompted(): boolean {
    return this._subcribePrompted;
  }

  public setSubcribePrompted() {
    this._subcribePrompted = true;
  }
}

import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  ConfirmDialogComponent
} from './confirm-dialog/confirm-dialog.component';
import {
  MessageDialogComponent
} from './message-dialog/message-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private ngbModal: NgbModal) { }

  confirm(
    prompt = 'Really?', title = 'Confirm'
  ): Observable<boolean> {
    const modal = this.ngbModal.open(
      ConfirmDialogComponent, { backdrop: 'static' });

    modal.componentInstance.prompt = prompt;
    modal.componentInstance.title = title;

    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }

  message(
    message: string, title = 'Message', messageClass = "", messageUrl = "", messageUrlTitle = ""
  ): Observable<boolean> {
    const modal = this.ngbModal.open(
      MessageDialogComponent, { backdrop: 'static' });

    modal.componentInstance.message = message;
    modal.componentInstance.title = title;
    modal.componentInstance.messageClass = messageClass;
    modal.componentInstance.messageUrl = messageUrl;
    modal.componentInstance.messageUrlTitle = messageUrlTitle;

    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }
}

<div class="modal-header bg-primary text-white">
    <h4 *ngIf="isEditMode" class="modal-title" id="modal-basic-title">View Tweet</h4>
    <h4 *ngIf="!isEditMode" class="modal-title" id="modal-basic-title">Post Tweet</h4>
</div>
<div class="modal-body">
            <div class="entry-header">
            <div class="entry-thumbnail">
                <img class="img-fluid" src='{{selectedNewsArticle?.articleImage}}' alt="" />
            </div>
            </div>
            <div  class="post-content">
            <h2>
                {{selectedNewsArticle?.articleTitle}}
            </h2>
            </div>
    <form [formGroup]="editForm" (ngSubmit)="onSubmit()" *ngIf="editForm">
        <input type="hidden" formControlName="articleId" class="form-control" readonly />
        <div *ngIf="isEditMode" class="section business-section">
            <h1 class="section-title">Facebook Post</h1>
            <div class="form-group row d-flex justify-content-center align-items-center">
                <label for="twitterPostId" class="col-form-label col-sm">Tweet ID:</label>
                <div class="col">
                    <a target="_blank" href="https://twitter.com/chinaspotlight1/status/{{selectedNewsArticle?.twitterPostId}}">{{selectedNewsArticle?.twitterPostId}}</a>
                    <input type="hidden" formControlName="twitterPostId" class="form-control" readonly />
                </div>
                <div class="col">
                    <button type="button" *ngIf="isEditMode" [disabled]="isLoading" class="btn btn-danger mr-2" (click)="onDelete()">Delete</button>
                </div>
            </div>
        </div>
        <div *ngIf="!isEditMode" class="form-group row d-flex justify-content-center align-items-center">
            <label for="twitterPostDescription" class="col-form-label col-sm-2 text-right">Twitter Post Description:</label>
            <div class="col-sm-10">
                <textarea formControlName="twitterPostDescription" class="form-control full-height" rows="8" (keyup)="characterCounter()"></textarea>
                <div *ngIf="editFormData.twitterPostDescription.hasError('required') && (editFormData.twitterPostDescription.dirty || editFormData.twitterPostDescription.touched)"
                    class="alert alert-danger">
                    <span>Twitter post description is required!</span>
                </div>
                <div *ngIf="editFormData.twitterPostDescription.hasError('maxlength')"
                    class="alert alert-danger">
                    <span>Maximum number of charactes exceeed!</span>
                </div>
                <div style="font-style: italic;">
                    Characters: {{ characterCount }} of 280
                  </div>
            </div>
        </div>
        <div *ngIf="!isEditMode" class="modal-footer">
            <button type="button" [disabled]="isLoading" class="btn btn-secondary mr-2" (click)="modal.close('Cancel')">Cancel</button>
            <button [disabled]="isLoading || editForm.invalid" class="btn btn-primary mr-2">Add</button>
            <csl-spinner  [show]="isLoading" [size]="500"> </csl-spinner>
        </div>
        <div *ngIf="twitterPostErrorFlag">
            <div class="alert alert-danger">
                <span>Error occured: {{twitterPostErrorMessage}}</span>
            </div>
        </div>
    </form>
    <div *ngIf="isEditMode" class="modal-footer">
        <button type="button" [disabled]="isLoading" class="btn btn-secondary mr-2" (click)="modal.close('Back')">Back</button>
    </div>
</div>
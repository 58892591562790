<csl-admin-header></csl-admin-header>

<body style="padding-top:20px">
    <div class="col-md-10 col-md-offset-1">
        <div id="divData" class="well hidden">
            <table  class="table table-bordered" id="tblData" aria-describedby="Manage Users">
                <thead>
                    <tr  class="success">
                      <th scope="col">User ID</th>
                      <th scope="col">Username</th>
                      <th scope="col">Email</th>
                      <th scope="col">Role</th>
                    </tr>
                    <csl-spinner  [size]="150"> </csl-spinner>
                    <tr *ngIf="showSpinner">
                        <td><csl-spinner [show]="showSpinner" [size]="150"> </csl-spinner></td>
                        <td><csl-spinner [show]="showSpinner" [size]="150"> </csl-spinner></td>
                        <td><csl-spinner [show]="showSpinner" [size]="150"> </csl-spinner></td>
                        <td><csl-spinner [show]="showSpinner" [size]="150"> </csl-spinner></td>
                    </tr>
                
                </thead>
                    <tr *ngFor="let user of users">
                      <td>{{user.id}}</td>
                      <td>{{user.userName}}</td>
                      <td>{{user.email}}</td>
                      <td>{{user.role}} <div class="btn-group">
                          <button type="button" (click)="modifyRole(user.id, 'Reader')" class="btn btn-outline-primary">Reader</button>
                          <button type="button" (click)="modifyRole(user.id, 'Editor')" class="btn btn-outline-primary">Editor</button>
                          <button type="button" (click)="modifyRole(user.id, 'SystemAdmin')" class="btn btn-outline-primary">SystemAdmin</button></div>
                        </td>
                    </tr>
                <tbody id="tblBody"></tbody>
            </table>
        </div>
        <div class="modal fade" tabindex="-1" id="errorModal"
              data-bs-keyboard="false" data-bs-backdrop="static">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-bs-dismiss="modal">
                            &times;
                        </button>
                        <h4 class="modal-title">Session Expired</h4>
                    </div>
                    <div class="modal-body">
                        <form>
                            <h2 class="modal-title">Close this message to login again</h2>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger"
                                data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div id="divError" class="alert alert-danger collapse">
            <a id="linkClose" href="#" class="close">&times;</a>
            <div id="divErrorText"></div>
        </div>
    </div>
  </body>
  
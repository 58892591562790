<div id="main-wrapper" class="homepage-two fixed-nav">
  <csl-top-slim-banner></csl-top-slim-banner>
  <div id="navigation">
    <csl-top-logo-banner></csl-top-logo-banner>
    <csl-top-navigation-menu></csl-top-navigation-menu>
  </div><!--/#navigation-->



  <div class="container">
    <div class="page-breadcrumbs">
      <h1 class="section-title title">Contact Us</h1>
    </div>
    <div class="row">
      <div class="col-md-8 col-lg-9 tr-sticky contact-content">
        <div class="contact-us theiaStickySidebar">
          <div class="map-section">
            <iframe title="ChinaSpotlight head office location map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9528.86304626843!2d-6.268624185346467!3d53.339393793850554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48670e992e73ac2d%3A0xa0442f3f43b25682!2sSt%20Stephen&#39;s%20Green%2C%20Dublin!5e0!3m2!1sen!2sie!4v1587835431584!5m2!1sen!2sie" width="100%" height="450" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
          </div>
          <div class="contact-info">
            <h1 class="section-title title">Contact Information</h1>
            <ul class="list-inline">
              <li>
                <h2>Head Office</h2>
                <address>
                  <br> Dublin<br>Ireland
                  <p class="contact-mail"><strong>Email:</strong> <a href="mailto:contactus@chinaspotlight.net">ContactUs@ChinaSpotlight.Net</a></p>
                  
                </address>
              </li>
              
            </ul>
          </div>
          <div class="message-box">
            <h1 class="section-title title">Drop Your Message</h1>
            <form (ngSubmit)="onSubmit()" [formGroup]="feedbackForm">
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" name="name" class="form-control" formControlName="readerName" required="required">
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="email" name="email" class="form-control" formControlName="email" required="required">
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Subject</label>
                    <input type="text" name="subject" class="form-control" formControlName="subject" >
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>Your Text</label>
                    <textarea name="comment" id="comment" required="required" class="form-control" formControlName="text"  rows="5"></textarea>
                  </div>
                  <div class="text-right">
                    <button type="submit" class="btn btn-success"  [disabled]="!feedbackForm.valid || submitted"  >Send</button>
                  </div>
                  <div class="alert alert-success" *ngIf="submitted">
                    Thank you for your feedback, one of the team will be in touch shortly.
                  </div>
                  <div class="alert alert-danger" *ngIf="error">
                    Sorry we encountered error when trying to send your feedback. Please try again later!
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div><!-- contact-us -->
      </div>
      <div class="col-md-4 col-lg-3 tr-sticky">
        <div id="sitebar" class="theiaStickySidebar">


            <div class="widget follow-us">
              <h1 class="section-title title">Follow Us</h1>
              <ul class="list-inline social-icons">
                <li><a href="https://www.facebook.com/chinaspotlights"><span class="fa fa-facebook-square fa-2x"></span></a></li>
                <li><a href="https://twitter.com/chinaspotlight1"><span class="fa fa-twitter-square fa-2x"></span></a></li>
              </ul>
            </div><!--follow us-->



          <csl-this-is-rising></csl-this-is-rising><!--this is rising widget -->

        </div><!--/#sitebar-->
      </div>
    </div><!-- row -->
  </div><!--/.container-->




  <csl-footer></csl-footer>
</div><!--/#main-wrapper-->

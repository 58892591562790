import { NewsArticle } from './newsArticle';
import { ArticleQuote } from './articleQuote';
import { ArticleSummary } from './articleSummary';

export class ArticleWithExtension {
  article: NewsArticle;
  articleQuotes: ArticleQuote[];
  articleSummaries: ArticleSummary[];
  articleText: string;
}

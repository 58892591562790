import { Component, OnInit, Input } from '@angular/core';
import { NewsArticleService } from '@services/news-article.service';
import { Subscriber } from '@models/subscriber';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'csl-news-letter-widget',
  templateUrl: './news-letter-widget.component.html'
})
export class NewsLetterWidgetComponent implements OnInit {
  @Input() 
  titleText: string;
  
  model: Subscriber;
  error = false;
  emailForm: UntypedFormGroup;
  submitted = false;
  showSpinner = false;
  subscribeSuccess = '';
  subscribeError = false;

  constructor(private newsArticleService: NewsArticleService, private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.emailForm = this.fb.group(
      {
        email: [
          null,
          Validators.compose([Validators.email])
        ]
      }
    );
    this.onChanges();
  }

  get email() {
    if (this.emailForm) {
      return this.emailForm.get('email');
    }
    return null;
  }

  onSubmit() {
    this.showSpinner = true;
    this.subscribeError = false;
    this.subscribeSuccess = '';
    console.log('email='+this.emailForm.get('email').value);
    this.newsArticleService.postSubscription(this.emailForm.get('email').value).subscribe(
      val => {
        console.log('Response=' + JSON.stringify(val));
        this.submitted = true;
        this.error = false;
        this.subscribeSuccess = "Successfully subscribed. Thank you!";
        this.showSpinner = false;
      }, err => {
        if (err.error.status == 409) {
          this.subscribeSuccess = "Thank you, you have already subscribed to our newsletter!";
        } else {
          this.subscribeError = true;
        }
        this.showSpinner = false;
      }
    );
  }

  onChanges(): void {
    this.emailForm.valueChanges.subscribe(_val => {
      this.subscribeError = false;
      this.subscribeSuccess = '';
    });
  }
}

declare const $: any;

import { Component, OnInit, Input, PLATFORM_ID, Inject, OnChanges} from '@angular/core';
import {NewsArticle} from '@models/newsArticle';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'csl-flickr-widget',
  templateUrl: './flickr-widget.component.html'
})
export class FlickrWidgetComponent implements OnInit, OnChanges{
  @Input() newsArticles: NewsArticle[] = [];

  galleryArticles: NewsArticle[] = [];

  constructor(@Inject(PLATFORM_ID) private platform: Object) { }

  ngOnInit() {
    this.getGalleryStories(this.newsArticles);
    this.initMagnificPopup();
  }

  ngOnChanges() {
    this.getGalleryStories(this.newsArticles);
    this.initMagnificPopup();
  }

  getGalleryStories(arrayOfArticles: any) {
    let count = 0;
    this.galleryArticles = [];
    for (const newsItem of arrayOfArticles) {
      if (newsItem.articleImage.length > 0 && count < 8) {
          // add to the array of section articles.
          this.galleryArticles.push(newsItem);
          count++;
      }
    }
  }

  initMagnificPopup() {
    if (isPlatformBrowser(this.platform)) {
      $(document).ready(function ($) {
        $('.image-link').magnificPopup({type:'image'});
        $('.image-link').magnificPopup({
          gallery: {
          enabled: true
          },
          type: 'image'
        });
        $('.feature-image .image-link').magnificPopup({
          gallery: {
            enabled: false
          },
          type: 'image'
        });
        $('.image-popup').magnificPopup({
          type: 'image'
        });
        $('.video-link').magnificPopup({type:'iframe'});
      });
    }
  }
}

<a *ngIf="newsArticle"  [routerLink]="['/story', newsArticle?.id, newsArticle?.articleTitleSlug]" routerLinkActive="active">
    <div class="post medium-post">
        <div class="entry-header">
            <div class="entry-thumbnail">
                <img class="img-fluid" [ngStyle]="{'max-height': this.articleHeight+'px'}" [ngStyle]="{'max-width': this.articleWidth+'px'}" src="{{newsArticle?.articleImage}}" alt="Image" />
            </div>
            <div *ngIf="showSectionLabel=='true'" class="catagory {{newsArticle?.articleSection}}"><span><a href="#">{{newsArticle?.articleSection}}</a></span></div>
        </div>
        <div class="post-content">
            <div class="entry-meta">
                <ul class="list-inline">
                    <li class="publish-date"><a href="#"><em class="fa fa-clock-o"></em> {{newsArticle?.dateLoaded | dateAgo}} </a></li>
                    <li class="views"><a href="#"><em class="fa fa-eye"></em>{{newsArticle?.viewsCount}}</a></li>
                    <li class="loves"><a href="#"><em class="fa fa-heart-o"></em>{{newsArticle?.likesCount}}</a></li>
                </ul>
            </div>
            <h2 class="entry-title postHeadline">
                <a href="#">
                    {{newsArticle?.articleTitle | truncatetext:textLimit}}
                </a>
            </h2>
        </div>
    </div><!--/post-->
</a>

<div class="section lifestyle-section">
    <h1 class="section-title">{{articleSectionDescription}}</h1>
    <div class="cat-menu">         
        <a routerLink="/section/{{articleSection}}" routerLinkActive="active">See all</a>					
    </div>
    <div class="row">
        <div class="col-lg-6">
            <ng-container *ngFor="let newsArticle of newsArticles; index as i; first as isFirst">
                <csl-medium-post *ngIf="i<2" [newsArticle] ="newsArticle" textLimit="100" showSectionLabel="false"></csl-medium-post>
            </ng-container>
        </div>
        <div class="col-lg-6">
            <ng-container *ngFor="let newsArticle of newsArticles; index as i; first as isFirst">
                <csl-medium-post *ngIf="i>1 && i<4" [newsArticle] ="newsArticle" textLimit="100" showSectionLabel="false"></csl-medium-post>
            </ng-container>
        </div>
    </div>
</div><!--/.lifestyle-section -->
import { Component, Input } from '@angular/core';
import { NewsArticle } from '@models/newsArticle';

@Component({
  selector: 'csl-home-vertical-section-five',
  templateUrl: './home-vertical-section-five.component.html'
})
export class HomeVerticalSectionFiveComponent {
  @Input()
  newsArticles: NewsArticle[];

  @Input()
  articleSection: string;

  @Input()
  articleSectionDescription: string;

}

import { Component, OnInit,  ViewChild} from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {Sort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { NewsArticleService } from '@services/news-article.service';
import { NewsArticle } from '@models/newsArticle';
import { NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { EditStoryFacebookComponent } from '@components/admin/edit-story-facebook/edit-story-facebook.component';
import { FacebookAdStatusComponent } from '@components/admin/facebook-ad-status/facebook-ad-status.component';
import { EditStoryTwitterComponent } from '@components/admin/edit-story-twitter/edit-story-twitter.component';
import { SlugifyPipe } from '@pipes/slugify.pipe';
import { Globals } from 'app/globals';

export interface FilterData {
  showDetailsFlag: number;
}

enum SearchTypesEnum {
  SearchArticleId = 'Search ID',
  SearchText = 'Search Text'
}

@Component({
  selector: 'csl-manage-stories',
  templateUrl: './manage-stories.component.html',
  styleUrls: ['./manage-stories.component.css']
})

export class ManageStoriesComponent implements OnInit  {
  newsArticles: any[];
  showSpinner: boolean;
  sortedData: any[];
  showStoryTable: boolean =true;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['id', 'articleTitle', 'articleDomain', 'dateLoaded', 'publishedDate','articleSection','publishedFlag','showDetailsFlag','fbPostId', 'PinnedFront','PubRem', 'Republish', 'Cache', 'Edit', 'FB', 'Twitter'];
  section: any='all';
  articleToEdit: NewsArticle;

  numDaysStoriesToReturn: any = [1,3,5,7,14];
  frontPageSlots: any = [null,1,2,3,4,5];
  hasDetailsSelection: any = ['All','Has all details', 'Missing details'];
  filterValues = {};
  @ViewChild(MatPaginator) paginator: MatPaginator;

  selectedArticleSectionId: string = '0';
  selectedSectionId: number = 0;
  selectedDaysToReturn: number = 1;
  selectedHasDetails: string = 'All';
  filterArticleId: any;
  errorFindingArticle: boolean;
  errorMessage: string = "";
  successMessage: string = "";

  articleSections: any;

  searchTypes = SearchTypesEnum;
  selectedSearchType = SearchTypesEnum.SearchArticleId;
  searchText = '';
  selectedSearchSectionId: number = 0;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  readonly DELIMITER = '-';

  formOffset = new UntypedFormGroup({
    formOffsetControl: new UntypedFormControl('', Validators.required)
  });

  formPinned = new UntypedFormGroup({
    formPinnedControl: new UntypedFormControl('', Validators.required)
  });

  constructor( private authService:AuthenticationService, public router: Router,private newsArticleService: NewsArticleService, private modalService: NgbModal, private slugifyPipe: SlugifyPipe, public globals: Globals) {
    this.newsArticles = new Array();
    this.showSpinner = false;
    this.filterArticleId = '';
    this.errorFindingArticle = false;
    this.selectedDaysToReturn = 1;
    this.selectedHasDetails = 'All';
   }

  ngOnInit(): void {
    this.articleSections = this.newsArticleService.getSectionsWithEmpty();
    this.authService.loadFbAdSets();
    //Default to Front section
    let selectionId = {target:{value:"39"}}
    this.filterSection(selectionId);
  }

  togglePublish(articleId:any): void {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    let article: any =[];
    this.showSpinner =true;
    this.authService.getStories(articleId).subscribe(newsArticle => {
      article = newsArticle;
      if(article.publishFlag){
        article.publishFlag = 0;
      } else {
        article.publishFlag = 1;
      }
      this.authService.postPublishStory(article).subscribe(response => {

        // set the article pinned date to be the new value
        for(let source of this.dataSource.data) {
          if(source.id === articleId){
            source.publishedFlag = response.publishFlag;
            if(!response.publishFlag){
              source.publishedDate = response.publishedDate;
            }
          }
        }
        this.showSpinner = false;
        this.loadStories();
      });
    }, err => {
      this.showSpinner =false;
      console.log("Error in changing the publish status.");
      console.log(err);
      this.logOut();
    });
  }

  rePublish(articleId:any): void {
    let article: any =[];
    this.showSpinner =true;
    this.authService.getStories(articleId).subscribe(newsArticle => {
      article = newsArticle;
      article.publishedFlag = 1;
      this.authService.postPublishStory(article).subscribe(response => {

        // set the article pinned date to be the new value
        for(let source of this.dataSource.data) {
          if(source.id === articleId){
            source.publishedFlag = response.publishFlag;
            source.publishedDate = response.publishedDate;
          }
        }
        this.showSpinner = false;        
        this.loadStories();
      });
    }, err => {
      this.showSpinner =false;
      console.log("Error when republishing.");
      console.log(err);
      this.logOut();
    });
  }

  goToEditPage(newsArticle:any): void {
    this.showStoryTable = false;
    this.articleToEdit = newsArticle;
  }

  showStoriesGrid(showTable: boolean): void {
    this.showStoryTable = showTable;
    if (this.selectedSearchType == SearchTypesEnum.SearchArticleId) {
      this.loadStories();
    } else {
      this.searchStories();
    }
  }

  loadStories(articleId?:any){
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    this.errorFindingArticle = false;
    this.errorMessage = "";
    this.successMessage = "";
    this.newsArticles= [];
    this.showSpinner = true;
    if ( articleId == undefined){
      this.filterArticleId = '';
    }
    else
    {
      this.filterArticleId = articleId;
    }
    // If we are not filtering for a single story, then we bring back all stories (optionally by section)
    if(this.filterArticleId=='' && this.selectedArticleSectionId=='0'){
      this.getNewsArticleProcess('Stories',this.selectedDaysToReturn.toString(), this.section ,'' );
    }else if(this.filterArticleId=='' && this.selectedArticleSectionId!='0'){
      this.getNewsArticleProcess('Stories',this.selectedDaysToReturn.toString(), this.getSectionName(this.selectedSectionId),'');
    }
    else
    {
      // if we have set the ID, then search for it.
      this.selectedArticleSectionId = '0';
      this.selectedSectionId = 0;
      this.selectedHasDetails = 'All';
      this.getNewsArticleProcess('','', '',this.filterArticleId );
    }
  }

  getNewsArticleProcess(sDescription: string, dayCount: string , section:string, myFilterId:string)
  {
    // There are two webservices we can call, one takes articleid the other takes section & other values
    // if myFilterId has value we call getNewsArticle
    if(section =='Front'){
      this.newsArticleService.getFrontPinnedStories()
      .subscribe(
        newsArticle => {
          this.newsArticles = newsArticle;
          this.showSpinner = false;
          this.initiateDataSourceForStoriesTable();
        }, err => {
          this.handleError(err);
        }
      );
    } else if  ( myFilterId != '' ) {
      this.newsArticleService.getNewsArticleById( myFilterId, true )
      .subscribe(
        newsArticle => {
          this.newsArticles[0] = newsArticle;
          this.showSpinner = false;
          this.initiateDataSourceForStoriesTable();
        }, err => {
          this.handleError(err);
        }
      );
    }
    else
    {
      this.newsArticleService.getNewsArticles(sDescription, dayCount , section)
      .subscribe(
        newsArticles => {
          this.newsArticles = newsArticles;
          this.showSpinner = false;
          this.initiateDataSourceForStoriesTable();
          this.dataSource.filterPredicate = this.createFilter();
          this.filterHasDetails(this.selectedHasDetails);
        }, err => {
          this.handleError(err);
        }
      );
    }
  }

  searchStories (){
    let filterSection = '';
    let startDate = '';
    let endDate = '';
    if(this.selectedSearchSectionId) {
      filterSection = this.getSectionName(this.selectedSearchSectionId);
    }
    if(this.fromDate) {
      startDate = this.constructDateFromJson(this.fromDate) + 'T00:00:00';
    }
    if(this.toDate) {
      endDate = this.constructDateFromJson(this.toDate) + 'T23:59:59';
    }
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    this.errorFindingArticle = false;
    this.errorMessage = "";
    this.successMessage = "";
    this.newsArticles= [];
    this.showSpinner = true;
    this.newsArticleService.searchStoryByText(this.searchText, filterSection, startDate, endDate)
      .subscribe(
        newsArticles => {
          this.newsArticles = newsArticles;
          this.showSpinner = false;
          this.initiateDataSourceForStoriesTable();
        }, err => {
          this.handleError(err);
        }
      );
  }

  constructDateFromJson(jsonDate) {
    return jsonDate.year + this.DELIMITER + jsonDate.month + this.DELIMITER + jsonDate.day;
  }

  handleError(err)
  {
    console.log(err);
    console.log("Error in loading stories.");
    if(err.error.status===404){
      this.showSpinner = false;
      this.errorFindingArticle = true;
    }
    else
    {
      this.logOut();
    }
  }

  initiateDataSourceForStoriesTable()
  {
    this.dataSource = new MatTableDataSource(this.newsArticles.slice());
    this.dataSource.paginator = this.paginator;
    this.sortData({active: "pinnedDate", direction: "asc"});
  }

  logOut(){
    console.log('logged out');
    localStorage.removeItem('userToken');
    this.router.navigate(['login']);
  }

  // this story will change 
  changeFrontPinnedSlots(e, articleId:any){
    console.log('pinned 5');
    this.showSpinner = true;
    if(e.target.value != ""){
      this.authService.putFeaturedStories(articleId, e.target.value).subscribe(_response => {
        this.showSpinner = false;
        this.loadStories();
      }, err => {
        console.log(err);
        console.log("Error in pinning a story to the front page.");
        this.logOut();
      });
    }else {
      this.showSpinner = false;
      this.loadStories();
    }
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id': return compare(a.id, b.id, isAsc);
        case 'articleTitle': return compare(a.articleTitle, b.articleTitle, isAsc);
        case 'articleDomain': return compare(a.articleDomain, b.articleDomain, isAsc);
        case 'dateLoaded': return compare(a.dateLoaded, b.dateLoaded, isAsc);
        case 'publishedDate': return compare(a.publishedDate, b.publishedDate, isAsc);
        case 'articleSection': return compare(a.articleSection, b.articleSection, isAsc);
        case 'publishedFlag': return compare(a.publishedFlag, b.publishedFlag, isAsc);
        case 'showDetailsFlag': return compare(a.showDetailsFlag, b.showDetailsFlag, isAsc);
        case 'fbPostId': return compare(a.fbPostId, b.fbPostId, isAsc);
        case 'PinnedFront': return compare(a.featuredStorySlot, b.featuredStorySlot, isAsc);

        default: return 0;
      }
    });
  }

  convertDate(date: any){
    return new Date((Date.parse(date)) );
  }

  getSectionName(selectedId: number){
   let i : number;

    for (i = 0; i <  this.articleSections.length; i++)
    {
        if ( this.articleSections[i].id == selectedId ) {
          return this.articleSections[i].sectionCode;
        }
    }

    return "ALL"

  }

  mapSectionName(){
    for (let source of this.dataSource.data) {
      switch (source.RSS_Feed_ID) {
        case 1: {
          source.RSS_Feed_ID_DESC ='Google - China';
          break;
        }
        case 2: {
          source.RSS_Feed_ID_DESC ='HK';
          break;
        }
        case 3: {
          source.RSS_Feed_ID_DESC ='China Technology';
          break;
        }
        case 4: {
          source.RSS_Feed_ID_DESC ='China Environment';
          break;
        }
        case 5: {
          source.RSS_Feed_ID_DESC ='Falun Gong';
          break;
        }
        case 6: {
          source.RSS_Feed_ID_DESC ='China Human Rights';
          break;
        }
        case 7: {
          source.RSS_Feed_ID_DESC ='China Arts';
          break;
        }
        case 8: {
          source.RSS_Feed_ID_DESC ='China Politics';
          break;
        }
        case 9: {
          source.RSS_Feed_ID_DESC ='China Science';
          break;
        }
        case 10: {
          source.RSS_Feed_ID_DESC ='China Health';
          break;
        }
        case 11: {
          source.RSS_Feed_ID_DESC ='China Style';
          break;
        }
        case 12: {
          source.RSS_Feed_ID_DESC ='China Military';
          break;
        }
        case 13: {
          source.RSS_Feed_ID_DESC ='Covid19';
          break;
        }
        case 14: {
          source.RSS_Feed_ID_DESC ='Belt And Road';
          break;
        }
        case 15: {
          source.RSS_Feed_ID_DESC ='Xinjiang Uyhgur';
          break;
        }
        case 19: {
          source.RSS_Feed_ID_DESC ='SG';
          break;
        }
        case 20: {
          source.RSS_Feed_ID_DESC ='GB';
          break;
        }
        case 21: {
          source.RSS_Feed_ID_DESC ='AU';
          break;
        }
        case 22: {
          source.RSS_Feed_ID_DESC ='CA';
          break;
        }
        case 23: {
          source.RSS_Feed_ID_DESC ='IE';
          break;
        }
        case 24: {
          source.RSS_Feed_ID_DESC ='US';
          break;
        }
        case 25: {
          source.RSS_Feed_ID_DESC ='NZ';
          break;
        }
        default: break;
      }
    }
  }
  // transform article from table object to view object
  filterSection(selectionId) {
    this.filterArticleId = '';
    this.selectedSectionId = selectionId.target.value;
    this.selectedArticleSectionId = selectionId.target.value;
    this.loadStories( this.filterArticleId ); // passing in article id to filter grid by articleid
    this.selectedHasDetails = 'All';
    this.resetFilters();
  }

  changeSearchType(searchType) {
    this.selectedSearchType = searchType.target.value;
    if (this.selectedSearchType == SearchTypesEnum.SearchArticleId) {
      this.loadStories();
    }
  }

  filterBackDays(selectionId) {
    this.filterArticleId = '';
    this.selectedDaysToReturn = selectionId.target.value;
    this.loadStories( this.filterArticleId );
    this.resetFilters();
  }

  filterHasDetails(selectionId) {
    this.selectedHasDetails = selectionId;
    switch (this.selectedHasDetails) {
      case 'All': {
        this.filterValues['showDetailsFlag'] = '';
        this.resetFilters();
        break;
      }
      case 'Has all details': {
        this.filterValues['showDetailsFlag'] = '1';
        break;
      }
      case 'Missing details': {
        this.filterValues['showDetailsFlag'] = '0';
        break;
      }
    }

    if (this.filterValues['showDetailsFlag'] != ''){
      this.dataSource.filter = JSON.stringify(this.filterValues)
    }
  }

  // Custom filter method fot Angular Material Datatable
  createFilter() {
    return function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col].toString() !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }
      let nameSearch = () => {
        let found = false;
        if (!isFilterSet) {
          return true;
        }
        for (const col in searchTerms) {
          searchTerms[col].trim().toLowerCase().split(' ').forEach(word => {
            if (data[col].toString().toLowerCase().indexOf(word) != -1 && isFilterSet) {
              found = true;
            }
          });
        }
        return found;
      }
      return nameSearch()
    }
  }
  // Reset table filters
  resetFilters() {
    this.filterValues = {}
    this.dataSource.filter = "";
  }

  goToFacebook(newsArticle:any): void {
    this.articleToEdit = newsArticle;
    const ref = this.modalService.open(EditStoryFacebookComponent, { centered: true, size: 'lg' });
    ref.componentInstance.selectedNewsArticle = this.articleToEdit;
    if (this.articleToEdit.fbPostId) {
      ref.componentInstance.isEditMode = true;
    } else {
      ref.componentInstance.isEditMode = false;
    }
    ref.result.then(r => {
      if (r == "Completed") {
        this.loadStories();
      }
    });
  }

  goToTwitter(newsArticle:any): void {
    this.articleToEdit = newsArticle;
    const ref = this.modalService.open(EditStoryTwitterComponent, { centered: true, size: 'lg' });
    ref.componentInstance.selectedNewsArticle = this.articleToEdit;
    if (this.articleToEdit.twitterPostId) {
      ref.componentInstance.isEditMode = true;
    } else {
      ref.componentInstance.isEditMode = false;
    }
    ref.result.then(r => {
      if (r == "Completed") {
        this.loadStories();
      }
    });
  }

  goToFacebookAdStatus(): void {
    this.modalService.open(FacebookAdStatusComponent, { centered: true, size: 'lg' });
  }

  runPrepareSiteCache(): void {
    this.showSpinner = true;
    this.errorMessage = "";
    this.successMessage = "";
    this.authService.postPrepareStories()
      .subscribe(
        _newsArticle => {
          this.successMessage = "Successfully updated site cache!";
          this.showSpinner = false;
        }, err => {
          this.errorMessage = err.error.title;
          this.showSpinner = false;
        }
      );
  }

  cacheSSR(newsArticle:any) {
    this.errorMessage = "";
    this.successMessage = "";
    console.log(`cacheSSR caching SSR...`);
    this.showSpinner = true;
    let ssrUrl = this.globals.path + '/story/' + newsArticle.id + "/" + this.slugifyPipe.transform(newsArticle.articleTitle);
    this.authService.getPageViaSSR(ssrUrl).subscribe(httpPage => {
      console.log(`cacheSSR: success`);
      this.successMessage = "Successfully cached page for social media!";
      this.showSpinner = false;
    }, _err => {
      console.log(`cacheSSR: failed`);
      this.errorMessage = _err.error.title;
      this.showSpinner = false;
    });
    
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';

declare const fullWidthMiddle970x90: any;
declare const bannerAdTest: any;

@Component({
  selector: 'csl-short-full-width-advert',
  templateUrl: './short-full-width-advert.component.html'
})
export class ShortFullWidthAdvertComponent implements OnInit {
  advertisement1: string;
  constructor(
    @Inject(PLATFORM_ID) private platform: Object) {
      this.advertisement1 ='"438126864", "970x90", "438126864"';
     }

  ngOnInit() {
    // Intentionally left empty
  }

}

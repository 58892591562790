import { Injectable } from '@angular/core';
import { Feedback } from '@models/feedback';
import { catchError} from 'rxjs/operators';
import { Service } from '@components/shared/service';
import { HttpClient} from '@angular/common/http';
import { MessageService } from '@services/message.service';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends Service {
 private feedbackUrl = ''; // live website




 constructor(protected http: HttpClient, protected messageService: MessageService) {
   super(http, messageService);
  this.feedbackUrl = environment.apiBaseUrl +'api/Feedbacks';
}





 /** post feedback to server */

 postFeedback(feedback: Feedback) {
   return this.http.post<Feedback>(this.feedbackUrl, feedback, this.httpOptions).pipe(
     catchError(this.handleError)
   );
 }
 }

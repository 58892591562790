import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { Globals } from 'app/globals';
import { AuthenticationService } from '@services/authentication.service';

@Component({
  selector: 'csl-edit-quote',
  templateUrl: './edit-quote.component.html',
  styleUrls: ['./edit-quote.component.css']
})
export class EditQuoteComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['id', 'articleTitle', 'dateLoaded', 'quote', 'person', 'weight','weightType', 'save', 'likeCount','dislikeCount','loveCount', 'trustCount','factcheckCount'];
  showSpinner: boolean = false;
  weightTypes: Array<{id: string, description: string}> = [
    {id:this.globals.quoteLikeTypeLike, description:'Like'},
    {id:this.globals.quoteLikeTypeDislike, description:'Dislike'},
    {id:this.globals.quoteLikeTypeLove, description:'Love'},
    {id:this.globals.quoteLikeTypeTrust, description:'Trust'},
    {id:this.globals.quoteLikeTypeFactcheck, description:'Fact Check'}
  ];
  quotes: any[];
  prepareDataError: string = "";
  prepareDataSuccess: boolean;

  constructor(public globals: Globals, private authService:AuthenticationService) { }

  ngOnInit(): void {
    this.showSpinner = true;
    this.loadQuotes();
  }

  loadQuotes()
  {
    this.authService.getTopQuotesLast7Days()
    .subscribe(
      quotes => {
        this.quotes = quotes;
        this.showSpinner = false;
        this.initiateDataSourceForStoriesTable();
      }
    );
  }

  initiateDataSourceForStoriesTable()
  {
    this.dataSource = new MatTableDataSource(this.quotes.slice());
    this.dataSource.paginator = this.paginator;
  }

  convertDate(date: any){
    return new Date((Date.parse(date)) );
  }

  save(id: any) {
    this.showSpinner = true;
    for(let source of this.dataSource.data) {
      if(source.id === id){
        this.authService.putStoryQuoteWeight(source).subscribe(_response => {
          this.loadQuotes();
        }, _err => {
          this.showSpinner = false;
        });
      }
    }
  }

  runPrepareSiteCache(): void {
    this.showSpinner = true;
    this.prepareDataError = "";
    this.prepareDataSuccess = false;
    this.authService.postPrepareStories()
      .subscribe(
        _newsArticle => {
          this.prepareDataSuccess = true;
          this.showSpinner = false;
        }, err => {
          this.prepareDataError = err.error.title;
          this.showSpinner = false;
        }
      );
  }
}

import { Component, OnInit } from '@angular/core';
import { VideoService } from '@services/video.service';
import { Video } from '@models/video';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditVideoComponent } from '@components/admin/edit-video/edit-video.component';
import { AuthenticationService } from '@services/authentication.service';
import { NewsArticleService } from '@services/news-article.service';

@Component({
  selector: 'csl-manage-videos',
  templateUrl: './manage-videos.component.html',
  styleUrls: ['./manage-videos.component.css']
})
export class ManageVideosComponent implements OnInit {
  selectedSectionCode: string = "";
  videos: any[];
  video: Video;
  showSpinner: boolean;
  videoSections: any;
  
  constructor(private authService:AuthenticationService, private newsArticleService: NewsArticleService, private videoService: VideoService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.videoSections = this.newsArticleService.getSectionsWithEmpty();
    this.loadVideos();
  }

  loadVideos(){
    this.showSpinner = true;
    console.log('selectedSectionCode = ' + this.selectedSectionCode);
    if (this.selectedSectionCode === '' || this.selectedSectionCode === undefined || this.selectedSectionCode === null){
      this.videoService.getVideos(true)
        .subscribe(
          videos => {
          this.videos = videos;
          this.showSpinner = false;
        }
      );
    } else {
      this.videoService.getVideosForSection(this.selectedSectionCode, true)
        .subscribe(
          videos => {
          this.videos = videos;
          this.showSpinner = false;
        }
      );
    }
  }

  changeSection(sectionCode) {
    this.selectedSectionCode = sectionCode.target.value;
    this.loadVideos();
  }

  deleteVideo(id:any){
    this.showSpinner = true;
    this.videoService.deleteVideo(id)
      .subscribe(
      _response => {
        this.loadVideos();
      }
    );
  }

  editVideo(id, linkId, provider, title, section, date) {
    this.video = new Video ();
    this.video.id = id;
    this.video.linkId = linkId;
    this.video.provider = provider;
    this.video.title = title;
    this.video.section = section;
    this.video.date = date;
    const ref = this.modalService.open(EditVideoComponent, { centered: true });
    ref.componentInstance.selectedVideo = this.video;
    ref.componentInstance.isEditMode = true;
  
    ref.result.then((_yes) => {
      this.loadVideos();
    })
  }

  addVideo() {
    this.video = new Video ();
    const ref = this.modalService.open(EditVideoComponent, { centered: true });
    ref.componentInstance.selectedVideo = this.video;
    ref.componentInstance.isEditMode = false;
  
    ref.result.then((_yes) => {  
      this.loadVideos();
    })
  }

}

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export const faLove: IconDefinition = {
    prefix: 'fac',
    iconName: 'love',
    icon: [
        512,
        512,
        [],
        null,
        'M373.333,40.896c-48.896,0.761-93.652,27.614-117.333,70.4c-23.681-42.786-68.437-69.639-117.333-70.4 C58.947,44.36-3.009,111.558,0,191.296c0,97.003,102.101,202.944,187.733,274.773c39.468,33.167,97.065,33.167,136.533,0 C409.899,394.24,512,288.299,512,191.296C515.009,111.558,453.053,44.36,373.333,40.896z M296.853,433.429 c-23.608,19.88-58.099,19.88-81.707,0c-109.611-91.968-172.48-180.203-172.48-242.133c-3.036-56.188,39.835-104.298,96-107.733 c56.165,3.435,99.036,51.545,96,107.733c0,11.782,9.551,21.333,21.333,21.333c11.782,0,21.333-9.551,21.333-21.333 c-3.036-56.188,39.835-104.298,96-107.733c56.165,3.435,99.036,51.545,96,107.733c0,61.931-62.869,150.165-172.48,242.048V433.429z'
    ],
} as any
import { Component, OnInit } from '@angular/core';
import { Globals } from 'app/globals';
import { SubscriberService } from '@services/subscriber.service';

@Component({
  selector: 'csl-manage-subscribers',
  templateUrl: './manage-subscribers.component.html',
  styleUrls: ['./manage-subscribers.component.css']
})
export class ManageSubscribersComponent implements OnInit {
  subscribers: any[];
  showSpinner: boolean;
  filterEmailAddress: '';

  constructor(private subscriberService: SubscriberService,
              public globals: Globals) {
    this.showSpinner = false;
  }

  ngOnInit(): void {
    this.loadSubscribers();
  }

  loadSubscribers(){
    this.showSpinner = true;
    console.log('filterEmailAddress = ' + this.filterEmailAddress);
    if (this.filterEmailAddress === '' || this.filterEmailAddress === undefined || this.filterEmailAddress === null){
      this.subscriberService.getSubscribers()
        .subscribe(
          subscribers => {
          this.subscribers = subscribers;
          this.showSpinner = false;
        }
      );
    } else {
      this.subscriberService.getSubscribersByAddress(this.filterEmailAddress)
        .subscribe(
          subscribers => {
          this.subscribers = subscribers;
          this.showSpinner = false;
        }
      );
    }
  }

  toggleEnabled(subscriberId:any){
    let subscriber: any =[];
    this.showSpinner =true;
    this.subscriberService.getSubscriber(subscriberId).subscribe(retrieved_subscriber => {
      subscriber = retrieved_subscriber;
      if(subscriber.enabledFlag){
        subscriber.enabledFlag =0;
      } else {
        subscriber.enabledFlag =1;
      }
      this.subscriberService.putSubscriber(subscriber).subscribe(_response => {
        this.loadSubscribers();
      });
    });
  }

}

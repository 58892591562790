<div class="post">
    <div class="entry-header">
        <div class="entry-thumbnail">
            <img class="img-fluid" src="{{countryNewsArticles[0].articleImage}}" alt="Image" />
        </div>
    </div>
    <div class="post-content">
        <div class="entry-meta">
            <ul class="list-inline">
                <li class="publish-date"><a href="#"><em class="fa fa-clock-o"></em> {{countryNewsArticles[0].dateLoaded | date:datePipe}} </a></li>
                <li class="views"><a href="#"><em class="fa fa-eye"></em>{{countryNewsArticles[0].viewsCount}}</a></li>
                <li class="loves"><a href="#"><em class="fa fa-heart-o"></em>{{countryNewsArticles[0].likesCount}}</a></li>
                <li class="comments"><em class="fa fa-comment-o"></em><a href="#">{{countryNewsArticles[0].commentCount}}</a></li>
            </ul>
        </div>
        <h2 class="entry-title">
            <a *ngIf="countryNewsArticles[0]"  [routerLink]="['/story', countryNewsArticles[0].id, countryNewsArticles[0].articleTitleSlug]" routerLinkActive="active">{{countryNewsArticles[0].articleTitle}}</a>
        </h2>
        <div class="entry-content" >
            <p>{{countryNewsArticles[0].articleIntro}}</p>
        </div>
    </div>
    <div class="list-post">
        <ul>
            <li><a *ngIf="countryNewsArticles[1]"  [routerLink]="['/story', countryNewsArticles[1].id, countryNewsArticles[1].articleTitleSlug]" routerLinkActive="active">{{countryNewsArticles[1].articleTitle}} <em class="fa fa-angle-right"></em></a></li>
            <li><a *ngIf="countryNewsArticles[2]"  [routerLink]="['/story', countryNewsArticles[2].id, countryNewsArticles[2].articleTitleSlug]" routerLinkActive="active">{{countryNewsArticles[2].articleTitle}}<em class="fa fa-angle-right"></em></a></li>
            <li><a *ngIf="countryNewsArticles[3]"  [routerLink]="['/story', countryNewsArticles[3].id, countryNewsArticles[3].articleTitleSlug]" routerLinkActive="active">{{countryNewsArticles[3].articleTitle}} <em class="fa fa-angle-right"></em></a></li>
        </ul>
    </div>
</div>
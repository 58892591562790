<div class="container">
    <div class="top-add">
      <div class="navbar-header float-left">
        <a class="navbar-brand" href="index.html">
          <img class="main-logo img-fluid" src="./assets/images/presets/preset1/logo.png" alt="logo">
        </a>
      </div>
      <div class="navbar-right float-right">
        <div id="935636682">
          <!-- <csl-media-net-ad [advertisement]="advertisement1"></csl-media-net-ad> -->
        </div>
      </div>
    </div>
  </div>

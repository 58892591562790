import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthenticationService } from '@services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'csl-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public frmLogin: UntypedFormGroup;
  error: any;
  submitted = false;
  showSpinner = false;

  constructor(private formBuilder: UntypedFormBuilder, private authService:AuthenticationService, public router: Router) {
    this.frmLogin = this.formBuilder.group({
      username: '',
      password: ''
    });
   }

  ngOnInit(): void {
    // Intentionally left empty
  }

  onSubmit() {
    // do signup or something
    this.error = false;
    this.submitted = false;
    return this.authService.
      login(this.frmLogin.value).subscribe(
        value =>  { const token: any= value;
          localStorage.setItem('userToken', token.access_token)
          this.authService.
          getUserInfo().subscribe(
            returnUserInfo =>  {const userInfo: any= returnUserInfo;
              localStorage.setItem('userRole', userInfo.role);
              this.frmLogin.reset();
              this.submitted = true;
              this.toggleSpinner();
              this.router.navigate(['manageStories']);
            }
          );
        },
        error => {this.error = error;
          this.toggleSpinner()
          this.frmLogin.reset();
      }
    )
  }

toggleSpinner() {
    this.showSpinner = !this.showSpinner;

}

}

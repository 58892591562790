<div>
    <div class="modal-header bg-primary text-white">
      <h4 class="modal-title">{{title}}</h4>
    </div>
    <div class="modal-body">
      <p>{{prompt}}</p>
    </div>
    <div class="modal-footer">
      <button type="button"
        class="btn btn-secondary mr-2"
        (click)="activeModal.close(false)">Cancel</button>
      <button type="button"
        class="btn btn-primary mr-2"
        (click)="activeModal.close(true)">OK</button>
    </div>
</div>
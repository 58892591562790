import { Injectable, Injector, Inject, PLATFORM_ID } from '@angular/core';
import {NewsArticle} from '@models/newsArticle';
import {ArticleQuote} from '@models/articleQuote';
import {ArticleSummary} from '@models/articleSummary';
import {ArticleExtension} from '@models/articleExtension';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from './message.service';
import { ArticleView } from '@models/articleView';
import { SlugifyPipe } from '@pipes/slugify.pipe';
import { Router } from '@angular/router';
import {Section} from '@models/section';
import { environment } from 'environments/environment';
import { Globals } from 'app/globals';

@Injectable({
  providedIn: 'root'
})
export class NewsArticleService {
  newsArticlesUrl = ''; // live website
  articleView: ArticleView;
  error = false;
  articleSections: Section[];
  articleSectionsWithEmpty: Section[];
  
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private injector: Injector,
    @Inject(PLATFORM_ID) private platformId: Object,
    private slugifyPipe: SlugifyPipe,
    public router: Router,
    public globals: Globals) {
      this.newsArticlesUrl = environment.apiBaseUrl +'api/';
     }

    private httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth':'True' })
    };

    private httpNoCacheOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth':'True', 'No-Cache':'true' })
    };

  /** GET news articles from the server */
  getSectionArticles(articleSection?: any): Observable<NewsArticle[]> {
    if (!articleSection) {
      articleSection = '';
    }
    return this.http.get<NewsArticle[]>(this.newsArticlesUrl + 'Stories/section/' + articleSection, this.httpOptions)
    .pipe(
      tap(_ => this.log('fetched newsArticles-' + articleSection)),
      catchError(this.handleError('getNewsArticles-' + articleSection, [])),
      map((newsArticles: any) => this.setArticleTitleSlug(newsArticles))
    );
  }

  /** GET news articles from the server */
  getRisingArticles(): Observable<NewsArticle[]> {
    return this.http.get<NewsArticle[]>(this.newsArticlesUrl + 'Stories/rising', this.httpOptions)
    .pipe(
      map((newsArticles: any) => this.setArticleTitleSlug(newsArticles))
    );
  }

  getFrontPinnedStories(): Observable<NewsArticle[]> {
    return this.http.get<NewsArticle[]>(this.newsArticlesUrl + 'featuredstories', this.httpNoCacheOptions)
    .pipe(
      map((newsArticles: any) => this.setArticleTitleSlug(newsArticles))
    );
  }
  
  /** GET news articles from the server */
  getNewsArticles(service: any = '', numOfDaysOfStoriesToReturn: any = '1', section: any =''): Observable<NewsArticle[]> {
    if(service == 'Stories')
    {
      return this.getSectionStories(numOfDaysOfStoriesToReturn,'Stories', section);
    } else {
      return this.getSectionStories(numOfDaysOfStoriesToReturn,'ChinaStoriesHomes', section);
    }
  }

  private getSectionStories(numOfDaysOfStoriesToReturn, service: any = 'ChinaStoriesHomes',section: any =''): Observable<NewsArticle[]>  {
    let requestParams = {numberOfDays: numOfDaysOfStoriesToReturn, articleSection: section};
    let tempHttpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Cache': 'true'}),
      params: requestParams
    };
    return this.http.get<NewsArticle[]>(this.newsArticlesUrl + service, tempHttpOptions)
    .pipe(
      tap(_ => this.log('fetched newsArticles-' + service)),
      catchError(this.handleError('getNewsArticles-' + service, [])),
      map((newsArticles: any) => this.setArticleTitleSlug(newsArticles))
    );
  }

  searchStoryByText(searchString: any, articleSection: any ='', fromDate: any ='', toDate: any =''): Observable<NewsArticle[]>  {
    let requestParams = {searchString: searchString, articleSection: articleSection, fromDate: fromDate, toDate: toDate};
    let tempHttpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Cache': 'true'}),
      params: requestParams
    };
    return this.http.get<NewsArticle[]>(this.newsArticlesUrl + 'Stories/search', tempHttpOptions)
    .pipe(
      tap(_ => this.log('fetched newsArticles-Stories/search')),
      catchError(this.handleError('getNewsArticles-Stories/search', [])),
      map((newsArticles: any) => this.setArticleTitleSlug(newsArticles))
    );
  }

  private setArticleTitleSlug(newsArticles: any) {
    return newsArticles.map((newsArticle) => {
      newsArticle.articleTitleSlug = this.slugifyPipe.transform(newsArticle.articleTitle);
      return newsArticle;
    });
  }

  /** Log a NewsArticleService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`NewsArticleService: ${message}`);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // Send the error to remote logging infrastructure
      console.error(error); // log to console instead

      if(error.status ===404){
        this.router.navigate(['home']);
      }

      // Better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** GET newsArticle by id. Return `undefined` when id not found */
  getNewsArticleNo404<Data>(id: number): Observable<NewsArticle> {
    const url = `${this.newsArticlesUrl}/?id=${id}`;
    return this.http.get<NewsArticle[]>(url,this.httpOptions)
      .pipe(
        map(newsArticles => newsArticles[0]), // returns a {0|1} element array
        tap(h => {
          const outcome = h ? `fetched` : `did not find`;
          this.log(`${outcome} newsArticle id=${id}`);
        }),
        catchError(this.handleError<NewsArticle>(`newsArticle id=${id}`))
      );
  }

  /** GET newsArticle by id. Will 404 if id not found */
  getNewsArticle(id: string, noCache:boolean = false): Observable<NewsArticle> {
    let httpOptions = this.httpOptions;
    if (noCache) {
      httpOptions = this.httpNoCacheOptions;
    }
    const url = `${this.newsArticlesUrl + 'Stories/view'}/${id}`;
    return this.http.get<NewsArticle>(url,httpOptions).pipe(
      tap(_ => this.log(`fetched newsArticle id=${id}`)),
      map((newsArticle: any) => {
        newsArticle.articleTitleSlug = this.slugifyPipe.transform(newsArticle.articleTitle);
        return newsArticle;
      }),
      catchError(this.handleError<NewsArticle>(`newsArticle id=${id}`))
    );
  }

  /** GET newsArticle by id. Will 404 if id not found */
  getNewsArticleById(id: string, noCache:boolean = false): Observable<NewsArticle> {
    let httpOptions = this.httpOptions;
    if (noCache) {
      httpOptions = this.httpNoCacheOptions;
    }
    const url = `${this.newsArticlesUrl + 'Stories'}/${id}`;
    return this.http.get<NewsArticle>(url,httpOptions).pipe(
      tap(_ => this.log(`fetched newsArticle id=${id}`)),
      map((newsArticle: any) => {
        newsArticle.articleTitleSlug = this.slugifyPipe.transform(newsArticle.articleTitle);
        return newsArticle;
      }),
      catchError(this.handleError<NewsArticle>(`newsArticle id=${id}`))
    );
  }

  /** GET article quotes */
  getArticleQuotes(id: string, noCache:boolean = false): Observable<ArticleQuote[]> {
    let httpOptions = this.httpOptions;
    if (noCache) {
      httpOptions = this.httpNoCacheOptions;
    }
    const url = `${this.newsArticlesUrl + 'Stories/quote'}/${id}`;
    return this.http.get<ArticleQuote[]>(url,httpOptions).pipe(
      catchError(this.handleErrorGeneric)
    );
  }

  /** GET article summaries */
  getArticleSummaries(id: string, noCache:boolean = false): Observable<ArticleSummary[]> {
    let httpOptions = this.httpOptions;
    if (noCache) {
      httpOptions = this.httpNoCacheOptions;
    }
    const url = `${this.newsArticlesUrl + 'Stories/summary'}/${id}`;
    return this.http.get<ArticleSummary[]>(url,httpOptions).pipe(
      catchError(this.handleErrorGeneric)
    );
  }

  /** GET article extension */
  getArticleExtension(id: string, noCache:boolean = false): Observable<ArticleExtension> {
    let httpOptions = this.httpOptions;
    if (noCache) {
      httpOptions = this.httpNoCacheOptions;
    }
    const url = `${this.newsArticlesUrl + 'Stories/extension'}/${id}`;
    return this.http.get<ArticleExtension>(url,httpOptions).pipe(
      catchError(this.handleErrorGeneric)
    );
  }

  /** GET historical stories by page */
  getArticleHistory(articleSection: string, pageNumber: number = 1, pageSize: number = 5): Observable<NewsArticle[]> {
    let requestParams = {pageNumber: pageNumber, pageSize: pageSize};
    let tempHttpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth':'True' }),
      params: requestParams
    };
    return this.http.get<NewsArticle[]>(this.newsArticlesUrl + 'Stories/section/history/' + articleSection, tempHttpOptions)
    .pipe(
      tap(_ => this.log('fetched newsArticles-Stories/section/history/')),
      map((newsArticles: any) => this.setArticleTitleSlug(newsArticles))
    );
  }

  /* GET newsArticles whose name contains search term */
  searchNewsArticles(term: string): Observable<NewsArticle[]> {
    if (!term.trim()) {
      // if not search term, return empty newsArticle array.
      return of([]);
    }
    return this.http.get<NewsArticle[]>(`${this.newsArticlesUrl + 'ChinaStories'}/?name=${term}`,this.httpOptions).pipe(
      tap(_ => this.log(`found newsArticles matching "${term}"`)),
      catchError(this.handleError<NewsArticle[]>('searchNewsArticles', []))
    );
  }

  /** GET random nine commentaries story */
  getRandomNineCommentaries(noCache:boolean = false): Observable<NewsArticle> {
    let httpOptions = this.httpOptions;
    if (noCache) {
      httpOptions = this.httpNoCacheOptions;
    }
    const url = this.newsArticlesUrl + 'Stories/NineCommentaries/Random';
    return this.http.get<NewsArticle>(url,httpOptions).pipe(
      tap(_ => this.log('fetched randpm nine commentaries ')),
      map((newsArticle: any) => {
        newsArticle.articleTitleSlug = this.slugifyPipe.transform(newsArticle.articleTitle);
        return newsArticle;
      }),
      catchError(this.handleError<NewsArticle>('newsArticle randpm nine commentaries'))
    );
  }

  /** GET top quotes */
  getTopQuotes(pageNumber: number = 1): Observable<any[]> {
    let requestParams = {pageNumber: pageNumber};
    let tempHttpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth':'True' }),
      params: requestParams
    };
    return this.http.get<any[]>(this.newsArticlesUrl + 'Stories/topQuotes', tempHttpOptions)
    .pipe(
      map((topQuotes: any) => this.setArticleTitleSlug(topQuotes))
    );
  }

  postArticleView(articleView: ArticleView) {
    return this.http.post<ArticleView>(this.newsArticlesUrl + 'ArticleViews', articleView, this.httpOptions).pipe(
      tap(_ => this.log(`Added article view: "${articleView}"`)),
      catchError(this.handleError<ArticleView[]>('PostArticleView', []))
    );
  }

  getSectionHighlights(): Observable<any> {
    return this.http.get<any>(this.newsArticlesUrl + 'Stories/sectionHighlights', this.httpOptions);
  }

  getSections(){
    return this.globals.articleSections;
  }
  
  getSectionsWithEmpty(){
    return this.globals.articleSectionsWithEmpty;
  }

  getMapSectionDescription(articleSection: string): string {
    let foundSection = null;
    if (this.articleSections) {
      foundSection = this.articleSections.find(x => x.sectionCode == articleSection);
    }
    if (foundSection) {
      return foundSection.name;
    } else {
      return articleSection;
    }
  }

  postSubscription(email: string) {
    return this.http.post(this.newsArticlesUrl + 'socialmedia/mailchimp/subscribe', {'email': email}, this.httpNoCacheOptions).pipe(
    );
  }

  postQuoteLike(articleQuoteId: number, likeType: string) {
    return this.http.post<any>(this.newsArticlesUrl + 'Stories/quote/like', {'articleQuoteId': articleQuoteId, 'likeType': likeType}, this.httpNoCacheOptions).pipe(
    );
  }

  protected handleErrorGeneric(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
 
      // A client-side or network error occurred. Handle it accordingly.
 
      console.error('An error occurred:', error.error.message);
    } else {
 
      // The backend returned an unsuccessful response code.
 
      // The response body may contain clues as to what went wrong.
 
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
 
    // return an observable with a user-facing error message
 
    let errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(errorData);
  }
}

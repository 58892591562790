import { Component, OnInit } from '@angular/core';
import { NewsArticleService } from '@services/news-article.service';
import { Globals } from 'app/globals';
import { faLike } from 'assets/icons/like';
import { faDislike } from 'assets/icons/dislike';
import { faLove } from 'assets/icons/love';
import { faTrust } from 'assets/icons/trust';
import { faFactcheck } from 'assets/icons/factcheck';

@Component({
  selector: 'csl-top-quotes',
  templateUrl: './top-quotes.component.html',
  styleUrls: ['./top-quotes.component.css']
})
export class TopQuotesComponent implements OnInit {
  topQuotes;
  topQuotesPage: number;
  faLike = faLike;
  faDislike = faDislike;
  faLove = faLove;
  faTrust = faTrust;
  faFactcheck = faFactcheck;

  constructor(private newsArticleService: NewsArticleService, public globals: Globals ) {}

  ngOnInit(): void {
    this.topQuotesPage = 0;
    this.onScrollDown();
  }

  public postQuoteLike(topQuote, likeType) {
    topQuote.isDisabled = true;
    this.newsArticleService.postQuoteLike(topQuote.quoteId, likeType).subscribe(
      replyData => {
        topQuote.quoteLikeCount = replyData.likeCount;
        topQuote.quoteDislikeCount = replyData.dislikeCount;
        topQuote.quoteLoveCount = replyData.loveCount;
        topQuote.quoteTrustCount = replyData.trustCount;
        topQuote.quoteFactcheckCount = replyData.factcheckCount;
        if (replyData.weight > 0) {
          switch (replyData.weightType) {
            case this.globals.quoteLikeTypeLike:
              topQuote.quoteLikeCount += replyData.weight;
              break;
            case this.globals.quoteLikeTypeDislike:
              topQuote.quoteDislikeCount += replyData.weight;
              break;
            case this.globals.quoteLikeTypeLove:
              topQuote.quoteLoveCount += replyData.weight;
              break;
            case this.globals.quoteLikeTypeTrust:
              topQuote.quoteTrustCount += replyData.weight;
              break;
            case this.globals.quoteLikeTypeFactcheck:
              topQuote.quoteFactcheckCount += replyData.weight;
              break;
          }
        }
      }
    );
  }

  onScrollDown () {
    this.topQuotesPage += 1;
    this.newsArticleService.getTopQuotes(this.topQuotesPage)
    .subscribe(
      topQuotes => {
        topQuotes.map(item => {
          if (item.quoteWeight > 0) {
            switch (item.quoteWeightType) {
              case this.globals.quoteLikeTypeLike:
                item.quoteLikeCount += item.quoteWeight;
                break;
              case this.globals.quoteLikeTypeDislike:
                item.quoteDislikeCount += item.quoteWeight;
                break;
              case this.globals.quoteLikeTypeLove:
                item.quoteLoveCount += item.quoteWeight;
                break;
              case this.globals.quoteLikeTypeTrust:
                item.quoteTrustCount += item.quoteWeight;
                break;
              case this.globals.quoteLikeTypeFactcheck:
                item.quoteFactcheckCount += item.quoteWeight;
                break;
            }
          }
        });

        if (this.topQuotes) {
          this.topQuotes.push(...topQuotes);
        } else {
          this.topQuotes = topQuotes;
        }
      }
    );
  }
}

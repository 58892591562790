import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Advertisement } from '@models/advertisement';

declare const bannerAdTest: any;
declare const bannerAd728x90: any;

@Component({
  selector: 'csl-top-logo-banner',
  templateUrl: './top-logo-banner.component.html'
})
export class TopLogoBannerComponent implements OnInit {
  advertisement: Advertisement;
  advertisement1: string;
  constructor(
    @Inject(PLATFORM_ID) private platform: Object) {
      this.advertisement1 ='"935636682", "728x90", "935636682"';
     }

  ngOnInit() {
    // Intentionally left empty
  }

}

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { MessageService } from '@services/message.service';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class Service {
errorData: {};

@Inject(Router)
public router: Router;

 protected httpOptions = {
   headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth':'True' })
 };

 constructor(
   protected http: HttpClient,
   protected messageService: MessageService) { }


 /** Log a EmailService message with the MessageService */
 private log(message: string) {
   this.messageService.add(`FeedbackService: ${message}`);
 }

 protected handleError(error: HttpErrorResponse) {
   if (error.error instanceof ErrorEvent) {

     // A client-side or network error occurred. Handle it accordingly.

     console.error('An error occurred:', error.error.message);
   } else {

     // The backend returned an unsuccessful response code.

     // The response body may contain clues as to what went wrong.

     console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
   }

   // return an observable with a user-facing error message

   this.errorData = {
     errorTitle: 'Oops! Request for document failed',
     errorDesc: 'Something bad happened. Please try again later.'
   };
   return throwError(this.errorData);
 }
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'csl-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageDialogComponent implements OnInit {
  title: string;
  message: string;
  messageClass: string;
  messageUrl: string;
  messageUrlTitle: string;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    // Intentionally left blank
  }

}

export class Video {
    id: number;
    date: Date;
    linkId: string;
    provider: string;
    title: string;
    section: string;
    commentsCount: number;
    likesCount: number;
    viewsCount: number;
}

import { Component } from '@angular/core';

@Component({
  selector: 'csl-top-navigation-menu',
  templateUrl: './top-navigation-menu.component.html'
})
export class TopNavigationMenuComponent {
  public isMenuCollapsed = true;

}

<div class="modal-header bg-primary text-white">
    <h4 class="modal-title" id="modal-basic-title">Preview Story</h4>
</div>
<div class="modal-body">
    <csl-spinner  [show]="isLoading" [size]="500"> </csl-spinner>
    <div class="entry-header">
        <div class="entry-thumbnail">
            <img class="img-fluid" src='{{newsArticle?.articleImage}}' alt="" />
        </div>
    </div>
    <div class="post-content">
        <h2>
            <div *ngIf="!isLoading">{{newsArticle?.articleTitle}}</div>
        </h2>
    </div>
    <div *ngIf="!isLoading" [innerHTML]="newsArticle?.articleText | safeHtml"></div>
    <div class="modal-footer">
        <button type="button" [disabled]="isLoading" class="btn btn-primary mr-2" (click)="modal.close('Back')">Back</button>
    </div>
</div>
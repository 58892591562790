<csl-admin-header></csl-admin-header>

<body style="padding-top:20px">
    <div class="col-md-10 col-md-offset-1">
        <div class="well controlBox">
            <select id="selectedSectionCode" class="filterSelection btn-success" (change)="changeSection($event)" [ngModel]="selectedSectionCode">
                <option *ngFor="let section of videoSections" [selected]="" [value]="section.sectionCode">{{section.name}}</option>
            </select>
            <button type="button" (click)="addVideo()" class="btn btn-success leftMargin">Add Video</button>
        </div>
        <div id="divData" class="well hidden">
            <table  class="table table-bordered" id="tblData" aria-describedby="Manage Comments">
                <thead>
                    <tr  class="success">
                      <th scope="col">Id</th>
                      <th scope="col">Title</th>
                      <th scope="col">Link Id</th>
                      <th scope="col">Provider</th>
                      <th scope="col">Section</th>
                      <th scope="col">Date</th>
                      <th scope="col">Actions</th>
                    </tr>
                    <csl-spinner  [size]="150"> </csl-spinner>
                    <tr *ngIf="showSpinner">
                        <td><csl-spinner [show]="showSpinner" [size]="150"> </csl-spinner></td>
                        <td><csl-spinner [show]="showSpinner" [size]="150"> </csl-spinner></td>
                        <td><csl-spinner [show]="showSpinner" [size]="150"> </csl-spinner></td>
                        <td><csl-spinner [show]="showSpinner" [size]="150"> </csl-spinner></td>
                        <td><csl-spinner [show]="showSpinner" [size]="150"> </csl-spinner></td>
                        <td><csl-spinner [show]="showSpinner" [size]="150"> </csl-spinner></td>
                    </tr>
                
                </thead>
                    <tr *ngFor="let video of videos">
                      <td>{{video.id}}</td>
                      <td>{{video.title}}</td>
                      <td>{{video.linkId}}</td>
                      <td>{{video.provider}}</td>
                      <td>{{video.section}}</td>
                      <td>{{video.date | date:'medium'}}</td>
                      <td><div class="btn-group">
                          <button type="button" (click)="editVideo(video.id, video.linkId, video.provider, video.title, video.section, video.date)" class="btn btn-outline-success">Edit</button>
                          <button type="button" (click)="deleteVideo(video.id)" class="btn btn-outline-danger leftMargin">Delete</button></div>
                      </td>
                    </tr>
                <tbody id="tblBody"></tbody>
            </table>
        </div>
        <div class="modal fade" tabindex="-1" id="errorModal"
              data-bs-keyboard="false" data-bs-backdrop="static">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-bs-dismiss="modal">
                            &times;
                        </button>
                        <h4 class="modal-title">Session Expired</h4>
                    </div>
                    <div class="modal-body">
                        <form>
                            <h2 class="modal-title">Close this message to login again</h2>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger"
                                data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div id="divError" class="alert alert-danger collapse">
            <a id="linkClose" href="#" class="close">&times;</a>
            <div id="divErrorText"></div>
        </div>
    </div>
  </body>
  
<csl-admin-header></csl-admin-header>

<body style="padding-top:20px">
    <div class="col-md-10 col-md-offset-1">
        <div class="well controlBox">
            <input id="btnFilterSubscribers" class="btn btn-success" (click)="this.loadSubscribers()"
                    type="button" value="Filter Subscribers" />
            <input type="text" onkeyup="this.value=this.value.replace(/[\s]/,'')" #articleIdFilter [ngModel]="filterEmailAddress" (ngModelChange)="filterEmailAddress=$event" class="inputboxfilter" placeholder="Filter by Email address">
        </div>
        <div id="divData" class="well hidden">
            <table  class="table table-bordered" id="tblData" aria-describedby="Manage Email Addresses">
                <thead>
                    <tr  class="success">
                      <th scope="col">Subscriber Id</th>
                      <th scope="col">Email Address</th>
                      <th scope="col">Subscribed?</th>
                    </tr>
                    <csl-spinner  [size]="150"> </csl-spinner>
                    <tr *ngIf="showSpinner">
                        <td><csl-spinner [show]="showSpinner" [size]="150"> </csl-spinner></td>
                        <td><csl-spinner [show]="showSpinner" [size]="150"> </csl-spinner></td>
                    </tr>
                
                </thead>
                    <tr *ngFor="let subscriber of subscribers">
                      <td>{{subscriber.subscriberId}}</td>
                      <td>{{subscriber.email}}</td>
                      <td>
                          <div class="btn-group">
                            <button *ngIf="subscriber.enabledFlag; else elseBlock" type="button" (click)="toggleEnabled(subscriber.subscriberId)" class="btn btn-outline-danger">Disable</button>
                            <ng-template #elseBlock>
                            <button type="submit"  (click)="toggleEnabled(subscriber.subscriberId)" class="btn btn-outline-success">Enable</button>
                            </ng-template>
                        </div>
                      </td>
                    </tr>
                <tbody id="tblBody"></tbody>
            </table>
        </div>
        <div class="modal fade" tabindex="-1" id="errorModal"
              data-bs-keyboard="false" data-bs-backdrop="static">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-bs-dismiss="modal">
                            &times;
                        </button>
                        <h4 class="modal-title">Session Expired</h4>
                    </div>
                    <div class="modal-body">
                        <form>
                            <h2 class="modal-title">Close this message to login again</h2>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger"
                                data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div id="divError" class="alert alert-danger collapse">
            <a id="linkClose" href="#" class="close">&times;</a>
            <div id="divErrorText"></div>
        </div>
    </div>
  </body>
  
import { Injectable } from '@angular/core';
import { Subscriber } from '@models/subscriber';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MessageService } from './message.service';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {
  private subscriberUrl = ''; // live website
  errorData: {};

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'True' })
  };

  private httpNoCacheOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth':'True', 'No-Cache':'true' })
  };

  constructor(
    private http: HttpClient,
    private messageService: MessageService)
  {
    this.subscriberUrl = environment.apiBaseUrl + 'api/subscriber';
  }

  /** post email to server */
  postSubscriber(subscriber: Subscriber) {
    subscriber.enabledFlag = 1;
    return this.http.post<Subscriber>(this.subscriberUrl, subscriber, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  /** GET single subscriber by email address */
  checkSubscriberExist(emailAddress: string): Observable<boolean>{
    return this.http.get<Subscriber>(this.subscriberUrl + '/emailAddress/' + emailAddress, this.httpNoCacheOptions )
      .pipe(
        map(subscriber => subscriber.email === emailAddress)
      );
  }

  /** GET all subscribers */
  getSubscribers(): Observable<Subscriber[]> {
    return this.http.get<Subscriber[]>(this.subscriberUrl, {headers: { 'Content-Type': 'application/json', 'No-Cache': 'true'}}).pipe(
      tap(_ => this.log(`fetched subscribers`)),
      catchError(this.handleError)
    );
  }

  getSubscribersByAddress(emailAddress: string): Observable<Subscriber[]> {
    return this.http.get<Subscriber[]>(this.subscriberUrl + '/emailAddresses/' + emailAddress, {headers: { 'Content-Type': 'application/json', 'No-Cache': 'true'}}).pipe(
      tap(_ => this.log(`fetched subscribers`)),
      catchError(this.handleError)
    );
  }

  putSubscriber(subscriber: Subscriber): Observable<any> {
    return this.http.put<any>(
      this.subscriberUrl + '/' + subscriber.subscriberId, subscriber, {headers: { 'Content-Type': 'application/json'}} ).pipe(
      catchError(this.handleError)
    );
  }

  /** GET newsArticle by id. Will 404 if id not found */
  getSubscriber(subscriberId: string): Observable<Subscriber> {
    return this.http.get<Subscriber>(this.subscriberUrl + '/' + subscriberId, {headers: { 'Content-Type': 'application/json', 'No-Cache': 'true'}}).pipe(
      tap(_ => this.log(`fetched subscriber`)),
      catchError(this.handleError)
    );
  }

  /** Log a SubscriberService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`SubscriberService: ${message}`);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong.

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }
}

<div class="section technology-news">
    <h1 class="section-title">{{articleSectionDescription}}</h1>
    <div class="cat-menu">         
        <a routerLink="/section/{{articleSection}}" routerLinkActive="active">See all</a>					
    </div>
    <div class="row">
        <div class="col-lg-8">
            <csl-post *ngIf="newsArticles" [newsArticle] ="newsArticles[0]"></csl-post>
        </div>
        <div class="col-lg-4">
            <ng-container *ngFor="let newsArticle of newsArticles; index as i; first as isFirst">
                <csl-small-post *ngIf="i>0 && i<3" [newsArticle] ="newsArticle"></csl-small-post>
            </ng-container>
        </div>
    </div>
</div><!--/technology-news--> 
import { Injectable } from '@angular/core';
import { Video } from '@models/video';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MessageService } from '@services/message.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  private videoUrl = ''; // live website
  errorData: {};

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth':'True' })
  };

  private httpNoCacheOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth':'True', 'No-Cache': 'true' })
  };

  constructor(
    private http: HttpClient,
    private messageService: MessageService) 
  { 
    this.videoUrl = environment.apiBaseUrl + 'api/Videos';
  }

  /** GET videos **/
  getVideos(noCache:boolean = false): Observable<Video[]> {
    let httpOtions = this.httpOptions
    if (noCache) {
      httpOtions = this.httpNoCacheOptions
    }
    return this.http.get<Video[]>(this.videoUrl, httpOtions).pipe(
      catchError(this.handleError)
    );
  }

  /** GET videos by section **/
  getVideosForSection(section: string, noCache:boolean = false): Observable<Video[]> {
    let httpOtions = this.httpOptions
    if (noCache) {
      httpOtions = this.httpNoCacheOptions
    }
    return this.http.get<Video[]>(this.videoUrl + '/section/' + section, httpOtions).pipe(
      catchError(this.handleError)
    );
  }
  
  /** For home page **/
  getVideosForHome(): Observable<Video[]> {
    console.log("Getting videos for home");
    return this.http.get<Video[]>(this.videoUrl + '/ChinaVideosHome', this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  /** Post video to server */
  postVideo(video: Video) {
    return this.http.post<Video>(this.videoUrl, video, {headers: { 'Content-Type': 'application/json'}} ).pipe(
      catchError(this.handleError)
    );
  }

  /** Post video view to server */
  postVideoView(video: Video) {
    console.log('Posting video view: ' + video.id);
    return this.http.post<Video>(this.videoUrl + '/videoView', video, this.httpOptions).pipe(
      tap(_ => console.log('Posted video view')),
      catchError(this.handleError)
    );
  }

  /** Put video to server */
  putVideo(video: Video): Observable<any> {
    return this.http.put<any>(
      this.videoUrl + '/' + video.id, video, {headers: { 'Content-Type': 'application/json'}} ).pipe(
      catchError(this.handleError)
    );
  }

  deleteVideo(id: string): Observable<Video[]> {
    return this.http.delete<Video[]>(this.videoUrl + '/' + id, {headers: { 'Content-Type': 'application/json'}} ).pipe(
      catchError(this.handleError)
    );
  }

  /** Log a VideoService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`VideoService: ${message}`);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong.

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }
}

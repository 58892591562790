import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {NewsArticle} from '@models/newsArticle';
import { NewsArticleService } from '@services/news-article.service';

@Component({
  selector: 'csl-feature-post-main',
  templateUrl: './feature-post-main.component.html'
})
export class FeaturePostMainComponent implements OnInit , OnChanges  {
  @Input()
  topNewsArticle: NewsArticle;

  @Input()
  articleHeight: string;

  @Input()
  showCategory = true;

  @Input()
  routeGoal: string;

  storyRouteGoal: string;

  constructor(private newsArticleService: NewsArticleService) {
  }

  ngOnInit() {
    // Intentionally left empty
  }
  ngOnChanges(){
    if (this.topNewsArticle){
      this.topNewsArticle.articleSection = this.newsArticleService.getMapSectionDescription(this.topNewsArticle.articleSection);
      if (!this.topNewsArticle.routeGoal) {
        if (this.routeGoal) {
          this.storyRouteGoal = this.routeGoal
        }
      } else {
        this.storyRouteGoal = this.topNewsArticle.routeGoal;
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'csl-admin-header',
  templateUrl: './admin-header.component.html'
})
export class AdminHeaderComponent implements OnInit {
  userRole: any;

  constructor(public router: Router) {
    this.userRole = localStorage.getItem('userRole');
  }

  ngOnInit(): void {
    // Intentionally left empty
  }

  logOut(){
    console.log('logged out');
    localStorage.removeItem('userToken');
    localStorage.removeItem('userRole');
    this.router.navigate(['login']);
  }
}

<div class="latest-news-wrapper">
    <h1 class="section-title">Highlights</h1>	
    <div id="latest-news">
        <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let newsArticle of newsArticles">
                <ng-template carouselSlide>
                    <csl-medium-post [newsArticle]="newsArticle" articleHeight="258" articleWidth="450" textLimit="50"></csl-medium-post>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</div><!--/.latest-news-wrapper-->
<div class="modal-header bg-primary text-white">
    <h4 *ngIf="isEditMode" class="modal-title" id="modal-basic-title">Manage Facebook Post</h4>
    <h4 *ngIf="!isEditMode" class="modal-title" id="modal-basic-title">Add Facebook Post</h4>
</div>
<div class="modal-body">
            <div class="entry-header">
            <div class="entry-thumbnail">
                <img class="img-fluid" src='{{selectedNewsArticle?.articleImage}}' alt="" />
            </div>
            </div>
            <div  class="post-content">
            <h2>
                {{selectedNewsArticle?.articleTitle}}
            </h2>
            </div>
    <form [formGroup]="editForm" (ngSubmit)="onSubmit()" *ngIf="editForm">
        <input type="hidden" formControlName="articleId" class="form-control" readonly />
        <div *ngIf="isEditMode" class="section business-section">
            <h1 class="section-title">Facebook Post</h1>
            <div class="form-group row d-flex justify-content-center align-items-center">
                <label for="fbPostId" class="col-form-label col-sm">Facebook Post ID:</label>
                <div class="col">
                    <a target="_blank" href="https://www.facebook.com/chinaspotlights/posts/{{selectedNewsArticle?.fbPostId}}">{{selectedNewsArticle?.fbPostId}}</a>
                    <input type="hidden" formControlName="fbPostId" class="form-control" readonly />
                </div>
                <div class="col">
                    <button type="button" *ngIf="isEditMode" [disabled]="isLoading" class="btn btn-danger mr-2" (click)="onDelete()">Delete</button>
                </div>
            </div>
        </div>
        <div *ngIf="!isEditMode" class="form-group row d-flex justify-content-center align-items-center">
            <label for="fbPostDescription" class="col-form-label col-sm-2 text-right">Facebook Post Description:</label>
            <div class="col-sm-10">
                <textarea formControlName="fbPostDescription" class="form-control full-height" rows="5"></textarea>
                <div *ngIf="editFormData.fbPostDescription.hasError('required') && (editFormData.fbPostDescription.dirty || editFormData.fbPostDescription.touched)"
                    class="alert-danger">
                    <span>Facebook post description is required!</span>
                </div>
            </div>
        </div>
        <div *ngIf="!isEditMode" class="modal-footer">
            <button type="button" [disabled]="isLoading" class="btn btn-secondary mr-2" (click)="modal.close('Cancel')">Cancel</button>
            <button [disabled]="isLoading" class="btn btn-primary mr-2">Add</button>
            <csl-spinner  [show]="isLoading" [size]="500"> </csl-spinner>
        </div>
        <div *ngIf="fbErrorFlag">
            <div class="alert-danger">
                <span>An error has occured when handling your request.</span>
            </div>
        </div>
    </form>
    <div *ngIf="isEditMode" class="section business-section">
        <h1 class="section-title">Facebook Advertisement</h1>
        <form [formGroup]="editAdForm" (ngSubmit)="onSubmitAd()" *ngIf="editAdForm">
            <input type="hidden" formControlName="fbPostId" class="form-control" readonly />
            <input type="hidden" formControlName="articleTitle" class="form-control" readonly />
            <table  class="table table-bordered" id="tblData" aria-describedby="Facebook Ads:">
                <thead>
                    <tr>
                    <th scope="col">Ad ID</th>
                    <th scope="col">Ad Set Description</th>
                    <th scope="col">Action</th>
                    </tr>
                    <csl-spinner  [size]="150"> </csl-spinner>
                    <tr *ngIf="isLoading">
                        <td><csl-spinner [show]="isLoading" [size]="150"> </csl-spinner></td>
                        <td><csl-spinner [show]="isLoading" [size]="150"> </csl-spinner></td>
                        <td><csl-spinner [show]="isLoading" [size]="150"> </csl-spinner></td>
                    </tr>
                
                </thead>
                <tbody id="tblBody">
                    <tr *ngFor="let fbAd of fbAds">
                        <td>{{fbAd.fbAdId}}</td>
                        <td>{{fbAd.fbAdSetName}}</td>
                        <td>
                            <button type="button" (click)="onDeleteFbAd(fbAd.id)" class="btn btn-danger leftMargin">Delete</button>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <ul class="list-group">
                                <label formArrayName="selectedFbAdSets" *ngFor="let selectedFbAdSets of fbAdSetsFormArray.controls; let i = index">
                                    <li class="list-group-item"><input class="checkbox_item" type="checkbox" [formControlName]="i">{{availableFbAdSets[i].name}}</li>
                                </label>
                                <li *ngIf="editFormAdData.selectedFbAdSets.hasError('required') && (editFormAdData.selectedFbAdSets.dirty || editFormAdData.selectedFbAdSets.touched)" class="list-group-item">
                                    <div class="alert-danger">
                                        <span>Please choose at least 1 ad set to be added!</span>
                                    </div>
                                </li>
                                <li *ngIf="fbAdErrorFlag">
                                    <div class="alert-danger">
                                        <span>An error was encountered. {{fbAdErrorMessage}}</span>
                                    </div>
                                </li>
                            </ul>
                        </td>
                        <td>
                            <button [disabled]="isLoading || editFormAdData.selectedFbAdSets.hasError('required')" class="btn btn-primary mr-2">Add</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
        <div class="modal-footer">
            <button type="button" [disabled]="isLoading" class="btn btn-secondary mr-2" (click)="modal.close('Back')">Back</button>
        </div>
    </div>
</div>
import { Component, OnInit, Input } from '@angular/core';
import { NewsArticle } from '@models/newsArticle';
import { NewsArticleService } from '@services/news-article.service';
import { SlugifyPipe } from '@pipes/slugify.pipe';

@Component({
  selector: 'csl-post',
  templateUrl: './post.component.html'
})
export class PostComponent implements OnInit {
  @Input()
  newsArticle: NewsArticle;

  @Input()
  articleWidth: string;

  @Input()
  textLimit: string;

  @Input()
  datePipe = 'medium';

  constructor(private slugifyPipe: SlugifyPipe, private newsArticleService: NewsArticleService) { }

  ngOnInit(): void {
    if (this.newsArticle){
      this.newsArticle.articleSection = this.newsArticleService.getMapSectionDescription(this.newsArticle.articleSection);
      this.newsArticle.articleTitleSlug = this.slugifyPipe.transform(this.newsArticle.articleTitle);
    }
  }

}

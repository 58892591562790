<div id="main-wrapper" class="homepage-two fixed-nav">
  <csl-top-slim-banner></csl-top-slim-banner>
  <div id="navigation">
    <csl-top-logo-banner></csl-top-logo-banner>
    <csl-top-navigation-menu></csl-top-navigation-menu>
  </div>
    <div class="container p-3">
      <div class="offset-3 col-6">
        <div class="card">
          <div class="card-header  font-weight-bold">
            Sign Up Form
          </div>
          <div class="card card-body">
            <form [formGroup]="frmSignup" (submit)="submit()">
              <div class="form-group">
                <label for="username" [ngClass]="frmSignup.controls['userName'].invalid ? 'text-danger' : ''">UserName</label>
                <input id="userName" formControlName="userName" type="string" class="form-control" [ngClass]="frmSignup.controls['userName'].invalid ? 'is-invalid' : ''">
                <label class="text-danger" *ngIf="frmSignup.controls['userName'].hasError('required')">
                  UserName is Required!
                </label>
                <label class="text-danger" *ngIf="frmSignup.controls['email'].hasError('email')">
                  Enter a valid email address!
                </label>
              </div>
              <div class="form-group">
                <label for="email" [ngClass]="frmSignup.controls['email'].invalid ? 'text-danger' : ''">Email Address</label>
                <input id="email" formControlName="email" type="email" class="form-control" [ngClass]="frmSignup.controls['email'].invalid ? 'is-invalid' : ''">
                <label class="text-danger" *ngIf="frmSignup.controls['email'].hasError('required')">
                  Email is Required!
                </label>
                <label class="text-danger" *ngIf="frmSignup.controls['email'].hasError('email')">
                  Enter a valid email address!
                </label>
              </div>
              <div class="form-group">
                <label for="password" [ngClass]="frmSignup.controls['password'].invalid ? 'text-danger' : ''">Password:</label>
                <input id="password" formControlName="password" type="password" class="form-control" [ngClass]="frmSignup.controls['password'].invalid ? 'is-invalid' : ''">
                <label class="col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                  Must be at least 8 characters!
                </label>
                <label class="col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                  Must contain at least 1 number!
                </label>
                <label class="col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                  Must contain at least 1 in Capital Case!
                </label>
                <label class="col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                  Must contain at least 1 Letter in Small Case!
                </label>
                <label class="col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">

                  Must contain at least 1 Special Character!
                </label>
              </div>
              <div class="form-group">
                <label for="confirmPassword" [ngClass]="frmSignup.controls['confirmPassword'].invalid ? 'text-danger' : ''">Confirm
                  Password:</label>
                <input id="confirmPassword" formControlName="confirmPassword" type="password" class="form-control"
                  [ngClass]="frmSignup.controls['confirmPassword'].invalid ? 'is-invalid' : ''">
                <label class="text-danger" *ngIf="frmSignup.controls['confirmPassword'].hasError('required')">
                  Password is Required!
                </label>
                <label class="text-danger" *ngIf="frmSignup.controls['confirmPassword'].hasError('NoPassswordMatch')">
                  Password do not match
                </label>
              </div>
              <div class="form-group">
                <button [disabled]="frmSignup.invalid" type="submit" class="btn btn-primary btn-block font-weight-bold" (click)="this.toggleSpinner()"><csl-spinner [show]="showSpinner" [size]="150"> </csl-spinner>Sign
                  up</button>
              </div>
              <div class="alert alert-success" *ngIf="submitted">
                Thank you for registering, please proceed to login.
              </div>
              <div class="alert alert-danger" *ngIf="error">
                Sorry we encountered error when trying to register your details. Please try again later!
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  <csl-footer></csl-footer>
</div><!--/#main-wrapper-->

<nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
    <div id="menubar">
        <div class="container">

  <!-- Step 3: Toggle the value of the property when the toggler button is clicked. -->
  <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
    &#9776;
  </button>
  <a class="navbar-brand d-lg-none" routerLink="/home" routerLinkActive="active">
    <img class="main-logo img-fluid" src="./assets/images/presets/preset1/logo.png" alt="logo">
  </a>
  <!-- Step 2: Add the ngbCollapse directive to the element below. -->
  <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
    <ul class="nav navbar-nav">
      <li class="nav-item dropdown">
        <!-- Step 4: Close the menu when a link is clicked. -->
        <a class="nav-link" (click)="isMenuCollapsed = true" routerLink="/home" routerLinkActive="active">Home</a>
      </li>
      <li class="nav-item dropdown">
          <a class="nav-link" (click)="isMenuCollapsed = true" routerLink="/section/China Business" routerLinkActive="active">Business</a>
      </li>
      <li class="nav-item">
        <a href="javascript:void(0);"  class="dropdown-toggle" data-bs-toggle="dropdown">Culture</a>
        <ul class="dropdown-menu">
          <a class="nav-link dropdown" (click)="isMenuCollapsed = true" routerLink="/section/China Culture" routerLinkActive="active">Culture</a>
          <a class="nav-link dropdown" (click)="isMenuCollapsed = true" routerLink="/section/China Travel" routerLinkActive="active">Travel</a>
          <a class="nav-link dropdown" (click)="isMenuCollapsed = true" routerLink="/section/China Food" routerLinkActive="active">Food</a>
          <a class="nav-link dropdown" (click)="isMenuCollapsed = true" routerLink="/section/China History" routerLinkActive="active">History</a>
        </ul>
    </li>
      <li class="nav-item">
        <a class="nav-link dropdown" (click)="isMenuCollapsed = true" routerLink="/section/China Politics" routerLinkActive="active">Politics</a>
      </li>
      <li class="nav-item dropdown">
          <a class="nav-link" (click)="isMenuCollapsed = true" routerLink="/section/China Health" routerLinkActive="active">Health</a>
      </li>
      <li class="nav-item dropdown">
          <a class="nav-link" (click)="isMenuCollapsed = true" routerLink="/section/China Technology" routerLinkActive="active">Technology</a>
      </li>
      <li class="nav-item dropdown">
          <a class="nav-link" (click)="isMenuCollapsed = true" routerLink="/section/China Science" routerLinkActive="active" >Science</a>
      </li>
      <li class="nav-item dropdown">
          <a class="nav-link" (click)="isMenuCollapsed = true" routerLink="/section/China Society" routerLinkActive="active">Society</a>
      </li>
      <li class="nav-item">
          <a class="nav-link dropdown" (click)="isMenuCollapsed = true" routerLink="/section/China Human Rights" routerLinkActive="active">Human Rights</a>
      </li>
      <li class="nav-item">
        <a class="nav-link dropdown" (click)="isMenuCollapsed = true" routerLink="/section/China Military" routerLinkActive="active">Military</a>
      </li>
      <li class="nav-item">
        <a href="javascript:void(0);"  class="dropdown-toggle" data-bs-toggle="dropdown">World</a>
        <ul class="dropdown-menu">
          <a class="nav-link dropdown" (click)="isMenuCollapsed = true" routerLink="/section/HK" routerLinkActive="active">Hong Kong</a>
          <a class="nav-link dropdown" (click)="isMenuCollapsed = true" routerLink="/section/TW" routerLinkActive="active">Taiwan</a>
          <a class="nav-link dropdown" (click)="isMenuCollapsed = true" routerLink="/section/US" routerLinkActive="active">USA</a>
        </ul>
      </li>
      <li class="nav-item">
        <a class="nav-link dropdown" (click)="isMenuCollapsed = true" routerLink="/topQuotes" routerLinkActive="active">Quotes</a>
      </li>
      <li class="nav-item">
        <a class="nav-link dropdown" (click)="isMenuCollapsed = true" routerLink="/section/Editorial" routerLinkActive="active">Editorial</a>
      </li>
      <li class="nav-item">
        <a href="javascript:void(0);"  class="dropdown-toggle" data-bs-toggle="dropdown">About CSL</a>
        <ul class="dropdown-menu">
          <a class="nav-link dropdown" (click)="isMenuCollapsed = true" routerLink="/aboutUs" routerLinkActive="active">About Us</a>
          <a class="nav-link dropdown" (click)="isMenuCollapsed = true" routerLink="/privacy" routerLinkActive="active">Privacy</a>
          <a class="nav-link dropdown" (click)="isMenuCollapsed = true" routerLink="/contactUs" routerLinkActive="active">Contact Us</a>
        </ul>
    </li>
    </ul>
  </div>
  </div>
  </div>
</nav>

import { Injectable } from '@angular/core';
import { Comment } from '@models/comment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MessageService } from '@services/message.service';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CommentService {

  private commentUrl = ''; // live website
  errorData: {};

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth':'True' })
  };

  private httpNoCacheOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth':'True', 'No-Cache':'true' })
  };

  constructor(
    private http: HttpClient,
    private messageService: MessageService) {
      this.commentUrl = environment.apiBaseUrl + 'api/Comments';
     }

  /** post comment to server */
  postComment(comment: Comment) {
    return this.http.post<Comment>(this.commentUrl, comment, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  /** GET newsArticle by id. Will 404 if id not found */
  getCommentForArticle(articleId: string, noCache:boolean = false): Observable<Comment[]> {
    let httpOtions = this.httpOptions
    if (noCache) {
      httpOtions = this.httpNoCacheOptions
    }
    return this.http.get<Comment[]>(this.commentUrl + '/article/' + articleId, httpOtions).pipe(
      tap(_ => this.log(`fetched comment id=${articleId}`)),
      catchError(this.handleError)
    );
  }

  /** GET newsArticle by id. Will 404 if id not found */
  getComments(noCache:boolean = false): Observable<Comment[]> {
    let httpOtions = this.httpOptions
    if (noCache) {
      httpOtions = this.httpNoCacheOptions
    }
    return this.http.get<Comment[]>(this.commentUrl, httpOtions).pipe(
      tap(_ => this.log(`fetched comments`)),
      catchError(this.handleError)
    );
  }

  deleteComment(commentId: string): Observable<Comment[]> {
    return this.http.delete<Comment[]>(this.commentUrl + '/' + commentId, {headers: { 'Content-Type': 'application/json'}} ).pipe(
      tap(_ => this.log(`deleted comment id=${commentId}`)),
      catchError(this.handleError)
    );
  }

  /** Log a EmailService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`EmailService: ${message}`);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong.

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }
}

<div id="main-wrapper" class="homepage-two fixed-nav">
  <csl-top-slim-banner></csl-top-slim-banner>
  <div id="navigation">
    <csl-top-logo-banner></csl-top-logo-banner>
    <csl-top-navigation-menu></csl-top-navigation-menu>
  </div><!--/#navigation-->

  <div class="container">
    
    <div *ngIf="articleSection!=='Editorial'" class="section">
      <div class="row">
        <div class="site-content col-lg-9">
          <div class="row">
            <div class="col-md-8">
              <csl-feature-post-main *ngIf="frontPageTopStories" [topNewsArticle] ="frontPageTopStories[0]" [articleHeight] ="330"></csl-feature-post-main>
            </div>
            <div class="col-md-4">
              <csl-feature-post-main *ngIf="frontPageTopStories" [topNewsArticle] ="frontPageTopStories[1]" [articleHeight] ="330"></csl-feature-post-main>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <csl-feature-post-main *ngIf="frontPageTopStories" [topNewsArticle] ="frontPageTopStories[2]" [articleHeight] ="210" ></csl-feature-post-main>
            </div>
            <div class="col-md-4">
              <csl-feature-post-main *ngIf="frontPageTopStories" [topNewsArticle] ="frontPageTopStories[3]" [articleHeight] ="210"></csl-feature-post-main>
            </div>
            <div class="col-md-4">
              <csl-feature-post-main *ngIf="frontPageTopStories" [topNewsArticle] ="frontPageTopStories[4]" [articleHeight] ="210"></csl-feature-post-main>
            </div>
          </div>
        </div><!--/#content-->

        <div class="col-lg-3 d-none d-lg-block">
          <div class="add featured-add" id="370465602">
            <csl-media-net-ad [advertisement]="advertisement1">
            </csl-media-net-ad>
          </div>
        </div><!--/#add-->
      </div>
    </div><!--/.section-->

    <csl-short-full-width-advert *ngIf="articleSection!=='Editorial'" ></csl-short-full-width-advert>
    
    <div class="section">	
      <csl-home-latest-news *ngIf="sectionHighlights" [newsArticles]="sectionHighlights"></csl-home-latest-news>
    </div><!--/.section-->
    <div class="section">
      <div class="row">
        <div class="col-md-8 col-lg-9">
          <div id="site-content">
            <div class="row">
              <div class="col-lg-8 col-md-6 tr-sticky">
                <div class="left-content theiaStickySidebar">
                  <ng-container *ngFor="let sectionArticle of sectionArticles; index as i; odd as isOdd; even as isEven">
                    <csl-home-section-four-boxes *ngIf="isEven" 
                      [newsArticles]="sectionArticle.newsArticles" 
                      [articleSection]="sectionArticle.section" 
                      [articleSectionDescription]="sectionArticle.sectionDescription">
                    </csl-home-section-four-boxes>
                    <csl-home-section-three-boxes *ngIf="isOdd" 
                      [newsArticles]="sectionArticle.newsArticles" 
                      [articleSection]="sectionArticle.section" 
                      [articleSectionDescription]="sectionArticle.sectionDescription">
                    </csl-home-section-three-boxes>
                  </ng-container>

                  <csl-four-stories-block-one-image *ngIf="regionArticles" 
                    [sectionArticles]="regionArticles">
                  </csl-four-stories-block-one-image>
                </div><!--/.left-content-->
              </div>
              <div class="col-lg-4 col-md-6 tr-sticky">
                <div class="middle-content theiaStickySidebar">
                  <ng-container *ngFor="let sectionVerticalArticle of sectionVerticalArticles; index as i; odd as isOdd; even as isEven">
                    <csl-home-vertical-section-five
                      [newsArticles]="sectionVerticalArticle.newsArticles" 
                      [articleSection]="sectionVerticalArticle.section" 
                      [articleSectionDescription]="sectionVerticalArticle.sectionDescription">
                    </csl-home-vertical-section-five>
                  </ng-container>

                  <!--csl-home-video *ngIf="videos" [videos]="videos"></csl-home-video-->
                </div><!--/.middle-content-->
              </div>
            </div>
          </div><!--/#site-content-->
        </div>
        <div class="col-md-4 col-lg-3 tr-sticky">
          <div id="sitebar" class="theiaStickySidebar">
            <csl-this-is-rising *ngIf="sectionHighlights"></csl-this-is-rising>
          </div><!--/#sitebar-->
        </div>
      </div>
    </div><!--/.section-->

    <div class="section" *ngIf="articleSection">
      <div class="row">
        <div class="col-md-8 col-lg-9">
          <div id="site-content" class="site-content">

            <div class="section listing-news" infinite-scroll (scrolled)="onScrollDown()">
              <ng-container *ngFor="let sectionHistoryArticle of sectionHistoryArticles; index as i; odd as isOdd; even as isEven">
                <csl-post [newsArticle] ="sectionHistoryArticle" articleWidth="450" textLimit="150" datePipe='MMM d, y'></csl-post>
              </ng-container>              
            </div>

          </div><!--/#site-content-->
        </div><!--/.col-sm-9 -->
      </div>				
    </div><!--/.section-->

  </div><!--/.container-->

  <csl-footer [newsArticles]="newsArticles"></csl-footer>
</div><!--/#main-wrapper-->

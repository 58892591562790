<div class="modal-header bg-primary text-white">
    <h4 *ngIf="isEditMode" class="modal-title" id="modal-basic-title">Update Video</h4>
    <h4 *ngIf="!isEditMode" class="modal-title" id="modal-basic-title">Add Video</h4>
</div>
<div class="modal-body">
    <form [formGroup]="editForm" (ngSubmit)="onSubmit()" *ngIf="editForm">
        <div *ngIf="isEditMode" class="form-group row d-flex justify-content-center align-items-center">
            <label for="id" class="col-form-label col-sm-2 text-right">ID:</label>
            <div class="col-sm-10">
                <input type="text" formControlName="id" class="form-control" readonly />
            </div>
        </div><div class="form-group row d-flex justify-content-center align-items-center">
            <label for="linkId" class="col-form-label col-sm-2 text-right">Link ID:</label>
            <div class="col-sm-10">
                <input type="text" formControlName="linkId" class="form-control" />
                <div *ngIf="editFormData.linkId.hasError('required') && (editFormData.linkId.dirty || editFormData.linkId.touched)"
                    class="alert-danger">
                    <span>Link ID is required!</span>
                </div>
            </div>
        </div>
        <div class="form-group row d-flex justify-content-center align-items-center">
            <label for="provider" class="col-form-label col-sm-2 text-right">Provider:</label>
            <div class="col-sm-10">
                <select formControlName="provider" class="form-control">
                    <option *ngFor="let provider of videoProviders" [selected]="" [value]="provider.providerCode">{{provider.name}}</option>
                </select>
                <div *ngIf="editFormData.provider.hasError('required') && (editFormData.provider.dirty || editFormData.provider.touched)"
                    class="alert-danger">
                    <span>Provider is required!</span>
                </div>
            </div>
        </div>
        <div class="form-group row d-flex justify-content-center align-items-center">
            <label for="title" class="col-form-label col-sm-2 text-right">Title:</label>
            <div class="col-sm-10">
                <input type="text" formControlName="title" class="form-control" />
                <div *ngIf="editFormData.title.hasError('required') && (editFormData.title.dirty || editFormData.title.touched)"
                    class="alert-danger">
                    <span>Title is required!</span>
                </div>
            </div>
        </div>
        <div class="form-group row d-flex justify-content-center align-items-center">
            <label for="section" class="col-form-label col-sm-2 text-right">Section:</label>
            <div class="col-sm-10">
                <select formControlName="section" class="form-control">
                    <option *ngFor="let section of videoSections" [selected]="" [value]="section.sectionCode">{{section.name}}</option>
                </select>
                <div *ngIf="editFormData.section.hasError('required') && (editFormData.section.dirty || editFormData.section.touched)"
                    class="alert-danger">
                    <span>Section is required!</span>
                </div>
            </div>
        </div>
        <div *ngIf="isEditMode" class="form-group row d-flex justify-content-center align-items-center">
            <label for="date" class="col-form-label col-sm-2 text-right">Date Added:</label>
            <div class="col-sm-10">
                <input type="text" formControlName="date" class="form-control" readonly />
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" [disabled]="isLoading" class="btn btn-primary mr-2" (click)="modal.close('Cancel')">Cancel</button>
            <button *ngIf="isEditMode" [disabled]="isLoading" class="btn btn-primary mr-2">Update</button>
            <button *ngIf="!isEditMode" [disabled]="isLoading" class="btn btn-primary mr-2">Add</button>
        </div>
 
    </form>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark"  (click)="modal.close('Yes')">Update</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Cancel')">Cancel</button>
</div> -->
<div class="footer-widget">
    <div class="container">
      <div class="row">
        <div class="col-sm-4">
          <csl-about-us></csl-about-us>
        </div>
        <div class="col-sm-4">
          <csl-flickr-widget *ngIf="newsArticles" [newsArticles]="newsArticles"></csl-flickr-widget>
        </div>
        <div class="col-sm-4">
          <csl-news-letter-widget></csl-news-letter-widget>
        </div>
      </div>
    </div>
  </div><!--/.footer-widget-->

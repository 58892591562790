import { Component, Input } from '@angular/core';
import { NewsArticle } from '@models/newsArticle';

@Component({
  selector: 'csl-home-section-four-boxes',
  templateUrl: './home-section-four-boxes.component.html'
})
export class HomeSectionFourBoxesComponent {
  @Input()
  newsArticles: NewsArticle[];

  @Input()
  articleSection: string;

  @Input()
  articleSectionDescription: string;

}

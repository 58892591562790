import { Component, Input } from '@angular/core';
import { SectionArticle } from '@models/sectionArticle';

@Component({
  selector: 'csl-four-stories-block-one-image',
  templateUrl: './four-stories-block-one-image.component.html',
  styleUrls: ['./four-stories-block-one-image.component.css']
})

export class FourStoriesBlockOneImageComponent {

  @Input()
  sectionArticles: SectionArticle[];

}

<div class="widget news-letter">
    <h1 class="section-title title"><ng-template [ngIf]="titleText" [ngIfElse]="defaultTitle">{{titleText}}</ng-template></h1>
    <p>China is AMAZING! We trawl 10,000 news sources a week to plot the trends emerging from China that will shape the world. Sign up now!</p>

    <form (ngSubmit)="onSubmit()" [formGroup]="emailForm">
        <input type="text" id="email" class="form-control border border-success" formControlName="email" placeholder="Your email" email>
            <div *ngIf="email.invalid && (email.dirty || email.touched)"
              class="alert alert-danger">
              <div *ngIf="email.errors.required">
                Email is required.
              </div>
              <div *ngIf="email.errors.email">
                Email must be in the correct format.
              </div>
              <div *ngIf="email.errors?.exmailExist">
                Email already subscribed.
              </div>    
            </div>
        <button type="submit" class="btn btn-success" [disabled]="!emailForm.valid || submitted || !email.value">Subscribe</button>
    </form>    
    <div *ngIf="showSpinner">
      <img src="assets/images/spinner.gif" width="50px" alt="">
    </div>
    <div class="alert alert-success" *ngIf="subscribeSuccess">
      {{subscribeSuccess}}
    </div>
    <div class="alert alert-danger" *ngIf="subscribeError">
      Sorry, you might have previously unsubcribed from our newsletter and you will need to use following link to re-subscribe: <a target="_blank" href="http://eepurl.com/hA5wm5" rel="noopener">Re-Subscribe to newsletter</a>
    </div>
  </div>
  
  <ng-template #defaultTitle>
      Weekly NewsLetter
  </ng-template>
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@services/authentication.service';
import { CustomValidator } from '@validators/custom-validator';
import { Router } from '@angular/router';

@Component({
  selector: 'csl-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  public frmChangePassword: UntypedFormGroup;
  error: any;
  submitted = false;
  showSpinner = false;

  constructor(private fb: UntypedFormBuilder, private authService:AuthenticationService, public router: Router) {
    this.frmChangePassword = this.createSignupForm();
    this.error = false;
    this.submitted = false;
  }

  createSignupForm(): UntypedFormGroup {
    return this.fb.group(
      {
        oldPassword: [
          null,
          Validators.compose([Validators.required])
        ],
        password: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidator.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidator.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidator.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            CustomValidator.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            Validators.minLength(8)
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidator.passwordMatchValidator
      }
    );
  }

  submit() {
    // do signup or something
    this.error = false;
    this.submitted = false;
    return this.authService.
      changePassword(this.frmChangePassword.value.oldPassword,
      this.frmChangePassword.value.password).subscribe(
              _val =>  { this.frmChangePassword.reset();
                        this.submitted = true;
                        this.toggleSpinner();},
        error => {this.error = error;
          this.toggleSpinner()
          this.frmChangePassword.reset();
        }
      )
  }

  toggleSpinner() {
    this.showSpinner = !this.showSpinner;

}

  ngOnInit(): void {
    // Intentionally left empty
  }

}


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '@components/home/home.component';
import { StoryPageComponent } from '@components/story-page/story-page.component';
import { TopQuotesComponent } from '@components/top-quotes/top-quotes.component';
import { AboutUsPageComponent } from '@components/about-us-page/about-us-page.component';
import { ContactUsComponent } from '@components/contact-us/contact-us.component';
import { PrivacyComponent } from '@components/privacy/privacy.component';
import { RegisterComponent } from '@components/admin/register/register.component';
import { LoginComponent } from '@components/admin/login/login.component';
import { ManageStoriesComponent } from '@components/admin/manage-stories/manage-stories.component';
import { ManageUsersComponent } from '@components/admin/manage-users/manage-users.component';
import { ChangePasswordComponent } from '@components/admin/change-password/change-password.component';
import { ManageCommentsComponent } from '@components/admin/manage-comments/manage-comments.component';
import { ManageSubscribersComponent } from '@components/admin/manage-subscribers/manage-subscribers.component';
import { ManageVideosComponent } from '@components/admin/manage-videos/manage-videos.component';
import { AuthGuard } from 'app/auth/auth.guard';
import { RoleAuthGuard } from 'app/auth/role.auth.guard';
import { EditCreateStoryComponent } from '@components/admin/edit-create-story/edit-create-story.component';
import { EditQuoteComponent } from '@components/admin/edit-quote/edit-quote.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' } ,
   { path: 'home', component: HomeComponent },
   { path: 'section/:section', component: HomeComponent },
   { path: 'story/:story/:slug', component: StoryPageComponent},
   { path: 'story/:story', component: StoryPageComponent},
   { path: 'aboutUs', component: AboutUsPageComponent } ,
   { path: 'contactUs', component: ContactUsComponent },
   { path: 'topQuotes', component: TopQuotesComponent },
   { path: 'register', component: RegisterComponent },
   { path: 'login', component: LoginComponent },
   { path: 'privacy', component: PrivacyComponent },
   { path: 'editCreate', component: EditCreateStoryComponent },
   { path: 'manageStories', component:  ManageStoriesComponent, canActivate:[AuthGuard]},
   { path: 'editQuotes', component:  EditQuoteComponent, canActivate:[AuthGuard]},
   { path: 'manageComments', component:  ManageCommentsComponent, canActivate:[AuthGuard]},
   { path: 'manageSubscribers', component:  ManageSubscribersComponent, canActivate:[AuthGuard]},
   { path: 'manageVideos', component:  ManageVideosComponent, canActivate:[AuthGuard]},
   { path: 'manageUsers', component:  ManageUsersComponent, canActivate:[RoleAuthGuard],
      data: {allowedRoles: ['SystemAdmin']}
    },
   { path: 'changePassword', component:  ChangePasswordComponent, canActivate:[AuthGuard]},
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

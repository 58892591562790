import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class AuthGuard  {
  constructor(private router : Router){}
  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot):  boolean {
      if (localStorage.getItem('userToken') != null) {
        return true;
      }
      this.router.navigate(['/login']);
      return false;
  }
}

declare const $: any;

import { Component, OnInit } from '@angular/core';
import { FeedbackService } from '@services/feedback.service';
import { Feedback } from '@models/feedback';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'csl-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  model: Feedback;
  error = false;
  feedbackForm: UntypedFormGroup;
  submitted = false;

  constructor(private feedbackService: FeedbackService, private fb: UntypedFormBuilder) { }

  ngOnInit() {

    this.feedbackForm = this.fb.group({
      readerName: ['', [Validators.required] ],
      email: ['', [Validators.required, Validators.email] ],
      text: ['', [Validators.required ]],
      subject: ['', [Validators.required ]],
      date: [''],
      categoryId: [1]
    });
    this.initSticky()

  }

  onSubmit() {
    this.feedbackForm.patchValue({articleId: this.feedbackForm, date: new Date()});

    return this.feedbackService.postFeedback( this.feedbackForm.value).subscribe(
      (data: Feedback) => {
        this.model = data;
        this.feedbackForm.reset();
        this.submitted = true;
        
      },
      error => this.error = error
    );
  }




  get name() {
    if (this.feedbackForm) {
      return this.feedbackForm.get('readerName');
    }
    return null;
  }

  get email() {
    if (this.feedbackForm) {
      return this.feedbackForm.get('email');
    }
    return null;
  }

  get text() {
    if (this.feedbackForm) {
      return this.feedbackForm.get('text');
    }
    return null;
  }

  get subject() {
    if (this.feedbackForm) {
      return this.feedbackForm.get('subject');
    }
    return null;
  }

  initSticky() {
    $(document).ready(function () {
      $('.tr-sticky')
          .theiaStickySidebar({
              additionalMarginTop: 0
          });
    });
  }
}

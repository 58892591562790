<div class="modal-header bg-primary text-white">
    <div class="modal-title" id="modal-basic-title">Facebook Ads Status</div>
</div>
<div class="modal-body">
    <table  class="table table-bordered" id="tblData" aria-describedby="Facebook Ads:">
        <thead>
            <tr>
                <th style="width: 8%" scope="col"></th>
                <th scope="col">Name</th>
                <th style="width: 20%" scope="col">Ad Set</th>
                <th style="width: 18%" scope="col">Status</th>
                <th style="width: 14%" scope="col">Created</th>
                </tr>  
        </thead>
        <tbody id="tblBody">
            <tr *ngFor="let fbAdStatus of fbAdStatuses">
                <td><img class="img-fluid ad_thumbnail" src='{{fbAdStatus?.creative.image_url}}' alt="" /></td>
                <td><a target="_blank" href="https://www.facebook.com/chinaspotlights/posts/{{fbAdStatus?.creative.post_id}}">{{fbAdStatus.name}}</a></td>
                <td>{{fbAdStatus.adset_name}}</td>
                <td>{{fbAdStatus.effective_status}}</td>
                <td>{{fbAdStatus.created_time | date:'MMM d, y'}}</td>
            </tr>        
        </tbody>
        <tfoot id="tblFooter">
            <tr>
                <td colspan="5">
                    <div *ngIf="fbAdErrorFlag" class="alert-danger">
                        <span>An error has occured when handling your request.</span>
                    </div>
                    <div class="pull-right">
                        <csl-spinner class="spinner" [show]="isLoading" [size]="150"> </csl-spinner>
                        <button [disabled]="isLoading || (fbAdStatusPaging && !fbAdStatusPaging.previous)" class="btn btn-primary mr-2" (click)="onGetMore('before=' + fbAdStatusPaging.cursors.before)">
                            <span aria-hidden="true">&laquo;</span>
                            <span class="sr-only">Previous</span>
                        </button>
                        <button [disabled]="isLoading || (fbAdStatusPaging && !fbAdStatusPaging.next)" class="btn btn-primary mr-2" (click)="onGetMore('after=' + fbAdStatusPaging.cursors.after)">
                            <span aria-hidden="true">&raquo;</span>
                            <span class="sr-only">Next</span>
                        </button>
                    </div>
                </td>
            </tr>
        </tfoot>
    </table>
</div>
<div class="modal-footer">
    <button type="button" [disabled]="isLoading" class="btn btn-secondary mr-2" (click)="modal.close('Back')">Back</button>
</div>
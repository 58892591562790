<div id="main-wrapper" class="homepage-two fixed-nav">
  <csl-top-slim-banner></csl-top-slim-banner>
  <div id="navigation">
    <csl-top-logo-banner></csl-top-logo-banner>
    <csl-top-navigation-menu></csl-top-navigation-menu>
  </div><!--/#navigation-->



  <div class="container">

    <div class="about-us welcome-section">
      <div class="row">
        <div class="col-lg-6 content-section">
          <div class="about-us-content">
            <h2>About Us</h2>
            <p>For people interested in events in China, who feel that they are not getting the full picture from other media, China Spotlight is a media platform focused on China that gives a broad overview of news and events there. Unlike other English language media we aggregate news from numerous sources and plan to decipher the common patterns to give you the full unbiased picture from multiple perspectives (but it's early days in the project, so bear with us). </p>
            <p>We plan to give our patrons input into the direction we go with new features, allowing them to vote for/or suggest features, if this is something you are interested in, please contact us.
            </p>
          </div>
        </div>
        <div class="col-lg-6 image-section">
          <div class="about-us-image">
            <img class="img-fluid" src="./assets/images/others/website Fb Banner-04.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="about-us">
      <div class="row">
        <div class="col-md-4">
          <div class="about-us-content">
            <h2>Background</h2>
            <p>China is the longest continuous civilization in the world today stretching back more than five thousand years. As modern China matures into a superpower that helps shape the world our mission is to help you understand the significant events that occur there. </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="about-us-content">
            <h2>Our Approach</h2>
            <p>We don't create the news, we will direct you to those that do. Our aim is to use two strategies to help you understand China; 1. Create a tribe of patrons who are engaged in shaping our platform. 2. Use AI and tech to discover the patterns behind significant events.</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="about-us-content">
            <h2>Become a Patron</h2>
            <p>There is so much fake news today, imagine you could shape a news service that helps you understand the story behind the news. Why did someone comment that way, whats their history on the topic? How has the story evolved? Help shape China Spotlight by becoming a patron. </p>
          </div>
        </div>
      </div>
    </div>
    <div class="our-team">
      <h1 class="section-title title">China Spotlight Team</h1>
      <div class="team-members">
        <div class="row">
          <div class="col-sm-6 col-md-4 col-lg-2">
            <div class="single-member">
              <div class="member-image">
                <img class="img-fluid" src="./assets/images/team/Kathleen.png" alt="" />
              </div>
              <h3>Kathleen Commons</h3>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-2">
            <div class="single-member">
              <div class="member-image">
                <img class="img-fluid" src="./assets/images/team/Martin.png" alt="" />
              </div>
              <h3>Martin Murphy</h3>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-2">
            <div class="single-member">
              <div class="member-image">
                <img class="img-fluid" src="./assets/images/team/Padriag.png" alt="" />
              </div>
              <h3>Pádraig Ó Dálaigh</h3>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-2">
            <div class="single-member">
              <div class="member-image">
                <img class="img-fluid" src="./assets/images/team/YorkKie.png" alt="" />
              </div>
              <h3>York Kie Tan</h3>
            </div>
          </div>
        </div>
      </div>
    </div><!-- our team -->
        
    <div class="testimonials">
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>
          <div class="row testimonial">
            <div class="col">
              <img class="img-fluid img-circle" style="max-width: 100px;" src="./assets/images/team/Martin.png" alt="" />
              <h3>Martin Murphy <span>Co-Founder, Chief Data Scientist</span></h3>
              <p>“There is so much information out there it is hard to discipher the fake from whats real. My aim is to use data science to help you figure this out.”</p>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="row testimonial">
            <div class="col">
              <img class="img-fluid img-circle" style="max-width: 100px;" src="./assets/images/team/YorkKie.png" alt="" />
              <h3>York Tan Kie <span>Chief Architect</span></h3>
              <p>“My passion has always been making great products. I hope I can have a hand in shaping a news platform that really serves it's readers.”</p>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="row testimonial">
            <div class="col">
              <img class="img-fluid img-circle" style="max-width: 100px;" src="./assets/images/team/Kathleen.png" alt="" />
              <h3>Kathleen Commons <span>Editor</span></h3>
              <p>“I have worked for more than ten years as a editor in an international news paper with global reach, I hope to help China Spotlight to achieve a similar impact.”</p>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="row testimonial">
            <div class="col">
              <img class="img-fluid img-circle" style="max-width: 100px;" src="./assets/images/team/Padriag.png" alt="" />
              <h3>Pádraig Ó Dálaigh<span>Community Manager</span></h3>
              <p>“As a photographer, and video journalist, I'm looking forward to creating a unique news experience with China Spotlight. Engage with me in our community!”</p>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div><!--/.testomonials-->
  </div><!--/.container-->

  <csl-footer></csl-footer>

</div><!--/#main-wrapper-->

import { Component, Input } from '@angular/core';
import { NewsArticle } from '@models/newsArticle';

@Component({
  selector: 'csl-country-panel',
  templateUrl: './country-panel.component.html'
})
export class CountryPanelComponent {

  @Input()
  countryNewsArticles: NewsArticle[];

  @Input() 
  articleSection: string;

  @Input()
  articleSectionDescription: string;

  @Input()
  datePipe = 'medium';
}

<div id="main-wrapper" class="homepage-two fixed-nav">
  <csl-top-slim-banner></csl-top-slim-banner>
  <div id="navigation">
    <csl-top-logo-banner></csl-top-logo-banner>
    <csl-top-navigation-menu></csl-top-navigation-menu>
  </div>

  <div class="container p-3">
    <div class="offset-3 col-6">
      <div class="card">
        <form [formGroup]="frmLogin" (ngSubmit)="onSubmit()">
            <!--Table to capture username and password-->
            <table class="table table-bordered">
              <caption></caption>
                <thead>
                    <tr class="success">
                        <th colspan="2" class="card-header  font-weight-bold" id="LoginHeader">
                            Existing User Login
                            <a  routerLink="/register" routerLinkActive="active" class="btn btn-success pull-right">
                                Register
                            </a>
                        </th>
                    </tr>
                </thead>
                <tbody>

                    <tr>
                        <td>Username:</td>
                        <td>
                            <input type="text" id="txtUsername" placeholder="Username" formControlName="username" />
                        </td>
                    </tr>

                    <tr>
                        <td>Password:</td>
                        <td>
                            <input type="password" id="txtPassword"
                                  placeholder="Password" formControlName="password"/>
                        </td>
                    </tr>

                    <tr class="success" >
                        <td colspan="2">
                            <input id="btnLogin" class="btn btn-success" type="submit" value="Login" (click)="this.toggleSpinner()"><csl-spinner [show]="showSpinner" [size]="150"> </csl-spinner>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!--Bootstrap alert to display error message if the login fails-->
            <div class="alert alert-danger" *ngIf="error"  id="divError">
              Sorry we encountered error when trying to log you in. Please try again, or contact your administrator!
            </div>
          </form>
        </div>
    </div>
  </div>
</div><!--/#main-wrapper-->

import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'csl-media-net-ad',
  templateUrl: './media-net-ad.component.html'
})
export class MediaNetAdComponent {
  @ViewChild('script') scriptFromHTML: ElementRef;

  @Input()
 advertisement: string;

  convertToScript() {
    let element = this.scriptFromHTML.nativeElement;
    let script = document.createElement('script');
    script.id = 'mNCC';
    script.lang = 'javascript';
    script.type = 'text/javascript';

    script.innerHTML = `
    try {
      window._mNHandle.queue.push(function (){
          window._mNDetails.loadTag(${this.advertisement});
      });
    }
    catch (error) {}`;
    script['data-cfasync'] = 'false';
    const parent = element.parentElement;
    parent.parentElement.replaceChild(script, parent);
}

ngAfterViewInit() {
    this.convertToScript();
}

}

<div class="widget">
  <h1 class="section-title title">This is Rising!</h1>
  <ul class="post-list">
    <li *ngFor="let risingArticle of newsArticles">
      <a  [routerLink]="['/story', risingArticle?.id,risingArticle?.articleTitleSlug]" routerLinkActive="active">
        <div class="post small-post">
          <div class="entry-header">
            <div class="entry-thumbnail">
              <img class="img-fluid" src="{{risingArticle.articleImage}}" alt="" />
            </div>
          </div>
          <div class="post-content">
            <div class="video-catagory"><a href="#">{{risingArticle.articleSection}}</a></div>
            <h2 class="entry-title postHeadline">
              <a [routerLink]="['/story', risingArticle?.id,risingArticle?.articleTitleSlug]" routerLinkActive="active">{{risingArticle.articleTitle}}</a>
            </h2>
          </div>
        </div><!--/post-->
      </a>
    </li>
  </ul>
</div><!--/#widget-->

<csl-admin-header></csl-admin-header>
<div class="controlBox">
    <input id="prepareData" class="btn btn-info" type="button" value="Update Site Cache" (click)="runPrepareSiteCache()"/>
</div>


<div class="mat-elevation-z8">
    <table id="tblData" mat-table [dataSource]="dataSource">
    <caption>List of articles in repository.</caption>
    <!-- articleId Column -->
    <ng-container matColumnDef="id">
        <th scope="col" title="Quote ID" mat-header-cell *matHeaderCellDef>Quote ID<csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- articleTitle Column -->
    <ng-container matColumnDef="articleTitle">
        <th scope="col" title="Title" mat-header-cell *matHeaderCellDef> Article Title <csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
        <td mat-cell *matCellDef="let element"> <a target="_blank" [routerLink]="['/story', element.articleId]" rel="noopener">{{element.articleTitle}}</a></td>
    </ng-container>

    <!-- dateLoaded Column -->
    <ng-container matColumnDef="dateLoaded">
        <th scope="col" title="Date Loaded" mat-header-cell *matHeaderCellDef> Date Loaded <csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
        <td mat-cell *matCellDef="let element"> {{element.dateLoaded | dateAgo}} </td>
    </ng-container>

    <!-- quote Column -->
    <ng-container matColumnDef="quote">
        <th scope="col" title="Quote" mat-header-cell *matHeaderCellDef> Quote Text <csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
        <td mat-cell *matCellDef="let element"> {{element.quote}} </td>
    </ng-container>

    <!-- person Column -->
    <ng-container matColumnDef="person">
        <th scope="col" title="Person" mat-header-cell *matHeaderCellDef> Person <csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
        <td mat-cell *matCellDef="let element"> {{element.person}} </td>
    </ng-container>

    <!-- weight Column -->
    <ng-container matColumnDef="weight">
        <th scope="col" title="Weight" mat-header-cell *matHeaderCellDef>Weight<csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
        <td mat-cell *matCellDef="let element">
            <mat-form-field>
                <input type="text" size="3" matInput [(ngModel)]="element.weight">
           </mat-form-field>
        </td>
    </ng-container>

    <!-- weightType Column -->
    <ng-container matColumnDef="weightType">
        <th scope="col" title="Weight Type" mat-header-cell *matHeaderCellDef>Weight Type<csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
        <td mat-cell *matCellDef="let element">
            <mat-select [(value)]="element.weightType">
                <mat-option *ngFor="let weightType of weightTypes" [value]="weightType.id">{{weightType.description}}</mat-option>
            </mat-select>
        </td>
    </ng-container>

    <!-- Save Column -->
    <ng-container matColumnDef="save">
        <th scope="col" mat-header-cell *matHeaderCellDef> Save  </th>
        <td mat-cell *matCellDef="let element" >
            <button type="button"  class="btn btn-sm btn-info leftMargin" (click)="save(element.id)">Save</button>
        </td>
    </ng-container>

    <!-- like count Column -->
    <ng-container matColumnDef="likeCount">
        <th scope="col" title="Like Count" mat-header-cell *matHeaderCellDef>Like Count<csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
        <td mat-cell *matCellDef="let element"> {{element.likeCount}} </td>
    </ng-container>

    <!-- dislike count Column -->
    <ng-container matColumnDef="dislikeCount">
        <th scope="col" title="Dislike Count" mat-header-cell *matHeaderCellDef>Dislike Count<csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
        <td mat-cell *matCellDef="let element"> {{element.dislikeCount}} </td>
    </ng-container>

    <!-- love count Column -->
    <ng-container matColumnDef="loveCount">
        <th scope="col" title="Love Count" mat-header-cell *matHeaderCellDef>Love Count<csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
        <td mat-cell *matCellDef="let element"> {{element.loveCount}} </td>
    </ng-container>

    <!-- trust count Column -->
    <ng-container matColumnDef="trustCount">
        <th scope="col" title="Trust Count" mat-header-cell *matHeaderCellDef>Trust Count<csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
        <td mat-cell *matCellDef="let element"> {{element.trustCount}} </td>
    </ng-container>

    <!-- factcheck count Column -->
    <ng-container matColumnDef="factcheckCount">
        <th scope="col" title="Factcheck Count" mat-header-cell *matHeaderCellDef>Factcheck Count<csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
        <td mat-cell *matCellDef="let element"> {{element.factcheckCount}} </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20,50,100]" showFirstLastButtons></mat-paginator>
  </div>
  <div class="alert alert-danger" *ngIf="prepareDataError">
    Error encountered when updating site cache: {{prepareDataError}}
  </div>
  <div class="alert alert-success" *ngIf="prepareDataSuccess">
    Successfully updated site cache!
  </div>
import { Component, OnInit, EventEmitter, Output, Input, NgZone} from '@angular/core';
import { UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import {NewsArticle} from '@models/newsArticle';
import { ArticleWithExtension } from '@models/articleWithExtension';
import { AuthenticationService } from '@services/authentication.service';
import { Router } from '@angular/router';
import { NewsArticleService } from '@services/news-article.service';
import { ModalService } from '@modals/modal.service';
import { Globals } from 'app/globals';
import { SlugifyPipe } from '@pipes/slugify.pipe';
import { PreviewStoryComponent } from '../preview-story/preview-story.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'csl-edit-create-story',
  templateUrl: './edit-create-story.component.html',
  styleUrls: ['./edit-create-story.component.css']
})
export class EditCreateStoryComponent implements OnInit {
  editStoryForm;
  editOrCreate;
  editForm: boolean =false;
  imageWidth;
  imageHeight;
  disableSubmitForm: boolean = false;
  storyCreated: boolean=true;
  resultReturnedFromServer: boolean=false;
  storyCreationMessage: any;
  newStory: any;
  errorFlag: boolean = false;
  nineCommentariesList;
  siteUrl: string;
  publishArticleAfterUpdate: boolean = false;
  errorArticleTextRequired: boolean = false;

  articleSections: any;

  @Output() hideEditForm = new EventEmitter<boolean>();
  @Input() editNewsArticle: NewsArticle;
  showSpinner: boolean = false;
  weightTypes: Array<{id: string, description: string}> = [
    {id:this.globals.quoteLikeTypeLike, description:'Like'},
    {id:this.globals.quoteLikeTypeDislike, description:'Dislike'},
    {id:this.globals.quoteLikeTypeLove, description:'Love'},
    {id:this.globals.quoteLikeTypeTrust, description:'Trust'},
    {id:this.globals.quoteLikeTypeFactcheck, description:'Fact Check'}
  ];

  constructor(private formBuilder: UntypedFormBuilder,
    private authService:AuthenticationService,
    private newsArticleService: NewsArticleService, 
    private modalService: ModalService,
    public router: Router,
    private zone: NgZone,
    public globals: Globals,
    private slugifyPipe: SlugifyPipe,
    private ngbModal: NgbModal) {      
      this.siteUrl = globals.path;
    }

  ngOnInit(): void {
    this.articleSections = this.newsArticleService.getSections();
    this.getNineCommentariesList();
    if(typeof this.editNewsArticle!== "undefined"){
      this.editOrCreate ='Update';// set the editOrCreate based on the user action.
      this.editForm =true;
      this.editStoryForm= this.formBuilder.group({
        articleDomain: [this.editNewsArticle.articleDomain, [Validators.required]],
        id: this.editNewsArticle.id,
        articleIntro: this.editNewsArticle.articleIntro,
        articleBody: '',
        articleTitle: [this.editNewsArticle.articleTitle, Validators.required],
        articleUrl: [this.editNewsArticle.articleUrl, [Validators.required]],
        Paragraph1: [this.editNewsArticle.paragraph1, Validators.required],
        Paragraph2: [this.editNewsArticle.paragraph2, Validators.required],
        Paragraph3: [this.editNewsArticle.paragraph3, Validators.required],
        dateLoaded: [this.editNewsArticle.dateLoaded, Validators.required],
        articleSection: [this.lookupSectionIdBySectionCode(this.editNewsArticle.articleSection), Validators.required],
        featured: this.editNewsArticle.featured,
        CommentCount: '',
        LikesCount: '',
        ViewsCount: '',
        imageHeight: [this.editNewsArticle.imageHeight, Validators.required],
        imageWidth: [this.editNewsArticle.imageWidth, Validators.required],
        articleImage: [this.editNewsArticle.articleImage, Validators.required],
        relArticleId: this.editNewsArticle.relArticleId,
        quotes: this.formBuilder.array([]),
        summaries: this.formBuilder.array([]),
        articleText: ''
        });
        this.getArticleExtension();
        this.getArticleQuotes();
        this.getArticleSummaries();
        this.onSectionChange();
    }else {
      this.editNewsArticle =new NewsArticle();
      this.editOrCreate ='Create';// set the editOrCreate based on the user action.
      this.editStoryForm= this.formBuilder.group({
        articleDomain: ['', [Validators.required]],
        id: '',
        articleIntro: '',
        articleBody: '',
        articleTitle: ['', Validators.required],
        articleUrl: ['', [Validators.required]],
        Paragraph1: ['', Validators.required],
        Paragraph2: ['', Validators.required],
        Paragraph3: ['', Validators.required],
        dateLoaded: [new Date(), Validators.required],
        articleSection: ['', Validators.required],
        featured: '',
        CommentCount: '',
        LikesCount: '',
        ViewsCount: '',
        imageHeight: ['', Validators.required],
        imageWidth: ['', Validators.required],
        articleImage: [''],
        relArticleId: null,
        quotes: this.formBuilder.array([]),
        summaries: this.formBuilder.array([]),
        articleText: ''
        });
    }

  }  

  get quotes() {
    return this.editStoryForm.controls["quotes"] as UntypedFormArray;
  }

  addQuote() {
    const quoteForm = this.formBuilder.group({
      quote: ['', Validators.required],
      person: [''],
      weight: [0, Validators.required],
      weightType: [this.globals.quoteLikeTypeLike, Validators.required],
    });
    this.quotes.push(quoteForm);
  }

  deleteQuote(quoteIndex: number) {
    this.quotes.removeAt(quoteIndex);
  }

  getArticleQuotes() {
    this.showSpinner = true;
    this.newsArticleService.getArticleQuotes(this.editStoryForm.value.id, true).subscribe(articleQuotes => {
      articleQuotes.forEach(element => {
        this.quotes.push(this.formBuilder.group(
          {
            id: [element.id],
            articleId: [element.articleId],
            quote: [element.quote, Validators.required],
            person: [element.person],
            weight: [element.weight, Validators.required],
            weightType: [element.weightType, Validators.required]
          }
        ))
      })
    });
    this.showSpinner = false;
  }

  get summaries() {
    return this.editStoryForm.controls["summaries"] as UntypedFormArray;
  }

  addSummary() {
    const summaryForm = this.formBuilder.group({
      text: ['', Validators.required]
    });
    this.summaries.push(summaryForm);
  }

  deleteSummary(summaryIndex: number) {
    this.summaries.removeAt(summaryIndex);
  }

  getArticleSummaries() {
    this.showSpinner = true;
    this.newsArticleService.getArticleSummaries(this.editStoryForm.value.id, true).subscribe(articleSummaries => {
      articleSummaries.forEach(element => {
        this.summaries.push(this.formBuilder.group(
          {
            id: [element.id],
            articleId: [element.articleId],
            text: [element.text, Validators.required]
          }
        ))
      })
    });
    this.showSpinner = false;
  }

  getArticleExtension() {
    this.showSpinner = true;
    this.newsArticleService.getArticleExtension(this.editStoryForm.value.id, true).subscribe(articleExtension => {
      if (articleExtension) {
        this.editStoryForm.patchValue({ articleText: articleExtension.articleText});
      }
    });
    this.showSpinner = false;
  }

  getGeneratedArticleSummaries() {
    this.showSpinner = true;

    // Clear summaries
    this.summaries.clear();

    this.authService.postGetArticleSummaryByUrl(this.editStoryForm.value.articleUrl).subscribe(articleSummaries => {
      articleSummaries.forEach(element => {
        this.summaries.push(this.formBuilder.group(
          {
            text: [element.text, Validators.required]
          }
        ));
        this.showSpinner = false;
      })
    });
  }

  getGeneratedArticleQuotes() {
    this.showSpinner = true;

    // Clear summaries
    this.quotes.clear();

    this.authService.postGetArticleQuoteByUrl(this.editStoryForm.value.articleUrl).subscribe(articleQuotes => {
      articleQuotes.forEach(element => {
        this.quotes.push(this.formBuilder.group(
          {
            quote: [element.quote, Validators.required],
            person: [element.person],
            weight: [0, Validators.required],
            weightType: [this.globals.quoteLikeTypeLike, Validators.required]
          }
        ));
        this.showSpinner = false;
      })
    });
  }

  onUpdateAndPublish(articleData) {
    this.publishArticleAfterUpdate = true;
    this.onSubmit(articleData);
  }

  onSubmit(articleData) { // Process checkout data here
    this.storyCreationMessage = '';
    this.showSpinner = true;
    // need to show post the content to the server, if its successful, show success, else show a warning
      if(this.editStoryForm.status == 'VALID'){
        if(articleData.id===''){
          //if there is no article id, then we are creating a new story
          this.createStory();
        }else {
          this.updateStory(articleData);
        }
      } else {
        this.editStoryForm.markAllAsTouched();
        this.showSpinner = false;
      }
    }

  createStory(){

    type ServerArticle = {
      articleTitle: string;
      rssFeedId: string;
      featured: string;
      paragraph1: string;
      paragraph2: string;
      paragraph3: string;
      imageHeight: string;
      imageWidth: string;
      imageUrl: string;
      articleDomain: string;
      articleLocation: string;
      articleUrl: string;
      dateLoaded: string;
      showDetailsFlag: any;
      publishFlag: any;
      ProcessedFlag: any;
      articleIntro:any;
      relArticleId: any;
      publishedDate: any;
    };
    let article: ServerArticle={
      articleTitle: '',
      rssFeedId: '',
      featured: '',
      paragraph1: '',
      paragraph2: '',
      paragraph3: '',
      imageHeight: '',
      imageWidth: '',
      imageUrl: '',
      articleDomain: '',
      articleLocation: '',
      articleUrl: '',
      dateLoaded: '',
      showDetailsFlag: 1,
      publishFlag: 1,
      ProcessedFlag: 1,
      articleIntro: 'test',
      relArticleId: '',
      publishedDate: new Date()
    };
    this.updateStoryDetails(article);
  }

  hideForm() {
    this.hideEditForm.emit(true);
  }

  updateStory(articleData){
    //find the story to be updated
    this.authService.getStories(articleData.id).subscribe(newsArticle => {
        this.updateStoryDetails(newsArticle)
    }, _err => {
      // if there is an error, log the user out of the app.
      this.logOut();
    });
  }

  updateStoryDetails(newsArticle){
    let article:any =[];
    article = newsArticle;

    article.articleTitle = this.editStoryForm.value.articleTitle;
    article.rssFeedId = this.editStoryForm.value.articleSection;
    article.featured = this.editStoryForm.value.featured;
    article.paragraph1 = this.editStoryForm.value.Paragraph1;
    article.paragraph2 = this.editStoryForm.value.Paragraph2;
    article.paragraph3 = this.editStoryForm.value.Paragraph3;
    article.imageHeight =this.editStoryForm.value.imageHeight;
    article.imageWidth = this.editStoryForm.value.imageWidth;
    article.imageUrl = this.editStoryForm.value.articleImage;
    article.articleDomain = this.editStoryForm.value.articleDomain;
    article.articleLocation = this.editStoryForm.value.articleUrl;
    article.articleUrl = this.editStoryForm.value.articleUrl;
    article.dateLoaded = this.editStoryForm.value.dateLoaded ;
    article.relArticleId = this.editStoryForm.value.relArticleId ;
    // if the three paragraphs have content, then set the article as visible.
    if(this.editStoryForm.value.Paragraph1=== "undefined"|| this.editStoryForm.value.Paragraph1== ""
      || this.editStoryForm.value.Paragraph2=== "undefined"|| this.editStoryForm.value.Paragraph2== ""
      || this.editStoryForm.value.Paragraph3=== "undefined"|| this.editStoryForm.value.Paragraph3== ""){
        article.showDetailsFlag = 0;
    }else {
        article.showDetailsFlag = 1;
    }

    // To cater for publish after save
    if(this.publishArticleAfterUpdate){
      article.publishFlag = 1;
      article.publishedDate = new Date();
    }

    if(this.editOrCreate == 'Create') {
      // if this is posting of Editorial, set article url
      if (article.rssFeedId == 40) {
        let tempUrl = this.siteUrl + "/invalid/" + new Date().getTime();
        article.articleUrl = tempUrl;
        article.articleLocation = tempUrl;
      }
    }

    // construct articleWithExtension entity    
    let articleWithExtension = new ArticleWithExtension();
    articleWithExtension.article = article;
    articleWithExtension.articleQuotes = this.editStoryForm.value.quotes;
    articleWithExtension.articleSummaries = this.editStoryForm.value.summaries;
    articleWithExtension.articleText = this.editStoryForm.value.articleText;
    if(this.editOrCreate == 'Update') {
      this.doUpdate(articleWithExtension);
    } else {
      this.authService.postStory(articleWithExtension).subscribe(response => {
        this.newStory = response;
        this.showSpinner = false;
        this.resultReturnedFromServer =true;
        this.storyCreated = true;
        this.storyCreationMessage ="The story was successfully created.";
        this.errorFlag = false;
      }, err => {
        this.resultReturnedFromServer =true;
        this.storyCreated = false;
        this.errorFlag = true;
        if(err.error.status==401){
          this.storyCreationMessage ="Your session has expired, please log off and log back in again.";
          setTimeout(() => {  this.logOut(); }, 3000);
        }else if(err.error.status==409){
          this.storyCreationMessage ="Error encountered: Story already exist.";
        } else {
          this.storyCreationMessage ="There was an error creating the story.";
        }
        this.showSpinner = false;
      });
    }
  }
  
  doUpdate(article) {
    this.authService.putStory(article).subscribe(_response => {
      this.resultReturnedFromServer =true;
      this.storyCreated = false;
      this.storyCreationMessage ="The story was successfully updated.";
      this.showSpinner = false;
      this.errorFlag = false;
    }, _err => {
      this.showSpinner = false;
      this.errorFlag = true;
      // if there is an error, log the user out of the app.
      this.logOut();
    });
  }

  checkArticleExist() {
    this.authService.postGetArticlebyArticleUrl(this.editStoryForm.value.articleUrl).subscribe(response => {
      this.modalService.message(
        'Article already exists!',
        'Article URL Existence Check',
        'alert alert-danger',
        this.globals.path + '/story/' + response.articleId + "/" + this.slugifyPipe.transform(response.articleTitle),
        'Link to Story Page'
      );
    }, _err => {
      this.modalService.message(
        'Article not found.',
        'Article URL Existence Check',
        'alert alert-success'
      );
    });
  }

  getURLArticleData() {
    this.showSpinner = true;
    let newsArticle:NewsArticle;
    this.authService.postGetArticleContentByUrl(this.editStoryForm.value.articleUrl).subscribe(response => {
      newsArticle = response;
      this.editStoryForm.patchValue({ articleTitle: newsArticle.articleTitle, 
                                      articleDomain: newsArticle.articleDomain,
                                      Paragraph1:newsArticle.paragraph1,
                                      Paragraph2:newsArticle.paragraph2,
                                      Paragraph3:newsArticle.paragraph3,
                                      articleImage:newsArticle.articleImage});
      this.updateImageDimensions();
      this.showSpinner = false;
    });
  }

  updateSection(){
    this.showSpinner = true;
    let articleSectionUpdate : {[key: string]: any} = {};
    articleSectionUpdate.articleId = this.editStoryForm.value.id;
    articleSectionUpdate.rssFeedId = this.editStoryForm.value.articleSection;
    
    this.authService.putStorySection(articleSectionUpdate).subscribe(_response => {
      this.resultReturnedFromServer =true;
      this.storyCreated = false;
      this.storyCreationMessage ="The story was successfully updated.";
      this.showSpinner = false;
      this.errorFlag = false;
    }, _err => {
      this.showSpinner = false;
      this.errorFlag = true;
      // if there is an error, log the user out of the app.
      this.logOut();
    });
  }

  logOut(){
    localStorage.removeItem('userToken');
    this.router.navigate(['login']);
  }

  updateImageDimensions(){
    let img = new Image();
    img.src = this.editStoryForm.value.articleImage;

    img.onload = () => {
      this.editStoryForm.patchValue({imageHeight: img.height, imageWidth: img.width});
      if ((typeof img.height !== 'undefined') &&(typeof img.width !== 'undefined') ) {
        if ((img.height > 0) &&(img.width > 0) ){
          this.disableSubmitForm = false;
        }
      }
    };
  }

  storyImageChanged(){
    // if you change the image you must reload the dimensions.
    this.disableSubmitForm = true;
  }

  // this method is necessary to show the default options for the section drop down
  compareFn(option1,option2){
    return option1.name===option2.name;
  }

  lookupSectionIdBySectionCode(sectionCode: string) {
    for(let section  of this.articleSections){
      if( section.sectionCode === sectionCode){
        return section.id;
      }
    }
  }

  getNineCommentariesList() {
    this.authService.getNineCommentariesList().subscribe(nineCommentariesList => {
      this.nineCommentariesList = nineCommentariesList;
    });
  }

  goViewNineCommentaries(articleId: string): void {
    const ref = this.ngbModal.open(PreviewStoryComponent, { centered: true, size: 'lg' });
    ref.componentInstance.articleId = articleId;
  }

  onSectionChange() {
    console.log("Section Changed = " + this.editStoryForm.value.articleSection);

    // Handle when section is Editorial (40)
    if (this.editStoryForm.value.articleSection === 40) {
      this.editStoryForm.controls['Paragraph2'].removeValidators([Validators.required]);
      this.editStoryForm.controls['Paragraph3'].removeValidators([Validators.required]);
      this.editStoryForm.controls['articleUrl'].removeValidators([Validators.required]);
      this.editStoryForm.controls['articleDomain'].removeValidators([Validators.required]);
      this.editStoryForm.controls['articleText'].addValidators([Validators.required]);
    } else {
      this.editStoryForm.controls['Paragraph2'].addValidators([Validators.required]);
      this.editStoryForm.controls['Paragraph3'].addValidators([Validators.required]);
      this.editStoryForm.controls['articleUrl'].addValidators([Validators.required]);
      this.editStoryForm.controls['articleDomain'].addValidators([Validators.required]);
      this.editStoryForm.controls['articleText'].removeValidators([Validators.required]);
    }
    this.editStoryForm.controls['Paragraph2'].updateValueAndValidity();
    this.editStoryForm.controls['Paragraph3'].updateValueAndValidity();
    this.editStoryForm.controls['articleUrl'].updateValueAndValidity();
    this.editStoryForm.controls['articleDomain'].updateValueAndValidity();
    this.editStoryForm.controls['articleImage'].updateValueAndValidity();
    this.editStoryForm.controls['imageHeight'].updateValueAndValidity();
    this.editStoryForm.controls['imageWidth'].updateValueAndValidity();
    this.editStoryForm.controls['articleText'].updateValueAndValidity();
  }

}

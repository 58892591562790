import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import {NewsArticle} from '@models/newsArticle';
import { ModalService } from '@modals/modal.service';
import { Globals } from 'app/globals';
import { take } from 'rxjs/operators';
import {FbAdSet} from '@models/fbAdSet';
import {FbAd} from '@models/fbAd';

@Component({
  selector: 'csl-edit-story-facebook',
  templateUrl: './edit-story-facebook.component.html',
  styleUrls: ['./edit-story-facebook.component.css']
})
export class EditStoryFacebookComponent implements OnInit {
  selectedNewsArticle: NewsArticle;
  editForm: UntypedFormGroup;
  isLoading = false;
  isEditMode = true;
  availableFbAdSets: FbAdSet[];
  fbAds: FbAd[];
  editAdForm: UntypedFormGroup;
  fbAdErrorFlag = false;
  fbAdErrorMessage = '';
  fbPostErrorFlag = false;
  fbPostErrorMessage = '';

  constructor(public modal: NgbActiveModal,
    private authService:AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalService,
    public globals: Globals) { }

  ngOnInit(): void {
    this.setForm();
  }

  onSubmit() {    
    this.fbPostErrorFlag = false;
    if (this.editForm.invalid || this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.authService.postFacebook(this.editForm.value).subscribe(x => {
      this.selectedNewsArticle.fbPostId = x.fbPostId;
      this.isEditMode = true;
      this.setForm();
      this.isLoading = false;
      },
      error => {
        this.fbPostErrorFlag = true;
        this.fbPostErrorMessage = 'Error title: ' + error.error_user_title + '; Error message: ' + error.error_user_msg;
        this.isLoading = false;
      }
    );
  }
 
  get editFormData() { return this.editForm.controls; }

  get editFormAdData() { return this.editAdForm.controls; }
 
  private setForm() {
    if (this.isEditMode) {
      this.editForm = this.formBuilder.group({
        fbPostId: [this.selectedNewsArticle.fbPostId],
        articleId: [this.selectedNewsArticle.id]
      });

      this.setAdForm();
    } else {
      this.editForm = this.formBuilder.group({
        fbPostDescription: [this.selectedNewsArticle.paragraph1, Validators.required],
        storyUrl: [this.globals.path + '/story/' + this.selectedNewsArticle.id + '/' + this.selectedNewsArticle.articleTitleSlug],
        articleId: [this.selectedNewsArticle.id]
      });
    }
  }

  onDelete() {
    this.fbPostErrorFlag = false;
    this.modalService.confirm(
      'This only deletes the Facebook Post and its Advertisements from our database, you will still need to delete the post and advertisements in Facebook manually. Do you want to proceed?', 'Delete Facebook Post'
    ).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        if(result) {
          this.isLoading = true;
          this.authService.deleteFacebook(this.editForm.value).subscribe(_x => {
            this.isLoading = false;
            this.selectedNewsArticle.fbPostId = null;
            this.isEditMode = false;
            this.setForm();
            },
            _error => {
              this.fbPostErrorFlag = true;
              this.fbPostErrorMessage = '';
              this.isLoading = false;
            }
          );
        }
      });
  }

  private setAdForm() {
    this.isLoading = true;
    this.editAdForm = this.formBuilder.group({
      fbPostId: [this.selectedNewsArticle.fbPostId],
      articleTitle: [this.selectedNewsArticle.articleTitle],
      selectedFbAdSets: new UntypedFormArray([], this.minSelectedCheckboxes(1)),
      fbAdSet: []
    });
    this.availableFbAdSets = this.authService.getFbAdsets();
    this.addfbAdSetsCheckboxes();
    this.getFbAds();
    this.isLoading = false;
  }

  private getAdSetNameById(id){
    let adSetFound = this.availableFbAdSets.find(x => x.id === id);
    if (adSetFound) {return adSetFound.name}
    return null;
  }

  private getFbAds() {
    this.authService.getAdsByFbPostId(this.selectedNewsArticle.fbPostId)
    .subscribe(
      fbAds => {
        fbAds.map(item => {
          item.fbAdSetName = this.getAdSetNameById(item.fbAdsetId);
        })
        this.fbAds = fbAds;
      }, _error => {
        this.isLoading = false;
    });
  }

  minSelectedCheckboxes(min = 1) {
    return (formArray: UntypedFormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);
  
      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };
  }

  get fbAdSetsFormArray() {
    return this.editAdForm.controls.selectedFbAdSets as UntypedFormArray;
  }

  private addfbAdSetsCheckboxes() {
    this.availableFbAdSets.forEach(() => this.fbAdSetsFormArray.push(new UntypedFormControl(false)));
  }

  onSubmitAd() {
    this.fbAdErrorFlag = false;
    const extractedFbAdSets = this.editAdForm.value.selectedFbAdSets
      .map((checked, i) => checked ? new FbAdSet(this.availableFbAdSets[i].id) : null)
      .filter(v => v !== null);
      this.editAdForm.patchValue({fbAdSet: extractedFbAdSets});
      this.isLoading = true;
      this.authService.postFbAd(this.editAdForm.value).subscribe(_x => {
        this.getFbAds();
        this.editAdForm.reset();
        this.setAdForm();
        this.isLoading = false;
        },
        error => {
          this.handleFbAdError(error.error);
        }
      );
  }

  onDeleteFbAd(id) {
    this.fbAdErrorFlag = false;
    this.modalService.confirm(
      'This only deletes the Facebook Ad from our database, you will still need to delete the Ad in Facebook manually. Do you want to proceed?', 'Delete Facebook Ad'
    ).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        if(result) {
          this.isLoading = true;
          this.authService.deleteFbAd(id).subscribe(_x => {
            this.getFbAds();
            this.isLoading = false;
            },
            error => {
              this.handleFbAdError(error.error);
            }
          );
        }
      });
  }

  handleFbAdError(error) {
    console.log("[handleFbAdError] error = " + JSON.stringify(error));
    this.getFbAds();
    this.fbAdErrorFlag = true;
    this.fbAdErrorMessage = 'Error title: ' + error.error_user_title + '; Error message: ' + error.error_user_msg;
    this.isLoading = false;
  }
}

import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { NewsArticleService } from '@services/news-article.service';
import {NewsArticle} from '@models/newsArticle';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'csl-this-is-rising',
  templateUrl: './this-is-rising.component.html'
})
export class ThisIsRisingComponent implements OnInit {
  newsArticles: NewsArticle[];

  constructor(private newsArticleService: NewsArticleService,
    @Inject(PLATFORM_ID) private platform: Object) {
    this.newsArticles = new Array();
    let noOfArticles = 1;
    while(noOfArticles < 7){
      this.newsArticles.push( {
        articleDomain: 'Loading...',
        id: noOfArticles,
        articleIntro: 'Loading...',
        articleBody: 'Loading...',
        articleTitle: 'Loading...',
        articleUrl: 'Loading...',
        paragraph1: 'Loading...',
        paragraph2: 'Loading...',
        paragraph3: 'Loading...',
        dateLoaded: new Date('2010-01-09T22:53:50'),
        articleImage: 'https://www.tedxbloomington.com/wp-content/uploads/globe-pic.gif',
        articleSection: 'Loading...',
        featured: false,
        commentCount: 0,
        likesCount: 0,
        viewsCount: 0,
        imageHeight: 0,
        imageWidth: 0,
        featuredStorySlot: noOfArticles
      });
      noOfArticles = noOfArticles + 1;
    }
   }

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      this.newsArticleService.getRisingArticles()
      .subscribe(
        newsArticles => {
          this.newsArticles = newsArticles;
          this.newsArticles.forEach( (newsArticle) => {
            newsArticle.articleSection = this.newsArticleService.getMapSectionDescription(newsArticle.articleSection);
          });
        }
      );
    }
  }

}

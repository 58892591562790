<div id="main-wrapper" class="homepage-two fixed-nav">
    <csl-top-slim-banner></csl-top-slim-banner>
    <div id="navigation">
      <csl-top-logo-banner></csl-top-logo-banner>
      <csl-top-navigation-menu></csl-top-navigation-menu>
    </div><!--/#navigation-->

    <div class="container">
        <div class="section">
            <div class="row">
                <div class="col">
                    <div id="site-content" class="site-content">
                        <div class="section listing-news" infinite-scroll (scrolled)="onScrollDown()">
                            <ng-container *ngFor="let topQuote of topQuotes; index as i; first as isFirst">
                                <div class="post">
                                    <div class="entry-header">
                                        <div class="entry-thumbnail">
                                            <a *ngIf="topQuote"  [routerLink]="['/story', topQuote?.articleId, topQuote?.articleTitleSlug]" [queryParams]="{goal: globals.routeGoalTopQuotes}"  routerLinkActive="active">
                                                <img class="img-fluid" src="{{topQuote.articleImage}}" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="post-content">
                                        <div class="entry-meta">
                                            <ul class="list-inline">
                                                <li class="publish-date"><a href="#"><em class="fa fa-clock-o"></em> {{topQuote?.articleDateLoaded | dateAgo}} </a></li>
                                                <li class="views"><a href="#"><em class="fa fa-eye"></em>{{topQuote?.articleViewsCount}}</a></li>
                                                <li class="loves"><a href="#"><em class="fa fa-heart-o"></em>{{topQuote?.articleLikesCount}}</a></li>
                                                <li class="comments"><em class="fa fa-comment-o"></em><a href="#">{{topQuote?.articleCommentCount}}</a></li>
                                            </ul>
                                        </div>
                                        <h2 class="entry-title">
                                            <a *ngIf="topQuote"  [routerLink]="['/story', topQuote?.articleId, topQuote?.articleTitleSlug]" [queryParams]="{goal: globals.routeGoalTopQuotes}"  routerLinkActive="active">
                                                {{topQuote?.articleTitle | truncatetext:100}}
                                            </a>
                                        </h2>
                                        <div class="entry-content">
                                            <div class="form-group">
                                                <div class="inner-container">
                                                    <div class="inner-row">
                                                    <div class="inner-container">
                                                        <blockquote class="quote-card">
                                                            <p>{{topQuote.quote}}</p>
                                                            <cite>{{topQuote.quotePerson}}</cite>
                                                            <div class="loves">
                                                                <button type="button" [disabled]="topQuote.isDisabled" (click)="postQuoteLike(topQuote, globals.quoteLikeTypeLike)" class="btn btn-light btn-xs" data-toggle="tooltip" title="Like"><fa-icon [icon]="faLike" matSuffix></fa-icon> {{topQuote.quoteLikeCount}}</button>
                                                                <button type="button" [disabled]="topQuote.isDisabled" (click)="postQuoteLike(topQuote, globals.quoteLikeTypeDislike)" class="btn btn-light btn-xs leftMargin" data-toggle="tooltip" title="Dislike"><fa-icon [icon]="faDislike" matSuffix></fa-icon> {{topQuote.quoteDislikeCount}}</button>
                                                                <button type="button" [disabled]="topQuote.isDisabled" (click)="postQuoteLike(topQuote, globals.quoteLikeTypeLove)" class="btn btn-light btn-xs leftMargin" data-toggle="tooltip" title="Love"><fa-icon [icon]="faLove" matSuffix></fa-icon> {{topQuote.quoteLoveCount}}</button>
                                                                <button type="button" [disabled]="topQuote.isDisabled" (click)="postQuoteLike(topQuote, globals.quoteLikeTypeTrust)" class="btn btn-light btn-xs leftMargin" data-toggle="tooltip" title="Trust"><fa-icon [icon]="faTrust" matSuffix></fa-icon> {{topQuote.quoteTrustCount}}</button>
                                                                <button type="button" [disabled]="topQuote.isDisabled" (click)="postQuoteLike(topQuote, globals.quoteLikeTypeFactcheck)" class="btn btn-light btn-xs leftMargin" data-toggle="tooltip" title="Fact Check"><fa-icon [icon]="faFactcheck" matSuffix></fa-icon> {{topQuote.quoteFactcheckCount}}</button>
                                                            </div>
                                                        </blockquote>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div><!--/post--> 
                            </ng-container>
                        </div><!--/.section lifestyle-news-->
                    </div><!--/#site-content-->
                </div><!--/.col-->
            </div><!--/.row-->
        </div><!--/.section-->
    </div><!--/.container-->

    <csl-footer></csl-footer>

</div><!--/#main-wrapper-->  
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@services/authentication.service';
import {FbAdStatus, FbAdStatusPaging} from '@models/fbAdStatus';

@Component({
  selector: 'csl-facebook-ad-status',
  templateUrl: './facebook-ad-status.component.html',
  styleUrls: ['./facebook-ad-status.component.css']
})
export class FacebookAdStatusComponent implements OnInit {
  fbAdStatuses: FbAdStatus[];
  fbAdStatusPaging: FbAdStatusPaging;
  fbAdErrorFlag = false;
  isLoading = false;

  constructor(private authService: AuthenticationService, public modal: NgbActiveModal) { }

  ngOnInit(): void {
    this.onGetMore();
  }

  onGetMore(paginate: string = '') {
    this.isLoading = true;
    this.authService.getFbAdStatus(paginate).subscribe(x => {
      this.fbAdStatuses = x.data;
      if (this.fbAdStatuses) {
        this.fbAdStatuses.forEach( (fbAdStatus) => {
          fbAdStatus.adset_name = this.authService.getFbAdSetName(fbAdStatus.adset_id);
        });
      }
      this.fbAdStatusPaging = x.paging
      this.isLoading = false;
    },
      _error => {
        this.fbAdErrorFlag = true;
        this.isLoading = false;
      }
    );
  }

}

import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'csl-subscribe-newsletter',
  templateUrl: './subscribe-newsletter.component.html'
})
export class SubscribeNewsletterComponent {

  constructor(public activeModal: NgbActiveModal) { 
    // Intentionally left blank
  }
}

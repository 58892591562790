<csl-admin-header></csl-admin-header>
<csl-edit-create-story *ngIf="!showStoryTable" (hideEditForm)="showStoriesGrid($event)" [editNewsArticle] ="articleToEdit"></csl-edit-create-story>
<body style="padding-top:20px">
  <div class="col-md-10 col-md-offset-1" *ngIf="showStoryTable">

      <div class="well controlBox">
          <select id="selectedSearchType" class="filterSelection inputboxfilter"  (change)="changeSearchType($event)" [ngModel]="selectedSearchType">
            <option *ngFor="let searchType of searchTypes | keyvalue" [selected]="" [value]="searchType.value">{{searchType.value}}</option>
          </select>
          <ng-container *ngIf="selectedSearchType === searchTypes.SearchArticleId">
            <input id="btnLoadArticles" class="btn btn-success leftMargin" (click)="this.loadStories(articleIdFilter.value)"
                    type="button" value="Load Stories" />
            <input type="number" onkeyup="this.value=this.value.replace(/[^\d]/,'')" #articleIdFilter [ngModel]="filterArticleId" class="inputboxfilter"  style="margin-left: 20px" placeholder="Enter Article Id">
            <label for="selectedSectionId" class="inputboxfilter">Section:</label>
            <select id="selectedSectionId" class="filterSelection inputboxfilter"  (change)="filterSection($event)" [ngModel]="selectedSectionId">
              <option *ngFor="let section of articleSections" [selected]="" [value]="section.id">{{section.name}}</option>
            </select>
            <label for="backDays" class="inputboxfilter">Back days:</label>
            <select id="selectedDaysToReturn" class="filterSelection inputboxfilter" (change)="filterBackDays($event)" [ngModel]="selectedDaysToReturn">
              <option *ngFor="let myDays of numDaysStoriesToReturn" [selected]="">{{myDays}}</option>
            </select>
            <label for="hasDetails" class="inputboxfilter">Has details:</label>
            <select id="selectedHasDetails" class="filterSelection inputboxfilter" (change)="filterHasDetails($event.target.value)" [ngModel]="selectedHasDetails">
              <option *ngFor="let myDetails of hasDetailsSelection" [selected]="">{{myDetails}}</option>
            </select>
          </ng-container>
          <ng-container *ngIf="selectedSearchType === searchTypes.SearchText">
            <input id="btnSearchArticles" class="btn btn-success leftMargin" [disabled]="!searchText" (click)="this.searchStories(searchText, selectedSearchSectionId, fromDate, toDate)"
                    type="button" value="Search Stories" />
            <input type="text" [(ngModel)]="searchText" class="inputboxfilter"  style="margin-left: 20px" placeholder="Enter Search String">
            <select id="selectedSearchSectionId" class="filterSelection inputboxfilter" [(ngModel)]="selectedSearchSectionId">
              <option *ngFor="let section of articleSections" [selected]="" [value]="section.id">{{section.name}}</option>
            </select>
            <label for="fromDate" class="inputboxfilter">From:</label>
            <input class="inputboxfilter" placeholder="yyyy-mm-dd" name="fromDate" [(ngModel)]="fromDate" ngbDatepicker #fd="ngbDatepicker">
            <button class="btn btn-outline-secondary calendar" (click)="fd.toggle()" type="button"><i class="fa fa-calendar" aria-hidden="true"></i></button>
            <label for="backDays" class="inputboxfilter">To:</label>
            <input class="inputboxfilter" placeholder="yyyy-mm-dd" name="toDate" [(ngModel)]="toDate" ngbDatepicker #td="ngbDatepicker">
            <button class="btn btn-outline-secondary calendar" (click)="td.toggle()" type="button"><i class="fa fa-calendar" aria-hidden="true"></i></button>
          </ng-container>
          <div class="pull-right">
            <input id="prepareData" class="btn btn-info leftMargin" type="button" value="Update Site Cache" (click)="runPrepareSiteCache()"/>       
            <input id="facebookAdStatus" class="btn btn-info leftMargin" type="button" value="FB Ad Status" (click)="goToFacebookAdStatus()"/>
            <input id="createStory" class="btn btn-success leftMargin" type="button" value="Create Story" (click)="goToEditPage()"/>
          </div>
      </div>

      <div class="mat-elevation-z8" matSort (matSortChange)="sortData($event)">
        <table id="tblData" mat-table [dataSource]="dataSource">
        <caption>List of articles in repository.</caption>
        <!-- ARTICLE_ID Column -->
        <ng-container matColumnDef="id">
          <th scope="col" mat-sort-header title="click to sort by ID" mat-header-cell *matHeaderCellDef>ID<csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- ARTICLE_TITLE Column -->
        <ng-container matColumnDef="articleTitle">
          <th scope="col" mat-sort-header title="click to sort by Title" mat-header-cell *matHeaderCellDef> Article Name <csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
          <td mat-cell *matCellDef="let element"> <a target="_blank" [routerLink]="['/story', element.id]" rel="noopener">{{element.articleTitle}}</a></td>
        </ng-container>

        <!-- Article_Domain Column -->
        <ng-container matColumnDef="articleDomain">
          <th scope="col" mat-sort-header title="click to sort by Article Domain" mat-header-cell *matHeaderCellDef> Article Domain <csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
          <td mat-cell *matCellDef="let element"> <a target="_blank" href="{{element.articleUrl}}" rel="noopener">{{element.articleDomain}} </a></td>
        </ng-container>

        <!-- DATE_LOADED Column -->
        <ng-container matColumnDef="dateLoaded">
          <th scope="col" mat-sort-header title="click to sort by Date Loaded" mat-header-cell *matHeaderCellDef> Date Loaded <csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
          <td mat-cell *matCellDef="let element"> {{convertDate(element.dateLoaded)}} </td>
        </ng-container>

        <!-- publishedDate Column -->
        <ng-container matColumnDef="publishedDate">
          <th scope="col" mat-sort-header title="click to sort by Published Date" mat-header-cell *matHeaderCellDef> Published Date <csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
          <td mat-cell *matCellDef="let element"> {{convertDate(element.publishedDate)}} </td>
        </ng-container>

        <!-- RSS_Feed_ID Column -->
        <ng-container matColumnDef="articleSection">
          <th scope="col" mat-sort-header title="click to sort by RSS Feed" mat-header-cell *matHeaderCellDef> RSS Feed <csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
          <td mat-cell *matCellDef="let element"> {{element.articleSection}} </td>
        </ng-container>

        <!-- publishedFlag Column -->
        <ng-container matColumnDef="publishedFlag">
          <th scope="col" mat-sort-header title="click to sort by Published Flag" mat-header-cell *matHeaderCellDef> Published <csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
          <td mat-cell *matCellDef="let element"> {{element.publishedFlag}} </td>
        </ng-container>

        <!-- ShowDetails_Flag Column -->
        <ng-container matColumnDef="showDetailsFlag">
          <th scope="col" mat-sort-header title="click to sort by Show Details Flag" mat-header-cell *matHeaderCellDef>Has all details<csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
          <td mat-cell *matCellDef="let element"> {{element.showDetailsFlag}} </td>
        </ng-container>

        <!-- Facebook post Column -->
        <ng-container matColumnDef="fbPostId">
          <th scope="col" mat-sort-header title="Facebook Post ID" mat-header-cell *matHeaderCellDef>Facebook Post ID<csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
          <td mat-cell *matCellDef="let element"> <a target="_blank" href="https://www.facebook.com/chinaspotlights/posts/{{element.fbPostId}}">{{element.fbPostId}}</a></td>
        </ng-container>

        <ng-container matColumnDef="PinnedFront">
          <th scope="col" mat-sort-header mat-header-cell *matHeaderCellDef> Front5Slots <csl-spinner  [show]="showSpinner" [size]="150"> </csl-spinner></th>
          <td mat-cell *matCellDef="let element" >
            <form [formGroup]="formPinned" class="formElement">
              <div class="form-group">
                  <select formControlName="formPinnedControl" class="form-control" (change)="changeFrontPinnedSlots($event, element.id)">
                      <option disabled default>{{element.featuredStorySlot}}</option>
                      <option *ngFor="let slot of frontPageSlots" [selected]="element.featuredStorySlot==slot">{{slot}}</option>
                  </select>
              </div>
            </form>
          </td>
        </ng-container>


        <!-- Publish/Remove Column -->
        <ng-container matColumnDef="PubRem">
          <th scope="col" mat-header-cell *matHeaderCellDef>(Un)publish</th>
          <td mat-cell *matCellDef="let element" >
            <button *ngIf="element.publishedFlag; else elseBlock" type="button" (click)="togglePublish(element.id)" class="btn btn-danger">Unpublish</button>
            <ng-template #elseBlock>
              <button type="button"  (click)="togglePublish(element.id)" class="btn btn-success">Publish</button>
            </ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="Republish">
          <th scope="col" mat-header-cell *matHeaderCellDef> Republish </th>
          <td mat-cell *matCellDef="let element" >
            <button type="button"  class="btn btn-info leftMargin" (click)="rePublish(element.id)">Republish</button>
          </td>
        </ng-container>

        <ng-container matColumnDef="Cache">
          <th scope="col" mat-header-cell *matHeaderCellDef> Cache </th>
          <td mat-cell *matCellDef="let element" >
            <button type="button"  class="btn btn-info leftMargin" (click)="cacheSSR(element)" [disabled]="!element.publishedFlag">Cache</button>
          </td>
        </ng-container>

        <!-- Edit Column -->
        <ng-container matColumnDef="Edit">
          <th scope="col" mat-header-cell *matHeaderCellDef> Edit </th>
          <td mat-cell *matCellDef="let element" >
            <button type="button" class="btn btn-info leftMargin"  (click)="goToEditPage(element)">Edit</button>
          </td>
        </ng-container>

        <ng-container matColumnDef="FB">
          <th scope="col" mat-header-cell *matHeaderCellDef> FB </th>
          <td mat-cell *matCellDef="let element" >
            <button type="button" class="btn btn-info leftMargin"  (click)="goToFacebook(element)">FB</button>
          </td>
        </ng-container>

        <ng-container matColumnDef="Twitter">
          <th scope="col" mat-header-cell *matHeaderCellDef> Twitter </th>
          <td mat-cell *matCellDef="let element" >
            <button type="button" class="btn btn-info leftMargin"  (click)="goToTwitter(element)">Twitter</button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20,50,100]" showFirstLastButtons></mat-paginator>
      </div>

      <div class="modal fade" tabindex="-1" id="errorModal"
            data-bs-keyboard="false" data-bs-backdrop="static">
          <div class="modal-dialog modal-sm">
              <div class="modal-content">
                  <div class="modal-header">
                      <button type="button" class="close" data-bs-dismiss="modal">
                          &times;
                      </button>
                      <h4 class="modal-title">Session Expired</h4>
                  </div>
                  <div class="modal-body">
                      <form>
                          <h2 class="modal-title">Close this message to login again</h2>
                      </form>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-danger"
                              data-bs-dismiss="modal">
                          Close
                      </button>
                  </div>
              </div>
          </div>
      </div>
      <div id="divError" class="alert alert-danger collapse">
          <a id="linkClose" href="#" class="close">&times;</a>
          <div id="divErrorText"></div>
      </div>
  </div>
  <div class="alert alert-danger" *ngIf="errorFindingArticle">
    Sorry that Article Id does not exist.
  </div>
  <div class="alert alert-danger" *ngIf="errorMessage">
    Error encountered: {{errorMessage}}
  </div>
  <div class="alert alert-success" *ngIf="successMessage">
    {{successMessage}}
  </div>
</body>

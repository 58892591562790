import { Component, OnInit } from '@angular/core';
import { CommentService } from '@services/comment.service';
import { Globals } from 'app/globals';

@Component({
  selector: 'csl-manage-comments',
  templateUrl: './manage-comments.component.html',
  styleUrls: ['./manage-comments.component.css']
})
export class ManageCommentsComponent implements OnInit {
  comments: any[];
  showSpinner: boolean;
  siteUrl: string;
  filterId: '';

  constructor(private commentService: CommentService,
              public globals: Globals) {
    this.showSpinner = false;
    this.siteUrl = globals.path;
  }

  ngOnInit(): void {
    this.loadComments();
  }

  loadComments(){
    this.showSpinner = true;
    console.log('filterId = ' + this.filterId);
    if (this.filterId === '' || this.filterId === undefined || this.filterId === null){
      this.commentService.getComments(true)
        .subscribe(
        comments => {
          this.comments = comments;
          this.showSpinner = false;
        }
      );
    } else {
      this.commentService.getCommentForArticle(this.filterId, true)
        .subscribe(
        comments => {
          this.comments = comments;
          this.showSpinner = false;
        }
      );
    }
  }

  deleteComment(commentId:any){
    this.showSpinner = true;
    this.commentService.deleteComment(commentId)
      .subscribe(
      _response => {
        this.loadComments();
      }
    );
  }

}

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {NewsArticle} from '@models/newsArticle';
import { ModalService } from '@modals/modal.service';
import { Globals } from 'app/globals';
import { take } from 'rxjs/operators';
import { NewsArticleService } from '@services/news-article.service';

@Component({
  selector: 'csl-edit-story-twitter',
  templateUrl: './edit-story-twitter.component.html',
  styleUrls: ['./edit-story-twitter.component.css']
})
export class EditStoryTwitterComponent implements OnInit {
  selectedNewsArticle: NewsArticle;
  editForm: UntypedFormGroup;
  isLoading = false;
  isEditMode = true;
  editAdForm: UntypedFormGroup;
  twitterPostErrorFlag = false;
  twitterPostErrorMessage = '';
  characterCount = 0;
  characterLeft = 0;

  constructor(public modal: NgbActiveModal,
    private authService:AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalService,
    private newsArticleService: NewsArticleService,
    public globals: Globals) { }

  ngOnInit(): void {
    this.setForm();
  }

  onSubmit() {    
    this.twitterPostErrorFlag = false;
    if (this.editForm.invalid || this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.authService.postTwitter(this.editForm.value).subscribe(x => {
      this.selectedNewsArticle.twitterPostId = x.twitterPostId;
      this.isEditMode = true;
      this.setForm();
      this.isLoading = false;
      },
      error => {
        this.twitterPostErrorFlag = true;
        this.twitterPostErrorMessage = 'Error encountered: ' + error.error;
        this.isLoading = false;
      }
    );
  }
 
  get editFormData() { return this.editForm.controls; }
 
  private setForm() {
    if (this.isEditMode) {
      this.editForm = this.formBuilder.group({
        twitterPostId: [this.selectedNewsArticle.twitterPostId],
        articleId: [this.selectedNewsArticle.id]
      });

    } else {
      let articleUrl = this.globals.path + '/story/' + this.selectedNewsArticle.id + '/' + this.selectedNewsArticle.articleTitleSlug;
      let articleSectionShort = this.newsArticleService.getMapSectionDescription(this.selectedNewsArticle.articleSection).replace(/\s/g, "");
      let twitterText = this.selectedNewsArticle.paragraph1 +
                        '\r\n\r\n' +
                        '#China #' + articleSectionShort;
      this.editForm = this.formBuilder.group({
        twitterPostDescription: [twitterText, [Validators.required, Validators.maxLength(256)]],
        articleId: [this.selectedNewsArticle.id],
        articleUrl: [articleUrl]
      });
      this.characterCounter();
    }
  }

  onDelete() {
    this.twitterPostErrorFlag = false;
    this.modalService.confirm(
      'This will permanently delete the tweet in Twitter. Do you want to proceed?', 'Delete Twitter Post'
    ).pipe(
      take(1) // take() manages unsubscription for us
    ).subscribe(result => {
        if(result) {
          this.isLoading = true;
          this.authService.deleteTwitter(this.editForm.value).subscribe(_x => {
            this.isLoading = false;
            this.selectedNewsArticle.twitterPostId = null;
            this.isEditMode = false;
            this.setForm();
            },
            _error => {
              this.twitterPostErrorFlag = true;
              this.twitterPostErrorMessage = 'Error title: ' + _error.error_user_title + '; Error message: ' + _error.error_user_msg;
              this.isLoading = false;
            }
          );
        }
      });
  }

  characterCounter() {
    this.characterCount = this.editForm.controls.twitterPostDescription.value.length + 24;
  }
}

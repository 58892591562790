import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export const faTrust: IconDefinition = {
    prefix: 'fac',
    iconName: 'trust',
    icon: [
        512,
        512,
        [],
        null,
        'M299.072,102.613c19.008-17.856,48.725-22.229,71.979-10.56l71.915,35.968H512V85.355h-58.965l-62.891-31.467 c-38.912-19.477-88.597-12.245-120.341,17.664l-13.184,12.416l-14.933-13.611c-19.605-17.856-44.992-27.691-72.32-27.691 c-16.448,0-32.853,3.883-47.509,11.2L58.944,85.333H0V128h69.035l71.936-35.968c21.803-10.965,52.437-8,71.979,9.856l12.523,11.413 l-56.448,53.163c-22.891,22.869-26.539,59.029-8.491,84.011c11.093,15.424,29.845,26.859,52.139,26.859 c16.917,0,33.152-6.656,44.885-18.411l21.461-20.544l135.317,101.483L256,458.261L71.104,320H0v42.667h56.896L256,511.552 l199.104-148.885H512V320h-53.056L310.251,208.491l36.011-34.475l-29.504-30.827l-89.024,85.227 c-4.437,4.437-10.475,6.72-16.811,6.187c-6.357-0.512-11.947-3.733-15.765-9.067c-5.845-8.107-4.053-20.8,3.584-28.459 l100.331-94.485V102.613z'
    ],
} as any
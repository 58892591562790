declare const $: any;

import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsArticleService } from '@services/news-article.service';
import { Video } from '@models/video';
import { NewsArticle } from '@models/newsArticle';
import { Advertisement } from '@models/advertisement';
import { isPlatformBrowser } from '@angular/common';
import { CordovaService } from '@services/cordova.service';
import { SectionArticle } from '@models/sectionArticle';

// Adverts run from JavaScript file
declare const sidePageAdvert300x600: any;
declare const bannerAdTest: any;

@Component ({
  selector: 'csl-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  frontPageTopStories: NewsArticle[];
  newsArticles: NewsArticle[];
  advertisement: Advertisement;
  advertisement1: string;
  australiaArticles: NewsArticle[];
  canadaArticles: NewsArticle[];
  hongKongArticles: NewsArticle[];
  irelandArticles: NewsArticle[];
  newZealandArticles: NewsArticle[];
  singaporeArticles: NewsArticle[];
  ukArticles: NewsArticle[];
  usaArticles: NewsArticle[];
  europeArticles: NewsArticle[];
  indiaArticles: NewsArticle[];
  germanyArticles: NewsArticle[];
  taiwanArticles: NewsArticle[];
  sectionHighlights: NewsArticle[];
  videos: Video[];
  sectionArticles: SectionArticle[];
  sectionVerticalArticles: SectionArticle[];
  regionArticles: SectionArticle[];
  subscribeAlert = false;
  articleSection: string;
  sectionHistoryArticles: NewsArticle[];
  sectionHistoryPage: number;
  bolScrollDown: boolean;

  constructor(private route: ActivatedRoute,
              private newsArticleService: NewsArticleService,
              @Inject(PLATFORM_ID) private platform: Object,
              private cordovaService: CordovaService) {
    
    // Clear variables
    delete this.articleSection;
    delete this.sectionHistoryArticles;
    this.sectionHistoryPage = 1;

    if (isPlatformBrowser(this.platform)) {

      this.route.paramMap.subscribe( params => {
        if (params.get('section')!="Editorial") {
          this.getTopStories(params);
        }
        if (!params.has('section')) {
          this.getSectionHighlights();
        } else {
          this.articleSection = params.get('section');
          delete this.sectionHistoryArticles;
          if (params.get('section')!="Editorial") {
            this.sectionHistoryPage = 1;
          } else {
            this.sectionHistoryPage = 0;
          }
          this.onScrollDown();
        }
      });
    }
    this.advertisement1 = '"370465602", "300x600", "370465602"';
  }

  ngOnInit() {
    if (this.cordovaService.onCordova) {
      this.cordovaService.setScreenName('HomePage');
    }
    this.initSticky();
  }

  getTopStories(parameters?: any): void {
    this.newsArticles = new Array();
    this.frontPageTopStories = new Array();
    let noOfArticles = 1;
    while(noOfArticles < 6){
      this.newsArticles.push( {
        articleDomain: 'Loading...',
        id: noOfArticles,
        articleIntro: 'Loading...',
        articleBody: 'Loading...',
        articleTitle: 'Loading...',
        articleUrl: 'Loading...',
        paragraph1: 'Loading...',
        paragraph2: 'Loading...',
        paragraph3: 'Loading...',
        dateLoaded: new Date(),
        articleImage: 'https://www.tedxbloomington.com/wp-content/uploads/globe-pic.gif',
        articleSection: 'Loading...',
        featured: false,
        commentCount: 0,
        likesCount: 0,
        viewsCount: 0,
        imageHeight: 0,
        imageWidth: 0,
        featuredStorySlot: noOfArticles
      });
      noOfArticles = noOfArticles + 1;
    }

    // If a section is passed in then pass it to the web service. <-- removed checking because service will be able to handle empty section
    this.newsArticleService.getSectionArticles(parameters.get('section'))
    .subscribe(
      newsArticles => {
        this.newsArticles = newsArticles;
        this.frontPageTopStories[0] = newsArticles[0];
        this.frontPageTopStories[1] = newsArticles[1];
        this.frontPageTopStories[2] = newsArticles[2];
        this.frontPageTopStories[3] = newsArticles[3];
        this.frontPageTopStories[4] = newsArticles[4];
      }
    );
  }
  ensureOnlyOneArticleFromEachSection() { //temporary solution to ensuring the front page is not loaded with content from one section.
      // Loop through the top ten stories array and ensure there are only 1 story for each section
      for (let outerArticle of this.newsArticles) {
        let articleSection = outerArticle.articleSection;
        let articleID = outerArticle.id;
        for (let j = 0; j < this.newsArticles.length; j++){
            if((this.newsArticles[j].id!==articleID) &&(this.newsArticles[j].articleSection ==articleSection)&&(this.newsArticles.length>5)){
              this.newsArticles.splice(j, 1);
            }

        }
   }
  }

  processSectionArticle(articleCollection, newsSectionArticle, articleSection, articleSectionName) {
    if (newsSectionArticle) {
      let count = 0;
      newsSectionArticle.forEach( (newsArticle) => {
        count += newsArticle.viewsCount;
      });
      articleCollection.push(new SectionArticle(articleSection, articleSectionName, count, newsSectionArticle));
    }
  }

  getSectionHighlights() {
    this.newsArticleService.getSectionHighlights()
    .subscribe(
      getSectionHighlightResponse => {
        this.sectionHighlights = getSectionHighlightResponse.sectionHighlights;

        // Regions
        this.regionArticles = [];
        let worldSectionArticles = getSectionHighlightResponse.worldSectionArticles;
        if (worldSectionArticles) {
          worldSectionArticles.forEach( (worldSectionArticle) => {
            this.processSectionArticle(this.regionArticles, worldSectionArticle.articleList, worldSectionArticle.articleSection, worldSectionArticle.articleSectionName);
          });
        }
        
        this.videos = getSectionHighlightResponse.videos;

        // For main sections, sorting is needed
        this.sectionArticles = [];

        let respSectionArticles = getSectionHighlightResponse.sectionArticles;
        
        if (respSectionArticles) {
          respSectionArticles.forEach( (sectionArticle) => {
            this.processSectionArticle(this.sectionArticles, sectionArticle.articleList, sectionArticle.articleSection, sectionArticle.articleSectionName);
          });
        }

        // Sort
        this.sectionArticles.sort(function (a, b) {
          return b.totalViews - a.totalViews;
        });
        
        this.sectionArticles = this.sectionArticles.filter(function( element ) {
          return element !== undefined;
        });

        // Split vertical and horizontal stories to 1:4 ratio
        let verticalCount = Math.floor(this.sectionArticles.length/4);
        this.sectionVerticalArticles = this.sectionArticles.splice(this.sectionArticles.length - 1 - verticalCount, verticalCount);

        // Sort videos by view count
        this.videos.sort(function (a, b) {
          return b.viewsCount - a.viewsCount;
        });
      }
    );
  }

  initSticky() {
    if (isPlatformBrowser(this.platform)) {
      $(document).ready(function () {
        $('.tr-sticky')
            .theiaStickySidebar({
                additionalMarginTop: 0
            });
      });
    }
  }

  onScrollDown () {
    this.sectionHistoryPage += 1;
    this.newsArticleService.getArticleHistory(this.articleSection, this.sectionHistoryPage)
    .subscribe(
      newsArticles => {
        if (this.sectionHistoryArticles) {
          this.sectionHistoryArticles.push(...newsArticles);
        } else {
          this.sectionHistoryArticles = newsArticles;
        }
      },
      error => {
        // Do not scroll when there is an error
        console.log("No more articles in section");
      }
    );
  }
}

// globals.ts
import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  path = 'https://chinaspotlight.net'; // live

  // Constants for routes - used for Google Anatlytics
  readonly routeGoalNineCommentary = 'nineCommentary';
  readonly routeGoalTopStories = 'topStories';
  readonly routeGoalSectionStories = 'sectionStories';
  readonly routeGoalTopQuotes = "topQuotes";
  readonly routeGoalStoryToQuotes = "storyToQuotes";
  readonly routeGoalStoryToEditorial = "storyToEditorial";
  
  // Constants for quote like types
  readonly quoteLikeTypeLike = 'LIKE';
  readonly quoteLikeTypeDislike = 'DISLIKE';
  readonly quoteLikeTypeLove = 'LOVE';
  readonly quoteLikeTypeTrust = 'TRUST';
  readonly quoteLikeTypeFactcheck = 'FACTCHECK';

  // readonly CSLWebServiceOpen = 'https://openservice.chinaspotlight.net/'; // live (non-core)
  // readonly CSLWebServiceOpen = 'https://authservice-staging.chinaspotlight.net/'; //'https://openservice-staging.chinaspotlight.net/'; // test
  readonly CSLWebServiceOpen = 'https://csl-stories20190601091951-coreservice.azurewebsites.net/'; // Live
  // readonly CSLWebServiceOpen = 'https://localhost:44325/'; // local
  // CSLWebServiceOpen = 'https://csl-stories20190601091951-coreservicestaging.azurewebsites.net/'; // Core Staging

  // readonly CSLWebServiceAuth = 'https://authservice.chinaspotlight.net/'; // live (non-core)
  // readonly CSLWebServiceAuth = 'https://csl-stories20190601091951-authenticatedservicestaging.azurewebsites.net/'; // staging
  readonly CSLWebServiceAuth = 'https://csl-stories20190601091951-authenticatedcslservicecore.azurewebsites.net/'; // Live
  // readonly CSLWebServiceAuth = 'https://localhost:5001'; // local

  readonly articleSecionEditorial = "Editorial";

  readonly articleSections = [
    {
        "id": 2,
        "name": "Hong Kong",
        "sectionCode": "HK"
    },
    {
        "id": 3,
        "name": "Technology",
        "sectionCode": "China Technology"
    },
    {
        "id": 4,
        "name": "Environment *",
        "sectionCode": "China Environment"
    },
    {
        "id": 5,
        "name": "Falun Gong *",
        "sectionCode": "Falun Gong"
    },
    {
        "id": 6,
        "name": "Human Rights",
        "sectionCode": "China Human Rights"
    },
    {
        "id": 7,
        "name": "Arts *",
        "sectionCode": "China Arts"
    },
    {
        "id": 8,
        "name": "Politics",
        "sectionCode": "China Politics"
    },
    {
        "id": 9,
        "name": "Science",
        "sectionCode": "China Science"
    },
    {
        "id": 10,
        "name": "Health",
        "sectionCode": "China Health"
    },
    {
        "id": 11,
        "name": "Style *",
        "sectionCode": "China Style"
    },
    {
        "id": 12,
        "name": "Military",
        "sectionCode": "China Military"
    },
    {
        "id": 13,
        "name": "Covid19 *",
        "sectionCode": "Covid19"
    },
    {
        "id": 14,
        "name": "Belt And Road *",
        "sectionCode": "Belt And Road"
    },
    {
        "id": 15,
        "name": "Xinjiang Uyghur *",
        "sectionCode": "Xinjiang Uyghur"
    },
    {
        "id": 19,
        "name": "Singapore *",
        "sectionCode": "SG"
    },
    {
        "id": 20,
        "name": "United Kingdom *",
        "sectionCode": "GB"
    },
    {
        "id": 21,
        "name": "Australia *",
        "sectionCode": "AU"
    },
    {
        "id": 22,
        "name": "Canada *",
        "sectionCode": "CA"
    },
    {
        "id": 24,
        "name": "USA",
        "sectionCode": "US"
    },
    {
        "id": 26,
        "name": "India *",
        "sectionCode": "IN"
    },
    {
        "id": 27,
        "name": "Taiwan",
        "sectionCode": "TW"
    },
    {
        "id": 28,
        "name": "Europe *",
        "sectionCode": "EU"
    },
    {
        "id": 29,
        "name": "Germany *",
        "sectionCode": "DE"
    },
    {
        "id": 30,
        "name": "Culture",
        "sectionCode": "China Culture"
    },
    {
        "id": 31,
        "name": "Travel",
        "sectionCode": "China Travel"
    },
    {
        "id": 32,
        "name": "Food",
        "sectionCode": "China Food"
    },
    {
        "id": 33,
        "name": "Society",
        "sectionCode": "China Society"
    },
    {
        "id": 34,
        "name": "History",
        "sectionCode": "China History"
    },
    {
        "id": 35,
        "name": "Beijing *",
        "sectionCode": "China Beijing"
    },
    {
        "id": 36,
        "name": "Shanghai *",
        "sectionCode": "China Shanghai"
    },
    {
        "id": 37,
        "name": "Shenzhen *",
        "sectionCode": "China Shenzhen"
    },
    {
        "id": 38,
        "name": "Business",
        "sectionCode": "China Business"
    },
    {
        "id": 39,
        "name": "Front *",
        "sectionCode": "Front"
    },
    {
        "id": 40,
        "name": "Editorial *",
        "sectionCode": "Editorial"
    }
  ];

  readonly articleSectionSelector = [{ "id": 0, "name": "Filter by Section","sectionCode":""}];
  readonly articleSectionsWithEmpty = this.articleSectionSelector.concat(this.articleSections);

}

import { Component, OnInit, Injector, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CordovaService } from '@services/cordova.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

declare let $:any;

@Component({
  selector: 'csl-about-us-page',
  templateUrl: './about-us-page.component.html',
  styleUrls: ['./about-us-page.component.css']
})
export class AboutUsPageComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    autoplay: true,
    nav: false,
    responsive: {
      0: {
        items: 1
      }
    }
  };

  constructor(private injector: Injector, @Inject(PLATFORM_ID) private platformId: Object,
    private cordovaService: CordovaService) { }

  ngOnInit() {
    if (this.cordovaService.onCordova) {
      this.cordovaService.setScreenName('AboutUsPage');
    }
    if (isPlatformBrowser(this.platformId)) {
      $('.carousel').carousel({
        interval: 5000
      });
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { VideoService } from '@services/video.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Video } from '@models/video';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication.service';
import { NewsArticleService } from '@services/news-article.service';

@Component({
  selector: 'csl-edit-video',
  templateUrl: './edit-video.component.html',
  styleUrls: ['./edit-video.component.css']
})
export class EditVideoComponent implements OnInit {
  selectedVideo: Video;
  editForm: UntypedFormGroup;
  isLoading = false;
  isEditMode = true;
  videoSections: any;
  videoProviders: {name: string , providerCode: string }[] = [
    {"name": "Choose Provider","providerCode":""},
    {"name": "YouTube","providerCode":"youtube"}
  ];

  constructor(private authService:AuthenticationService, public modal: NgbActiveModal,
    private route: ActivatedRoute,
    private videoService: VideoService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private newsArticleService: NewsArticleService) { }

  ngOnInit(): void {
    this.videoSections = this.newsArticleService.getSections();
    this.setForm()
  }

  onSubmit() {
    if (this.editForm.invalid || this.isLoading) {
      return;
    }
    this.isLoading = true;
    if (this.isEditMode) {
      this.videoService.putVideo(this.editForm.value).subscribe(_x => {
        this.isLoading = false;
        this.modal.close('Yes');
        },
        _error => {
          this.isLoading = false;
        }
      );
    } else {
      this.editForm.patchValue({date: new Date()});
      this.videoService.postVideo(this.editForm.value).subscribe(_x => {
        this.isLoading = false;
        this.modal.close('Yes');
        },
        _error => {
          this.isLoading = false;
        }
      );
    }

  }
 
  get editFormData() { return this.editForm.controls; }
 
  private setForm() {
    console.log("selectedVideo = " + this.selectedVideo);
    
    if (this.isEditMode) {
      this.editForm = this.formBuilder.group({
        id: [this.selectedVideo.id],
        linkId: [this.selectedVideo.linkId, Validators.required],
        provider: [this.selectedVideo.provider, Validators.required],
        title: [this.selectedVideo.title, Validators.required],
        section: [this.selectedVideo.section, Validators.required],
        date: [this.selectedVideo.date]
      });
    } else {
      this.editForm = this.formBuilder.group({
        linkId: [this.selectedVideo.linkId, Validators.required],
        provider: [this.selectedVideo.provider, Validators.required],
        title: [this.selectedVideo.title, Validators.required],
        section: [this.selectedVideo.section, Validators.required],
        date: [this.selectedVideo.date]
      });
    }
  }
}

<body style="padding-top:20px">
  <div class="col-md-10 col-md-offset-1" class="formStyle">

    <form [formGroup]="editStoryForm" (ngSubmit)="onSubmit(editStoryForm.value)">
      <div class="row">
        <div class="col-md">
          <mat-form-field appearance="fill">
            <mat-label>Article ID</mat-label>
            <input matInput formControlName="id" readonly>
          </mat-form-field>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>Article Title</mat-label>
            <input matInput formControlName="articleTitle">
          </mat-form-field>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>Article Domain</mat-label>
            <input matInput formControlName="articleDomain" [readonly]="editForm">
          </mat-form-field>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>Paragraph One</mat-label>
            <textarea matInput
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="5"
                      formControlName="Paragraph1"></textarea>
          </mat-form-field>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>Paragraph Two</mat-label>
            <textarea matInput
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="5"
                      formControlName="Paragraph2"></textarea>
          </mat-form-field>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>Paragraph Three</mat-label>
            <textarea matInput
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="5"
                      formControlName="Paragraph3"></textarea>
          </mat-form-field>
          <br>
            <mat-label>Article Content (Rich Text)</mat-label>
            <quill-editor formControlName="articleText"></quill-editor>
            <mat-error *ngIf="editStoryForm.controls['articleText'].errors?.['required']">Article Content is required</mat-error>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>Date Loaded</mat-label>
            <!-- #docregion toggle -->
            <input matInput [matDatepicker]="picker" formControlName="dateLoaded" [readonly]="editForm">
            <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <!-- #enddocregion toggle -->
          </mat-form-field>
          <br>
          <div *ngIf="!editForm" class="wrapper">
            <div class="fields-wrapper" >
              <mat-form-field appearance="fill" class="form-field-with-button">
                <mat-label>Article Url</mat-label>
                <input matInput formControlName="articleUrl" [readonly]="editForm">
              </mat-form-field>
            </div>
            <div class="fields-wrapper" >
              <input id="btnGetPageContent" (click)="this.getURLArticleData()" type="button" value="Retrieve Content" />
              <input class="leftMargin" id="btnCheckArticleUrl" (click)="this.checkArticleExist()" type="button" value="Check Exist" />
            </div>
          </div>
          <mat-form-field *ngIf="editForm" appearance="fill">
            <mat-label>Article Url</mat-label>
            <input matInput formControlName="articleUrl" [readonly]="editForm">
          </mat-form-field>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>Image Url</mat-label>
            <input matInput formControlName="articleImage" (change)="this.storyImageChanged()">
          </mat-form-field>
          <br>
          <br>
          <div class="wrapper">
            <div class="fields-wrapper" style="width:80%">
              <mat-form-field appearance="fill">
                <mat-label>Image Height</mat-label >
                <input matInput formControlName="imageHeight" readonly>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Article Width</mat-label>
                <input matInput formControlName="imageWidth" readonly>
              </mat-form-field>
            </div>
            <input id="btnLoadImageDimensions" (click)="this.updateImageDimensions()"
                        type="button" value="Load Sizes" />
          </div>
          <br>
          <div class="wrapper">
            <div class="fields-wrapper" style="width:80%">
              <ng-select formControlName="relArticleId"
                  [items]="nineCommentariesList" 
                  bindLabel="articleTitle" 
                  bindValue="articleId"
                  placeholder="Related Article (Nine Commentaries)"
                  groupBy="chapter">
              </ng-select>
            </div>
            <input [disabled]="!editStoryForm.value.relArticleId" id="btnViewNineCommentaries" (click)="this.goViewNineCommentaries(editStoryForm.value.relArticleId)" type="button" value="View Article" />
          </div>
          <br>
          <div class="wrapper">
            <div class="fields-wrapper" style="width:80%">
              <mat-form-field appearance="fill">
                <mat-label>Article Section </mat-label>
                <mat-select formControlName="articleSection" (selectionChange)="this.onSectionChange()">
                          <mat-option *ngFor="let section of articleSections" [value]="section.id">{{section.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <input id="btnSaveSection" (click)="this.updateSection()" type="button" value="Save Section" />
          </div>
          <br>
          <mat-divider></mat-divider>
          <div class="wrapper">
            <h3>Quotes:</h3>
            <csl-spinner  [show]="showSpinner" [size]="150"></csl-spinner><input id="btnGenerateQuotes" class="form-field-top-margin-summary" (click)="this.getGeneratedArticleQuotes()" type="button" value="Generate Quotes" />
          </div>
          <div class="wrapper" [formGroup]="editStoryForm">
              <ng-container formArrayName="quotes">
                  <ng-container *ngFor="let quoteForm of quotes.controls; let i = index">
                      <div class="fields-wrapper" [formGroup]="quoteForm" style="width:90%">
                        <mat-form-field appearance="fill" class="form-field-left-margin-quote">
                          <mat-label>Quote text</mat-label>
                          <textarea matInput
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="1"
                                    cdkAutosizeMaxRows="5"
                                    formControlName="quote"></textarea>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="form-field-left-margin-person">
                          <mat-label>Quoted person's name</mat-label>
                          <input matInput
                                formControlName="person">
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="form-field-left-margin-weight">
                          <mat-label>Likes</mat-label>
                          <input matInput
                                formControlName="weight">
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="form-field-left-margin-weight-type">
                          <mat-label>Likes Type</mat-label>
                          <mat-select formControlName="weightType" >
                            <mat-option *ngFor="let weightType of weightTypes" [value]="weightType.id">{{weightType.description}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <mat-icon class="delete-btn button-red" (click)="deleteQuote(i)" type="button">
                          delete_forever</mat-icon>
                  </ng-container>
              </ng-container>
            
              <button mat-mini-fab (click)="addQuote()" type="button">
                  <mat-icon class="add-quote-btn button-green">add</mat-icon>
              </button>
          </div>
          <mat-divider></mat-divider>
          <div class="wrapper">
              <h3>Summaries:</h3>
              <csl-spinner  [show]="showSpinner" [size]="150"></csl-spinner><input id="btnGenerateSummaries" class="form-field-top-margin-summary" (click)="this.getGeneratedArticleSummaries()" type="button" value="Generate Summary" />
          </div>
          <div class="wrapper" [formGroup]="editStoryForm">
              <ng-container formArrayName="summaries">
                  <ng-container *ngFor="let summaryForm of summaries.controls; let i = index">
                      <div class="fields-wrapper" [formGroup]="summaryForm" style="width:90%">
                        <mat-form-field appearance="fill" class="form-field-left-margin-summary">
                          <mat-label>Summary text</mat-label>
                          <textarea matInput
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="1"
                                    cdkAutosizeMaxRows="5"
                                    formControlName="text"></textarea>
                        </mat-form-field>
                      </div>
                      <mat-icon class="delete-btn button-red" (click)="deleteSummary(i)" type="button">
                          delete_forever</mat-icon>
                  </ng-container>
              </ng-container>
            
              <button mat-mini-fab (click)="addSummary()" type="button">
                  <mat-icon class="add-quote-btn button-green">add</mat-icon>
              </button>
          </div>
          <mat-divider></mat-divider>
          <br>
        </div>
      </div>
      <button class="btn btn-success" type="submit" [disabled]="disableSubmitForm">{{editOrCreate}} Story<csl-spinner  [show]="showSpinner" [size]="150"></csl-spinner> </button>
      <div  class="divider"></div>
      <button *ngIf="editOrCreate == 'Update' && editNewsArticle.publishedFlag != 1" class="btn btn-success" type="button" (click)="onUpdateAndPublish(editStoryForm.value)" [disabled]="disableSubmitForm">Update & Publish Story<csl-spinner  [show]="showSpinner" [size]="150"></csl-spinner> </button>
      <div  class="divider"></div>
      <button class="btn btn-success" type="submit" (click)="hideForm()">Back</button>
    </form>
    <div  *ngIf="resultReturnedFromServer"  [ngStyle]="{'background-color': errorFlag? 'red' : 'MediumSeaGreen'}" >{{storyCreationMessage}}</div>
    <div *ngIf="resultReturnedFromServer&&storyCreated" >Click <a target="_blank" [routerLink]="['/story', newStory.articleId]">{{newStory.articleId}}</a> to check the story details.</div>
  </div>
</body>

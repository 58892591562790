import { Injectable, NgZone, Injector, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { Router } from '@angular/router';

declare let cordova;
declare let navigator: Navigator;

declare let FirebasePlugin;
declare let universalLinks;
declare let nativegeocoder;

function _window(): any {
  // return the global native browser window object
  return window;
}

interface Navigator {
  app: any;
  geolocation: any;
}

@Injectable({
  providedIn: 'root'
})
export class CordovaService {
  private launchedFromNotification = false;
  private resume: BehaviorSubject<boolean>;
  private requested = false;
  constructor(private zone: NgZone, private router: Router, private injector: Injector, @Inject(PLATFORM_ID) private platformId: Object) {
    if (!isPlatformServer(this.platformId)) {
        console.log('CordovaService: Init');
        this.resume = new BehaviorSubject<boolean>(null);
        fromEvent(document, 'resume').subscribe(_event => {
          this.zone.run(() => {
              console.log('CordovaService: Resume event fired!')
              this.onResume();
          });
        });
      }
    }
  get cordova(): any {
      return _window().cordova;
  }
  get onCordova(): boolean {
    if (!isPlatformServer(this.platformId)) {
      return !!_window().cordova;
    } else {
      return false;
    }
  }
  public onResume(): void {
      this.resume.next(true);
  }

  public openLinkInBrowser(url: string) {
    cordova.InAppBrowser.open(url,'_blank', 'usewkwebview=yes,toolbarposition=top');
  }

  public openLinkInBrowserSystem(url: string) {
    cordova.InAppBrowser.open(url,'_system');
  }

  public shareInMobileApp(url: string) {
    _window().plugins.socialsharing.share(null, null, null, url);
  }

  public initFirebase() {
    // Clear browser history for Android so that backbutton can exit app
    if(cordova.platformId === 'android'){
      console.log('CordovaService: Clear browsing history');
      navigator.app.clearHistory();
    }

    navigator.geolocation.getCurrentPosition((position: any) => {
      nativegeocoder.reverseGeocode((result: any) => {
        console.log('launchedFromNotification = ' + this.launchedFromNotification);
        if (!this.launchedFromNotification && result.length > 0 && result[0].countryCode) {
          console.log('Country Code = ' + result[0].countryCode);
          if (result[0].countryCode === 'US' ||
          result[0].countryCode === 'CA' ||
          result[0].countryCode === 'GB' ||
          result[0].countryCode === 'IE' ||
          result[0].countryCode === 'AU' ||
          result[0].countryCode === 'NZ' ||
          result[0].countryCode === 'HK' ||
          result[0].countryCode === 'SG') {
            console.log('Routing to section = ' + '/' + 'section', result[0].countryCode);
            this.zone.run(() => { this.router.navigate(['/' + 'section', result[0].countryCode]); });
          }
        }
      },
      (error: any) => {
        console.log('Received position: ' + error);
      }
      , position.coords.latitude, position.coords.longitude);
    },
    (error: any) => {
      console.log('Received position: ' + error);
    });

    console.log('CordovaService: subscribing universal links');
    universalLinks.subscribe('csl', (eventData: any) => {
      console.log('CordovaService: universalLink called with eventData: ' + JSON.stringify(eventData));
      this.router.navigateByUrl(eventData.path);
    });

    console.log('CordovaService: Register onMessageReceived');
    FirebasePlugin.onMessageReceived(
      (data: any) => {
        console.log('CordovaService: [onMessageReceived] Tap = ' + data.tap + '; Received data = ' + JSON.stringify(data));
        if (data.tap) {
          this.launchedFromNotification = true;
          console.log('CordovaService: Tapped, routing to:' + '/' + data.landing_page + '/' + data.articleId);
          this.zone.run(() => { this.router.navigate(['/' + data.landing_page, data.articleId]); });
        } else {
          console.log('CordovaService: Not tapped by user, no action taken.');
        }
    });

    console.log('CordovaService: Registering onTokenRefresh');
    FirebasePlugin.onTokenRefresh(
      (token: any) => {
        console.log('CordovaService [onTokenRefresh]: Token = ' + token);
    });

    console.log('CordovaService: Checking permission');
    FirebasePlugin.hasPermission(
      (hasPermission: any) => {
        if(hasPermission){
          console.log('CordovaService: Notifications permission granted, getting Token');
          // Granted
          this.getToken();
        }else{
          // Request permission
          console.log('CordovaService: Requesting remote notifications permission');
          FirebasePlugin.grantPermission(
            (grantedPermission: any) => {
              if(grantedPermission){
                console.log('CordovaService: Notifications permission granted, getting Token');
                // Granted
                this.getToken();
              }else{
                // Denied
                console.log('CordovaService: Notifications would not be shown as permission is denied');
              }
            }
          );
        }
    });

    if(cordova.platformId === 'ios'){
      console.log('CordovaService: Registering onApnsTokenReceived');
      FirebasePlugin.onApnsTokenReceived(
        (token: any) => {
          console.log('CordovaService: APNS token received: ' + token)
      });
    }
  }

  public setScreenName(screenName: string) {
    console.log('CordovaService: [setScreenName] Setting screenName = ' + screenName);
    FirebasePlugin.setScreenName(screenName);
  }

  public logEvent(eventName : string, eventProperties: object) {
    console.log('CordovaService: [logEvent] Logging event = ' + eventName + '; Params: ' + JSON.stringify(eventProperties));
    FirebasePlugin.logEvent(eventName, eventProperties);
  }

  public getToken() {
    FirebasePlugin.getToken(
      (pushRegistrationId: any) => {
          console.log('CordovaService: Push registration ID: ' + pushRegistrationId);
          console.log('CordovaService: Subscribe to ChinaNews topic');
          FirebasePlugin.subscribe('ChinaNews');
      },
      (err: any) => {
          console.log('CordovaService: error retrieving push registration id: ' + err);
      }
    );
  }
}

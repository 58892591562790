<csl-admin-header></csl-admin-header>

<body style="padding-top:20px">
    <div class="col-md-10 col-md-offset-1">
        <div class="well controlBox">
            <input id="btnLoadArticles" class="btn btn-success" (click)="this.loadComments()"
                    type="button" value="Load Comments" />
            <input type="number" onkeyup="this.value=this.value.replace(/[^\d]/,'')" #articleIdFilter [ngModel]="filterId" (ngModelChange)="filterId=$event" class="inputboxfilter" placeholder="Enter Article Id">
        </div>
        <div id="divData" class="well hidden">
            <table  class="table table-bordered" id="tblData" aria-describedby="Manage Comments">
                <thead>
                    <tr  class="success">
                      <th scope="col">Comment Id</th>
                      <th scope="col">Date</th>
                      <th scope="col">Article Id</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Comment</th>
                      <th scope="col">Actions</th>
                    </tr>
                    <csl-spinner  [size]="150"> </csl-spinner>
                    <tr *ngIf="showSpinner">
                        <td><csl-spinner [show]="showSpinner" [size]="150"> </csl-spinner></td>
                        <td><csl-spinner [show]="showSpinner" [size]="150"> </csl-spinner></td>
                        <td><csl-spinner [show]="showSpinner" [size]="150"> </csl-spinner></td>
                        <td><csl-spinner [show]="showSpinner" [size]="150"> </csl-spinner></td>
                    </tr>
                
                </thead>
                    <tr *ngFor="let comment of comments">
                      <td>{{comment.id}}</td>
                      <td>{{comment.date | date:'medium'}}</td>
                      <td><a href="{{siteUrl}}/story/{{comment.articleId}}" target="_blank" rel="noopener">{{comment.articleId}}</a></td>
                      <td>{{comment.name}}</td>
                      <td>{{comment.email}}</td>
                      <td>{{comment.commentText}}</td>
                      <td>
                          <div class="btn-group">
                          <button type="button" (click)="deleteComment(comment.id)" class="btn btn-outline-primary">Delete</button></div>
                      </td>
                    </tr>
                <tbody id="tblBody"></tbody>
            </table>
        </div>
        <div class="modal fade" tabindex="-1" id="errorModal"
              data-bs-keyboard="false" data-bs-backdrop="static">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-bs-dismiss="modal">
                            &times;
                        </button>
                        <h4 class="modal-title">Session Expired</h4>
                    </div>
                    <div class="modal-body">
                        <form>
                            <h2 class="modal-title">Close this message to login again</h2>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger"
                                data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div id="divError" class="alert alert-danger collapse">
            <a id="linkClose" href="#" class="close">&times;</a>
            <div id="divErrorText"></div>
        </div>
    </div>
  </body>
  
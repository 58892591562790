import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

// Maximum age
const maxAge = 300000;

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cache = new Map();

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(request.method !== "GET") {
      return next.handle(request)
    }
    if(request.headers.get("Reset-Cache") == 'true') {
      this.deleteCache(request.urlWithParams);
    }
    // Don't read from cache if no-cache header is set.
    if(request.headers.get("No-Cache") == 'true' ) {
      this.deleteCache(request.urlWithParams);
      return next.handle(request);
    }
    
    let cachedResponse = undefined;
    const entry = this.cache.get(request.urlWithParams);
    if (entry) {
      const expired = Date.now() - maxAge;
      if (entry.cachedDate < expired) {
        this.cache.delete(request.urlWithParams);
      } else {
        cachedResponse = entry.response;
      }
    }

    if(cachedResponse) {
      return of(cachedResponse.clone())
    }else {
      return next.handle(request).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            const new_entry = {response: event, cachedDate: Date.now() };
            this.cache.set(request.urlWithParams, new_entry);
          }
        })
      );
    }
  }

  private deleteCache (key: string) {
    if (this.cache.get(key)) {
      this.cache.delete(key);
    }
  }
}

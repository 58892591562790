import { Component, Input } from '@angular/core';
import {NewsArticle} from '@models/newsArticle';

@Component({
  selector: 'csl-story-page-section',
  templateUrl: './story-page-section.component.html'
})
export class StoryPageSectionComponent {
  @Input() sectionTitle: string;
  @Input() routeGoal: string;
  @Input() newsArticles: NewsArticle[];

}

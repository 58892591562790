import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { CommentService } from '@services/comment.service';
import { Comment } from '@models/comment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'csl-comment-widget',
  templateUrl: './comment-widget.component.html'
})
export class CommentWidgetComponent implements OnInit {
  model: Comment;
  error = false;
  commentForm: UntypedFormGroup;
  submitted = false;
  comments: Comment[];
  isCollapsed = true;

  regExforURL = '((?:https?://|[a-z0-9.\-]+[.][a-z]{2,4})(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)))';

  articleId: string;
  commentCount: number;

  constructor(private commentService: CommentService,
              private fb: UntypedFormBuilder,
              @Inject(PLATFORM_ID) private platform: Object,
              route:ActivatedRoute) {
                route.paramMap.subscribe(params => {
                  this.articleId = params.get('story');
                  if (isPlatformBrowser(this.platform)) {
                    this.getComments();
                  }
                  this.commentForm = this.fb.group({
                    name: ['', [Validators.required] ],
                    email: ['', [Validators.required, Validators.email] ],
                    commentText: ['', [Validators.required, Validators.pattern('\^((?!' + this.regExforURL + ').)*$')] ],
                    articleId: [''],
                    date: ['']
                  });
                  this.model = null;
                  this.submitted = false;
                  this.error = false;
                  this.onChanges();
                });
              }

  ngOnInit() {
    // Intentionally left empty
  }

  onSubmit() {
    this.commentForm.patchValue({articleId: this.articleId, date: new Date()});
    return this.commentService.postComment(this.commentForm.value).subscribe(
      (data: Comment) => {
        this.model = data;
        this.commentForm.reset();
        this.submitted = true;
        this.getComments(true);
      },
      error => this.error = error
    );
  }

  onChanges(): void {
    this.commentForm.valueChanges.subscribe(_val => {
      this.model = null;
      this.submitted = false;
      this.error = false;
    });
  }

  getComments(noCache:boolean = false): void {
    this.commentService.getCommentForArticle(this.articleId, noCache)
    .subscribe(
      comments => {
        this.comments = comments;
        this.commentCount = comments.length;
      }
    );
  }

  get name() {
    if (this.commentForm) {
      return this.commentForm.get('name');
    }
    return null;
  }

  get email() {
    if (this.commentForm) {
      return this.commentForm.get('email');
    }
    return null;
  }

  get commentText() {
    if (this.commentForm) {
      return this.commentForm.get('commentText');
    }
    return null;
  }
}

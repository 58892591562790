declare const $: any;

import { Component, OnInit, Injector, Inject, PLATFORM_ID, } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { NewsArticleService } from '@services/news-article.service';
import { Meta, DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import {NewsArticle} from '@models/newsArticle';
import { CordovaService } from '@services/cordova.service';
import { Globals } from 'app/globals';
import { DeviceDetectorService } from 'ngx-device-detector';
import { switchMap } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {ArticleQuote} from '@models/articleQuote';
import {ArticleSummary} from '@models/articleSummary';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { faLike } from 'assets/icons/like';
import { faDislike } from 'assets/icons/dislike';
import { faLove } from 'assets/icons/love';
import { faTrust } from 'assets/icons/trust';
import { faFactcheck } from 'assets/icons/factcheck';

// Javascript needed for the media.net adverts to be displayed
declare const storyPageSidePageAdvert300x600: any;
declare const bannerAdTest: any;
declare const storyPageMiddlePageAdvert728x90: any;

@Component({
  selector: 'csl-story-page',
  templateUrl: './story-page.component.html',
  styleUrls: ['./story-page.component.css']
})
export class StoryPageComponent implements OnInit {
  onCordova = false;
  pageUrl = '';
  androidPath = '';
  deviceOS = '';
  onMobile = false;
  onTablet = false;
  newsArticle: NewsArticle;
  articleSectionMaped: string;
  newsArticles: NewsArticle[];
  relatedNewsArticles: NewsArticle[]; 
  storyQuotes: ArticleQuote[];
  storySummaries: ArticleSummary[];
  advertisement1: string;
  advertisement2: string;
  subscribeSuccess = '';
  subscribeError = false;
  public emailForm: UntypedFormGroup;
  faLike = faLike;
  faDislike = faDislike;
  faLove = faLove;
  faTrust = faTrust;
  faFactcheck = faFactcheck;
  isBrowser = false;

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    autoplay: true,
    nav: false,
    rewind: true,
    items: 1
  };

  constructor(private route: ActivatedRoute,
              private newsArticleService: NewsArticleService,
              private cordovaService: CordovaService,
              private router: Router,
              private meta: Meta,
              private injector: Injector, @Inject(PLATFORM_ID) private platformId: Object,
              public globals: Globals,
              private deviceService: DeviceDetectorService,
              private domSanitizer: DomSanitizer,
              private formBuilder: UntypedFormBuilder) {
                this.onCordova = this.cordovaService.onCordova;
                this.deviceOS = deviceService.os;
                this.onMobile = deviceService.isMobile();
                this.onTablet = deviceService.isTablet();
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.advertisement1 ='"526582645", "728x90", "526582645"';
                this.advertisement2 ='"674508636", "300x600", "674508636"';
  }

  ngOnInit() {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.retrieveStoryDetails();
    this.emailForm = this.createSignupForm();
    this.onChanges();
  }

  retrieveStoryDetails() {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.newsArticleService.getNewsArticle(params.get('story')))
    ).subscribe(newsArticle => {
      if (this.cordovaService.onCordova) {
        this.cordovaService.setScreenName('StoryPage');
        this.cordovaService.logEvent('select_content', {content_type: 'news article', item_id: newsArticle.id});
        this.pageUrl = this.globals.path + this.router.url;
      } else {
        this.pageUrl = this.getApplicationUrl() + this.router.url;
      }
      this.androidPath = 'android-app://net.chinaspotlight.app.android/https/chinaspotlight.net' + this.router.url;
      this.meta.updateTag({property: 'og:url', content: this.pageUrl});
      this.meta.updateTag({property: 'og:image', content: newsArticle.articleImage});
      this.meta.updateTag({property: 'og:title', content: newsArticle.articleTitle});
      this.meta.updateTag({property: 'og:description', content: newsArticle.paragraph1});
      this.meta.updateTag({property: 'og:image:width', content: (newsArticle.imageWidth || '').toString()});
      this.meta.updateTag({property: 'og:image:height', content: (newsArticle.imageHeight || '').toString()});
      this.newsArticle = newsArticle;
      this.articleSectionMaped = this.newsArticleService.getMapSectionDescription(this.newsArticle.articleSection);
      if (this.isBrowser) {
        this.retrieveStorySummary(this.newsArticle.id);
        this.retrieveStoryQuotes(this.newsArticle.id);
          this.getTopStories();
          if (this.newsArticle.articleSection !=="Editorial") {
          this.retrieveRelatedStoryDetails();
        }
      }
      this.initSticky();
    });
  }

  // Get Stories for footer
  getTopStories(): void {
    // If a section is passed in then pass it to the web service. <-- removed checking because service will be able to handle empty section
    this.newsArticleService.getSectionArticles()
    .subscribe(
      newsArticles => {
        this.newsArticles = newsArticles;
        if (this.newsArticle.relArticleId) {
          this.retrieveRelatedStory(this.newsArticle.relArticleId.toString());
        } else {
          this.retrieveRandomNineCommentaries();
        }
        this.retrieveEditorial();
      }
    );
  }

  retrieveRelatedStoryDetails() {
    // If a section is passed in then pass it to the web service. <-- removed checking because service will be able to handle empty section
    this.newsArticleService.getSectionArticles(this.newsArticle.articleSection)
    .subscribe(
      newsArticles => {
        this.relatedNewsArticles = newsArticles;
      }
    );
  }

  retrieveRelatedStory(articleId : string) {
    // If a section is passed in then pass it to the web service. <-- removed checking because service will be able to handle empty section
    this.newsArticleService.getNewsArticleById(articleId)
    .subscribe(
      newsArticle => {
        this.prependTopStories(newsArticle);
      }
    );
  }

  retrieveRandomNineCommentaries() {
    // If a section is passed in then pass it to the web service. <-- removed checking because service will be able to handle empty section
    this.newsArticleService.getRandomNineCommentaries(true)
    .subscribe(
      newsArticle => {
        this.prependTopStories(newsArticle);
      }
    );
  }

  retrieveEditorial() {
    // If a section is passed in then pass it to the web service. <-- removed checking because service will be able to handle empty section
    this.newsArticleService.getArticleHistory(this.globals.articleSecionEditorial, 1, 1)
    .subscribe(
      newsArticle => {
        newsArticle[0].articleImage = "./assets/images/others/editorial.png";
        newsArticle[0].routeGoal = this.globals.routeGoalStoryToEditorial;
        this.appendTopStories(newsArticle);
      }
    );
  }

  prependTopStories(newsArticle: NewsArticle) {
    // Shallow clone to prevent cached news article's section to be replaced
    let clonedNewsArticle = { ...newsArticle };
    clonedNewsArticle.routeGoal = this.globals.routeGoalNineCommentary;
    this.newsArticles.unshift(clonedNewsArticle);
  }

  appendTopStories(newsArticles: NewsArticle[]) {
    //remove last article from top stories
    this.newsArticles.pop();
    //add editorial as last story in top stories
    this.newsArticles.push(...newsArticles);
  }

  retrieveStoryQuotes(articleId : number) {
    // If a section is passed in then pass it to the web service. <-- removed checking because service will be able to handle empty section
    this.newsArticleService.getArticleQuotes(articleId.toString())
    .subscribe(
      storyQuotes => {
        storyQuotes.map(item => {
          if (item.weight > 0) {
            switch (item.weightType) {
              case this.globals.quoteLikeTypeLike:
                item.likeCount += item.weight;
                break;
              case this.globals.quoteLikeTypeDislike:
                item.dislikeCount += item.weight;
                break;
              case this.globals.quoteLikeTypeLove:
                item.loveCount += item.weight;
                break;
              case this.globals.quoteLikeTypeTrust:
                item.trustCount += item.weight;
                break;
              case this.globals.quoteLikeTypeFactcheck:
                item.factcheckCount += item.weight;
                break;
            }
          }
        });
        this.storyQuotes = storyQuotes;
      }
    );
  }

  retrieveStorySummary(articleId : number) {
    // If a section is passed in then pass it to the web service. <-- removed checking because service will be able to handle empty section
    this.newsArticleService.getArticleSummaries(articleId.toString())
    .subscribe(
      storySummaries => {
        this.storySummaries = storySummaries;
      }
    );
  }

  public getApplicationUrl(): string {
    let url = '';
    if (isPlatformServer(this.platformId)) {
        const request = this.injector.get(REQUEST);
        url = 'https://' + request.get('host');
    } else if (isPlatformBrowser(this.platformId)) {
        const location = window.location;
        url = 'https://' + location.host;
    }

    return url;
  }

  createSignupForm(): UntypedFormGroup {
    return this.formBuilder.group(
      {
        email: [
          null,
          Validators.compose([Validators.email, Validators.required])
        ]
      }
    );
  }

  get email() {
    if (this.emailForm) {
      return this.emailForm.get('email');
    }
    return null;
  }

  onSubmit () {
    this.subscribeError = false;
    this.subscribeSuccess = '';
    console.log('email='+this.emailForm.get('email').value);
    this.newsArticleService.postSubscription(this.emailForm.get('email').value).subscribe(
      val => {
        console.log('Response=' + JSON.stringify(val));
        this.subscribeSuccess = "Successfully subscribed. Thank you!";
      }, err => {
        if (err.error.status == 409) {
          this.subscribeSuccess = "Thank you, you have already subscribed to our newsletter!";
        } else {
          this.subscribeError = true;
        }
      }
    );
  }

  onChanges(): void {
    this.emailForm.valueChanges.subscribe(_val => {
      this.subscribeError = false;
      this.subscribeSuccess = '';
    });
  }

  public onClickMe(strUrl : string) {
    this.cordovaService.openLinkInBrowser(strUrl);
  }
  public onClickMeSystem(strUrl : string) {
    this.cordovaService.openLinkInBrowserSystem(strUrl);
  }

  public onClickMobile(strUrl : string) {
    this.cordovaService.shareInMobileApp(strUrl);
  }
  public sanitizeUrl(url: string): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
  public postQuoteLike(articleQuote : ArticleQuote, likeType : string) {
    articleQuote.isDisabled = true;
    this.newsArticleService.postQuoteLike(articleQuote.id, likeType).subscribe(
      replyData => {
        articleQuote.likeCount = replyData.likeCount;
        articleQuote.dislikeCount = replyData.dislikeCount;
        articleQuote.loveCount = replyData.loveCount;
        articleQuote.trustCount = replyData.trustCount;
        articleQuote.factcheckCount = replyData.factcheckCount;
        if (replyData.weight > 0) {
          switch (replyData.weightType) {
            case this.globals.quoteLikeTypeLike:
              articleQuote.likeCount += replyData.weight;
              break;
            case this.globals.quoteLikeTypeDislike:
              articleQuote.dislikeCount += replyData.weight;
              break;
            case this.globals.quoteLikeTypeLove:
              articleQuote.loveCount += replyData.weight;
              break;
            case this.globals.quoteLikeTypeTrust:
              articleQuote.trustCount += replyData.weight;
              break;
            case this.globals.quoteLikeTypeFactcheck:
              articleQuote.factcheckCount += replyData.weight;
              break;
          }
        }
      }
    );
  }

  initSticky() {
    if (isPlatformBrowser(this.platformId)) {
      $(document).ready(function () {
        $('.tr-sticky')
            .theiaStickySidebar({
                additionalMarginTop: 0
            });
      });
    }
  }
}

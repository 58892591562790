<div id="main-wrapper" class="homepage-two fixed-nav">
  <csl-top-slim-banner></csl-top-slim-banner>
  <div id="navigation">
    <csl-top-logo-banner></csl-top-logo-banner>
    <csl-top-navigation-menu></csl-top-navigation-menu>
  </div>

  <div *ngIf="newsArticle" id="main-wrapper" class="page">
    <div class="container">

      <div class="page-breadcrumbs">
        <h1 class="section-title">{{newsArticle?.articleSection}}</h1>
        <div class="world-nav cat-menu">
          <ul class="list-inline">
            <li ><a  routerLink="/home" routerLinkActive="active">Home</a></li>
            <li class="active"><a routerLink="/section/{{newsArticle?.articleSection}}" routerLinkActive="active">{{newsArticle?.articleSection}}</a></li>
          </ul>
        </div>
      </div>
      <div class="section">
        <div class="row">
          <div class="col-md-8 col-lg-9">
            <div id="site-content" class="site-content">
              <div class="row">
                <div class="col">
                  <div class="left-content">
                    <div class="details-news">
                      <div class="post">
                        <div class="entry-header">
                          <div class="entry-thumbnail">
                            <img class="img-fluid" src='{{newsArticle?.articleImage}}' alt="" />
                          </div>
                        </div>
                        <div  class="post-content">
                          <div class="entry-meta">
                            <ul class="list-inline">
                              <li class="publish-date"><a href="#"><span class="fa fa-clock-o"></span>{{newsArticle?.dateLoaded | dateAgo}}</a></li>
                              <li class="views"><a href="#"><span class="fa fa-eye"></span>{{newsArticle?.viewsCount}}</a></li>
                              <li class="loves"><a href="#"><span class="fa fa-heart-o"></span>{{newsArticle?.likesCount}}</a></li>
                              <li class="comments"><span class="fa fa-comment-o"></span><a href="#">{{newsArticle?.commentCount}}</a></li>
                            </ul>
                          </div>
                          <h2 class="entry-title">
                            {{newsArticle?.articleTitle}}
                          </h2>
                          <div class="entry-content">
                            <ng-container *ngIf="storySummaries.length > 0">
                              <div class="border border-danger summary-border">
                                <h2>Key Takeaways</h2>
                                <ng-container *ngFor="let storySummary of storySummaries;">
                                  <div class="inner-container">
                                    <div class="inner-row">
                                      <div class="inner-container">
                                        <blockquote class="summary-card">
                                          <p>{{storySummary.text}}</p>
                                        </blockquote>
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </ng-container>
                            <div class="article-paragraph" *ngIf="!newsArticle?.articleText">
                              <p [ngClass]="{'article-first-paragraph': storySummaries.length > 0}" *ngIf="newsArticle?.paragraph1">{{newsArticle?.paragraph1}}</p>
                              <p *ngIf="!newsArticle?.paragraph1">{{newsArticle?.articleIntro}}...</p>
                              <p *ngIf="newsArticle?.paragraph2">{{newsArticle?.paragraph2}}</p>
                              <p *ngIf="newsArticle?.paragraph3">{{newsArticle?.paragraph3}}</p>
                            </div>
                            <div *ngIf="newsArticle?.articleText" [innerHTML]="newsArticle.articleText | safeHtml"></div>


                            <ng-container *ngFor="let storyQuote of storyQuotes; let i = index; let last = last;">
                              <div class="inner-container">
                                <div class="inner-row">
                                  <div class="inner-container">
                                    <blockquote class="quote-card">
                                      <p>{{storyQuote.quote}}</p>
                                      <cite>{{storyQuote.person}}</cite>
                                      <div class="loves">
                                        <button type="button" [disabled]="storyQuote.isDisabled" (click)="postQuoteLike(storyQuote, globals.quoteLikeTypeLike)" class="btn btn-light btn-xs" data-toggle="tooltip" title="Like"><fa-icon [icon]="faLike" matSuffix></fa-icon> {{storyQuote.likeCount}}</button>
                                        <button type="button" [disabled]="storyQuote.isDisabled" (click)="postQuoteLike(storyQuote, globals.quoteLikeTypeDislike)" class="btn btn-light btn-xs leftMargin" data-toggle="tooltip" title="Dislike"><fa-icon [icon]="faDislike" matSuffix></fa-icon> {{storyQuote.dislikeCount}}</button>
                                        <button type="button" [disabled]="storyQuote.isDisabled" (click)="postQuoteLike(storyQuote, globals.quoteLikeTypeLove)" class="btn btn-light btn-xs leftMargin" data-toggle="tooltip" title="Love"><fa-icon [icon]="faLove" matSuffix></fa-icon> {{storyQuote.loveCount}}</button>
                                        <button type="button" [disabled]="storyQuote.isDisabled" (click)="postQuoteLike(storyQuote, globals.quoteLikeTypeTrust)" class="btn btn-light btn-xs leftMargin" data-toggle="tooltip" title="Trust"><fa-icon [icon]="faTrust" matSuffix></fa-icon> {{storyQuote.trustCount}}</button>
                                        <button type="button" [disabled]="storyQuote.isDisabled" (click)="postQuoteLike(storyQuote, globals.quoteLikeTypeFactcheck)" class="btn btn-light btn-xs leftMargin" data-toggle="tooltip" title="Fact Check"><fa-icon [icon]="faFactcheck" matSuffix></fa-icon> {{storyQuote.factcheckCount}}</button>
                                      </div>
                                      <a *ngIf="last" class="btn btn-outline-success btn-sm btn-block readmore" role="button" aria-pressed="true" [routerLink]="['/topQuotes']" [queryParams]="{goal: globals.routeGoalStoryToQuotes}">Continue to read all quotes of the week</a>
                                    </blockquote>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                            
                            <div class="text-center">
                              <a *ngIf="!onCordova && newsArticle.articleSection != 'Editorial'" href="{{newsArticle?.articleUrl}}" target="_blank" rel="noopener" class="btn btn-outline-secondary btn-sm btn-block" role="button" aria-pressed="true">Continue reading on {{newsArticle?.articleDomain}}</a>
                              <a *ngIf="onCordova" (click)="onClickMe(newsArticle.articleUrl)" class="btn btn-outline-secondary btn-sm btn-block" role="button" aria-pressed="true">Continue reading on {{newsArticle?.articleDomain}}</a>
                            </div>
                            <div class="borderbox">
                              <form (ngSubmit)="onSubmit()" [formGroup]="emailForm">
                                <div class="row">
                                  <div class="col-sm-3">
                                    <div>
                                      China Spotlight Insider Newsletter
                                    </div>
                                  </div>
                                  <div class="col-sm-5 col-md-5 col-lg-6 col-xl-7">
                                    <div>
                                      <input type="text" class="form-control" placeholder="Enter your email" name="email" formControlName="email">
                                      <div *ngIf="email.invalid && (email.dirty || email.touched)"
                                        class="alert alert-danger">
                                        <div *ngIf="email.errors.required">
                                          Email is required.
                                        </div>
                                        <div *ngIf="email.errors.email">
                                          Email must be in the correct format.
                                        </div>
                                        <div *ngIf="email.errors?.exmailExist">
                                          Email already subscribed.
                                        </div>    
                                      </div>
                                    </div>
                                  </div>
                                  <div class="mt-1 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                                    <div>
                                      <button class="btn btn-success" [disabled]="!emailForm.valid || subscribeError || subscribeSuccess" type="submit" name="subscribe">Subscribe</button>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-sm-12" *ngIf="subscribeError">
                                    <div class="alert alert-danger">
                                      Sorry, you might have previously unsubcribed from our newsletter and you will need to use following link to re-subscribe: <a target="_blank" href="http://eepurl.com/hA5wm5" rel="noopener">Re-Subscribe to newsletter</a>
                                    </div>
                                  </div>
                                  <div class="col-sm-12" *ngIf="subscribeSuccess">
                                    <div class="alert alert-success">
                                        {{subscribeSuccess}}
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                            
                            <div class="list-inline share-link">
                              <share-buttons *ngIf="!onCordova" url="{{pageUrl}}" theme="material-dark" exclude="['google', 'pinterest', 'messenger', 'sms', 'tumblr']" show="2"></share-buttons>
                              <button *ngIf="onCordova" type="button" class="btn btn-danger" (click)="onClickMobile(pageUrl)">Share</button>
                            </div>
                          </div>
                        </div>
                      </div><!--/post-->
                    </div><!--/.section-->
                  </div><!--/.left-content-->
                </div>
              </div>
            </div><!--/#site-content-->

            <div class="row">
              <div class="col-sm-12">
                <div class="post google-add">
                  <div class="add inner-add text-center" id="526582645">
                    <csl-media-net-ad [advertisement]="advertisement1">
                    </csl-media-net-ad>
                  </div><!--/.section-->
                </div><!--/.google-add-->

              <csl-comment-widget *ngIf="newsArticle"></csl-comment-widget>
              <csl-story-page-section *ngIf="newsArticles" sectionTitle="Top Stories" [newsArticles]="newsArticles" [routeGoal]=globals.routeGoalTopStories></csl-story-page-section>
              <csl-story-page-section *ngIf="relatedNewsArticles" sectionTitle="More In {{articleSectionMaped}}" [newsArticles]="relatedNewsArticles"  [routeGoal]=globals.routeGoalSectionStories></csl-story-page-section>
              </div>
            </div>
          </div><!--/.col-sm-9 -->

          <div class="col-md-4 col-lg-3 tr-sticky">
            <div id="sitebar" class="theiaStickySidebar">
              <div class="widget">
                <div class="add featured-add" id="674508636">
                  <csl-media-net-ad [advertisement]="advertisement2">
                  </csl-media-net-ad>
                </div>
              </div><!--/#widget-->

              <div class="widget follow-us">
                <h1 class="section-title title">Follow Us</h1>
                <ul class="list-inline social-icons">
                  <li><a href="https://www.facebook.com/chinaspotlights"><span class="fa fa-facebook-square fa-2x"></span></a></li>
                  <li><a href="https://twitter.com/chinaspotlight1"><span class="fa fa-twitter-square fa-2x"></span></a></li>
                </ul>
              </div><!--/#widget-->

              <csl-this-is-rising *ngIf="isBrowser"></csl-this-is-rising>
            </div><!--/#sitebar-->
          </div>
        </div>
      </div><!--/.section-->
    </div><!--/.container-->
  </div><!--/#main-wrapper-->
  <csl-footer *ngIf="isBrowser" [newsArticles]="newsArticles"></csl-footer>
</div>  

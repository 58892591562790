<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
      <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
        <li class="nav-item">
          <a class="nav-link" routerLink="/manageStories" routerLinkActive="active">Stories</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/editQuotes" routerLinkActive="active">Quotes</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/manageVideos" routerLinkActive="active">Videos</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/manageComments" routerLinkActive="active">Comments</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/manageSubscribers" routerLinkActive="active">Subscribers</a>
        </li>
        <li *ngIf="userRole === 'SystemAdmin'" class="nav-item">
            <a class="nav-link" routerLink="/manageUsers" routerLinkActive="active">Users</a>
        </li>
        <li *ngIf="userRole === 'SystemAdmin'" class="nav-item">
          <a class="nav-link" routerLink="/changePassword"><i class="fa fa-key fa-lg" style="color:#DDDDDD;" aria-hidden="true" title="Change password"></i></a>
        </li>
        <li *ngIf="userRole === 'SystemAdmin'" class="nav-item">
          <a class="nav-link" (click)="this.logOut()"><i class="fa fa-sign-out fa-lg" style="color:#DDDDDD;" aria-hidden="true" title="Log out"></i></a>
        </li>
      </ul>
    </div>
  </nav>
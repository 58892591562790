import { Component, Inject, PLATFORM_ID, Injector } from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Meta } from '@angular/platform-browser';
import { CordovaService } from '@services/cordova.service';

declare let gtag: Function;

@Component({
  selector: 'csl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  onCordova = false;
  title = 'csl-app';
  constructor(private injector: Injector, @Inject(PLATFORM_ID) private platformId: Object,
              private router: Router,
              private meta: Meta,
              private cordovaService: CordovaService) {

    this.onCordova = this.cordovaService.onCordova;
    console.log('onCordova? ' + this.onCordova);

    if (isPlatformBrowser(this.platformId) && !this.onCordova) {
      console.log('Registering gtag');
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
            gtag('config', 'G-LEHX3SRZ1M',
                  {
                    page_path: event.urlAfterRedirects
                  }
                  );
          }
      }
    )}
    if (this.onCordova) {
      console.log('calling initFirebase');
      this.cordovaService.initFirebase();
    }

    this.meta.updateTag({property: 'og:url', content: this.getApplicationUrl()});
    this.meta.updateTag({property: 'og:image', content: this.getApplicationUrl() + '/assets/images/presets/preset1/China-Spotlight-Logo.png'});
    this.meta.updateTag({property: 'og:title', content: 'China Spotlight'});
    this.meta.updateTag({property: 'og:description', content: 'Our mission is to serve our readers with news they trust.'});
    this.meta.updateTag({property: 'og:image:width', content: '1600'});
    this.meta.updateTag({property: 'og:image:height', content: '1599'});
  }

  public getApplicationUrl(): string {
    let url = '';
    if (isPlatformServer(this.platformId)) {
        const request = this.injector.get(REQUEST);
        url = 'https://' + request.get('host');
    } else if (isPlatformBrowser(this.platformId)) {
        const location = window.location;
        url = 'https://' + location.host;
    }

    return url;
  }
}

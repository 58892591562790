<csl-admin-header></csl-admin-header>

<div class="container p-3">
    <div class="offset-3 col-6">
      <div class="card">
        <div class="card-header  font-weight-bold">
          Change Password
        </div>
        <div class="card card-body">
          <form [formGroup]="frmChangePassword" (submit)="submit()">
            <div class="form-group">
                <label for="oldPassword" [ngClass]="frmChangePassword.controls['oldPassword'].invalid ? 'text-danger' : ''">Current Password:</label>
                <input id="oldPassword" formControlName="oldPassword" type="password" class="form-control" [ngClass]="frmChangePassword.controls['oldPassword'].invalid ? 'is-invalid' : ''">
                <label class="col" [ngClass]="frmChangePassword.controls['password'].hasError('required') || frmChangePassword.controls['password'].hasError('required')  ? 'text-danger' : 'text-success'">
                  Current Password is required
                </label>
              </div>
            <div class="form-group">
              <label for="password" [ngClass]="frmChangePassword.controls['password'].invalid ? 'text-danger' : ''">Password:</label>
              <input id="password" formControlName="password" type="password" class="form-control" [ngClass]="frmChangePassword.controls['password'].invalid ? 'is-invalid' : ''">
              <label class="col" [ngClass]="frmChangePassword.controls['password'].hasError('required') || frmChangePassword.controls['password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                Must be at least 8 characters!
              </label>
              <label class="col" [ngClass]="frmChangePassword.controls['password'].hasError('required') || frmChangePassword.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                Must contain at least 1 number!
              </label>
              <label class="col" [ngClass]="frmChangePassword.controls['password'].hasError('required') || frmChangePassword.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                Must contain at least 1 in Capital Case!
              </label>
              <label class="col" [ngClass]="frmChangePassword.controls['password'].hasError('required') || frmChangePassword.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                Must contain at least 1 Letter in Small Case!
              </label>
              <label class="col" [ngClass]="frmChangePassword.controls['password'].hasError('required') || frmChangePassword.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">

                Must contain at least 1 Special Character!
              </label>
            </div>
            <div class="form-group">
              <label for="confirmPassword" [ngClass]="frmChangePassword.controls['confirmPassword'].invalid ? 'text-danger' : ''">Confirm
                Password:</label>
              <input id="confirmPassword" formControlName="confirmPassword" type="password" class="form-control"
                [ngClass]="frmChangePassword.controls['confirmPassword'].invalid ? 'is-invalid' : ''">
              <label class="text-danger" *ngIf="frmChangePassword.controls['confirmPassword'].hasError('required')">
                Password is Required!
              </label>
              <label class="text-danger" *ngIf="frmChangePassword.controls['confirmPassword'].hasError('NoPassswordMatch')">
                Password do not match
              </label>
            </div>
            <div class="form-group">
              <button [disabled]="frmChangePassword.invalid" type="submit" class="btn btn-primary btn-block font-weight-bold" (click)="this.toggleSpinner()"><csl-spinner [show]="showSpinner" [size]="150"> </csl-spinner>Update</button>
            </div>
            <div class="alert alert-success" *ngIf="submitted">
              Password Changed successfully
            </div>
            <div class="alert alert-danger" *ngIf="error">
              Sorry we encountered error when trying to register your details. Please try again later!
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
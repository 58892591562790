<a *ngIf="topNewsArticle" [routerLink]="['/story', topNewsArticle?.id, topNewsArticle?.articleTitleSlug]" [queryParams]="{goal: storyRouteGoal}" routerLinkActive="active">
<div class="post feature-post" >
  <div class="entry-header">
    <div class="entry-thumbnail">
      <img class="img-responsive" [ngStyle]="{'max-height': this.articleHeight+'px' }"   src='{{topNewsArticle?.articleImage}}' alt="Image" />
    </div>
    <div *ngIf="showCategory" class='catagory {{topNewsArticle?.articleSection}}'><a href="#">{{topNewsArticle?.articleSection}}</a></div>
  </div>
  <div *ngIf="topNewsArticle?.articleSection != 'Editorial'" class="post-content">
    <div class="entry-meta">
      <ul class="list-inline">
        <li class="publish-date"><span class="fa fa-clock-o"></span><a href="#"> {{topNewsArticle?.dateLoaded | dateAgo}} </a></li>
        <li class="views"><span class="fa fa-eye"></span><a href="#">{{topNewsArticle?.viewsCount}}</a></li>
        <li class="loves"><span class="fa fa-heart-o"></span><a href="#">{{topNewsArticle?.likesCount}}</a></li>
        <li class="comments"><span class="fa fa-comment-o"></span><a href="#">{{topNewsArticle?.commentCount}}</a></li>
        <li ><a href='#'>{{topNewsArticle?.articleDomain}}</a></li>
      </ul>
    </div>
    <h2 class="entry-title">
      {{topNewsArticle?.articleTitle}}
    </h2>
  </div>
</div><!--/post-->
</a>
import { Component, OnInit } from '@angular/core';
import {NewsArticle} from '@models/newsArticle';
import { NewsArticleService } from '@services/news-article.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'csl-preview-story',
  templateUrl: './preview-story.component.html',
  styleUrls: ['./preview-story.component.css']
})
export class PreviewStoryComponent implements OnInit {
  newsArticle: NewsArticle;
  articleId = '';
  isLoading = false;

  constructor(public modal: NgbActiveModal, 
    private newsArticleService: NewsArticleService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.newsArticleService.getNewsArticleById(this.articleId)
    .subscribe(newsArticle => {
      this.newsArticle = newsArticle;
      this.isLoading = false;
    },
    _error => {
      this.isLoading = false;
    });
  }
}

<div id="main-wrapper" class="homepage-two fixed-nav">
  <csl-top-slim-banner></csl-top-slim-banner>
  <div id="navigation">
    <csl-top-logo-banner></csl-top-logo-banner>
    <csl-top-navigation-menu></csl-top-navigation-menu>
  </div><!--/#navigation-->
  <div class="container">
    <div class="col-lg-12 content-section">
      <div class="privacy-content entry-content">


        <h2>This privacy policy is effective as and from January 1st, 2020</h2>

        <h3>Introduction</h3>
        <p>Your privacy is important to China Spotlight and maintaining your trust is paramount to us. We are fully committed to keeping your personal information safe. This privacy policy is intended to provide you with information about the personal information we collect and how that information is used and shared.  We collect personal information about you when you access this website  or when you use any of our products, applications or services (collectively our “Services”).</p>

        <p>This document outlines our approach to compliance with the https://www.dataprotection.ie/en/individuals


          <a href="https://ec.europa.eu/info/priorities/justice-and-fundamental-rights/data-protection/2018-reform-eu-data-protection-rules/eu-data-protection-rules_en/">General Data Protection Regulation (GDPR)</a> and all other relevant data-protection legislation. It also sets forth your privacy rights. Please take a moment to familiarise yourself with our privacy practices.</p>

        <p>It is important that you read this privacy policy together with any other privacy policy we may provide on specific occasions so that you are fully aware of how and why we are using your data. This privacy policy supplements the other notices and is not intended to override them.</p>

        <p>Should you have any questions or comments related to this privacy policy, please contact us at <a href="mailto:dataprotection@chinaspotlight.net?Subject=Data Protection Query" target="_top">DataProtection@ChinaSpotlight.Net</a></p>

        <h5>Changes to this privacy policy</h5>
        <p>We may update this privacy policy from time to time. If we make any material changes, we will notify you prior to the changes taking effect by posting a notice on our websites and, where appropriate, sending you a notification.</p>

        <h5>Who we are and how to contact us</h5>
        <p>Throughout this privacy policy, “we” “us” and “ours” refers to China Spotlight. We are the company that controls your data known as the “ data controller” under data protection law. We can be contacted by email at <a href="mailto:dataprotection@chinaspotlight.net?Subject=Data Protection Query" target="_top">DataProtection@ChinaSpotlight.Net</a></p>

        <h5>Data protection officer</h5>
        <p>We have appointed an internal data protection officer (DPO) for you to contact if you have any questions regarding this privacy policy, our privacy practices or if you wish to exercise your data rights. While we have appointed a DPO, our Customer Care team will be your initial point of contact for all data-protection inquiries. They can be reached by email at dataprotection@chinaspotlight.net</p>

        <h5>Information we collect and how we use it</h5>
        <p>We collect and use your personal information for a variety of reasons including:
        If you register, purchase or subscribe to one of our Services</p>

        <h5>To create an account with us</h5>
        <p>To become a subscriber to China Spotlight or to purchase or subscribe to one of our other Services, you will need to create an account with us.</p>

        <h5>To pay for the Services you have requested from us</h5>
        <p>We will collect your payment information to pay for any Services you have requested from us. Credit and debit card information is processed directly by PCI-compliant payment providers who perform payment services on our behalf. </p>

        <h5>To facilitate registration through social networks</h5>
        <p>We may collect your publically available social media profile details including your name and email address when you sign up for one of our services through a social media account. We may use these details for the purposes of our registration process in order to enter into a contract with you. We may also automatically import your profile photo and membership status of Facebook, LinkedIn and Twitter if you choose to make these details public. Where we do, we do so in pursuit of our legitimate interest in understanding the demographics of our customers and readers.</p>

        <h5>To place an advertisement</h5>
        <p>We may process your personal information if you opt to place an advertisement. We only process this information to deliver the advertising service you have requested as per the terms and conditions you accept.</p>

        <h5>To receive marketing communications</h5>
        <p>We may periodically send promotional emails about features, events, exclusive offers and competitions to the email address you have provided – we will only do so with your consent. We have an interest in making sure our marketing is relevant for you, so we may process your information to send you marketing communications tailored to your interests. You may at any time choose not to receive marketing communications from us by following the unsubscribe instructions included at the end of each email communication. </p>


        <h5>For competition and promotional purposes</h5>
        <p>We regularly run competitions or similar promotions. If you choose to enter a competition or promotion, we will use your personal information to administer these promotions and as per the terms and conditions you accept when you enter. In some cases, your competition entry may be shared with the promoter and this will be specified when you enter.  If you win a competition, your contact information will be used to notify you as the winner and to award the relevant prize. We will publish your name on our website and indicate the prize you have won.

        <h5>To participate in a survey or poll</h5>
        <p>We conduct surveys and polls that we use to produce original research from our readers. Your participation is entirely voluntary. You may consent to China Spotlight contacting you at the email address provided to participate in this research. We do not track individuals but look at the information in the aggregate.  Any opinion you express, therefore, is anonymous.</p>

        <p>From time to time, upon completing a survey or poll, we may ask for your email address for competition, promotional or gifting purposes. Participation is entirely voluntary and this information is processed and stored separately from the information you provide us for the survey or poll. Where you provide this information to us, it will be processed only to administer the competition or promotion.</p>


        <h5>To recommend content</h5>
        <p>We may use aggregate information we collect about you to suggest and recommend tailored content. By understanding which content you find useful or interesting, we can recommend more content that you might also like, as well as continuing to produce the most valuable content to meet your needs. This processing is necessary for the purpose of our legitimate interest in delivering relevant content to our readers.  You have the right to object to this processing, and if you wish to do so please visit the My Preferences section of My Account or contact us at <a href="mailto:dataprotection@chinaspotlight.net?Subject=Data Protection Query" target="_top">DataProtection@ChinaSpotlight.Net</a></p>

        <h5>To provide an alert service</h5>
        <p>China Spotlight offers a number of email alert services.  With your consent, we will process your personal details for the purpose of sending you the alert service you have requested. If you prefer not to receive these alerts, you can opt out at any time by unsubscribing at the bottom of each email communication or logging into the relevant alert service and disabling the alert.</p>

        <h5>To understand how you use our services</h5>
        <p>To track how you gain access to or read our content
        We collect information relating to your use of our services to track how you gain access to or read our content.  We use log-in information, cookies and similar technologies, device information and internet protocol (“IP”) addresses to log your use of our services as follows:</p>

        <h5>To support our advertising services</h5>
        <p>Third-party vendors, including Google, display advertising on our Services. Some of the ads you see are targeted based on interests that have been inferred from the type of content you view when visiting our site or other sites. This type of behavioural advertising is targeted towards groups of users in an anonymous and aggregated manner as opposed to you as an individual. Such online advertising helps support the content, products and services we provide.</p>


        <h5>To apply for a job with us</h5>
        <p>If you apply for a job within China Spotlight Group, you may provide us with certain personal information about yourself. We will use this information for the purpose of processing and responding to your application, to assess your suitability as a potential candidate and for current and future career opportunities.</p>

        <h5>To attend one of our events</h5>
        <p>We host many events throughout the year. These include but are not limited to breakfast briefings, live content events, live lectures, podcast recordings, training, conferences and seminars.</p>


        <h5>To correspond with you</h5>
        <p>If you correspond with us, we will use your contact information to respond to your enquiry. We process this information in pursuit of our legitimate interests to develop and maintain relationships with our customers and readers.</p>


        <h5>To verify your identity</h5>
        <p>To exercise your data rights, we may need to collect additional personal information from you to verify your identify and to process your request.</p>

        <h5>When you provide professional services to us</h5>
        <p>You may provide us with your personal information if you provide professional services to us. We process this information so we can administer, manage and pay for the relevant services you provide and as per the terms and conditions of our contract.</p>


        <h5>Anonymous information we process</h5>
        <p>We process data in a form that does not, on its own, permit direct association with any specific individual and therefore cannot be reasonably used to identify or contact you.  Aggregated and anonymous data is considered non-personal information for the purposes of this privacy policy. </p>


        <h5>The legal basis for processing your information</h5>
        <p>We collect your information for a number of purposes and rely on a number of different legal bases to use your personal information.
        To enter into and perform a contract with you
        We use your personal information to carry out our obligations arising from any contracts entered into between you and us or to take the necessary steps at your request prior to entering into a contract with us including:</p>

        <ul>
        <li>To provide you with the subscription, services or products you have requested</li>
        <li>To process your payment information</li>
        <li>To authenticate and verify your account</li>
        <li>To provide customer and technical support</li>
        <li>To administer competitions or similar promotions</li>
        <li>To process live chat information at your request prior to entering into a subscription contract with us</li>
        <li>To provide our recruitment services to you when requested</li>
        <li>To pay for the services you may professional provide to us</li>
        <li>To send important notices such as communications relating to payment, delivery services, changes to our terms, conditions, and policies and to notify you about changes to our service</li>
        <li>To keep you informed of the benefits of your subscription</li>
        <li>To ensure you get the most out of your subscription</li>
        <li>To deliver exclusive content and benefits to our subscribers</li>
        </ul>
        <p>With your consent, which can be withdrawn at any time
        We will, in certain circumstances, rely on your explicit consent to process your personal information. Where we do, you have the right to withdraw your consent at any time. In most circumstances, your consent can be withdrawn by following the unsubscribe instructions included at the end of each email communication from us. You can also contact us at <a href="mailto:dataprotection@chinaspotlight.net?Subject=Data Protection Query" target="_top">DataProtection@ChinaSpotlight.Net</a> and we will be happy to facilitate your request.
        </p>
        <h5>To comply with our legal obligations</h5>
        <p>We may be required to process your personal information to comply with certain legal obligations to which we are subject, including:</p>

        <p>Providing information to an Garda Síochána, the Revenue Commissioners or other Government bodies or agencies when required to do so by law
        If you have exercised one of your data rights, we will retain a copy of all correspondence to demonstrate our compliance with data-protection legislation
        If you have exercised one of your data rights and ask us not to contact you by email at a particular email address, we will need to retain a copy of that email address in order to comply with your no-contact request</p>
        <p>To carry out our statutory audit
        Processing based on our legitimate interests
        We process your personal information for carefully considered and specific purposes which are in our legitimate interests and enable us to enhance the services we provide, but which we also believe benefit our customers. Our legitimate business interests do not automatically override your interests. You have the right, free of charge, to object to our use of your personal information for our legitimate interests. If you wish to do so contact us at <a href="mailto:dataprotection@chinaspotlight.net?Subject=Data Protection Query" target="_top">DataProtection@ChinaSpotlight.Net</a> Please bear in mind that if you do object, this may affect our ability to carry out certain tasks for your benefit.
        </p>

      <h5>With third parties</h5>
        <p>We may share your personal information with third parties for a number of reasons as outlined below. We do not sell your personal information to anyone and only share it with third parties who are facilitating the delivery or fulfilment of our services and or are working on our behalf. We contractually require that our suppliers protect such information from unauthorised access, use, and disclosure.</p>

        <h5>Third-party service providers</h5>
        <p>We may share your personal information with third-party service providers which perform services and functions at our direction and on our behalf such as our accountants, IT service providers, printers, payment providers, lawyers, providers of security and administration services and other business advisers.</p>

        <h5>Third-party service partners</h5>
        <p>We provide certain services and run promotions in conjunction with third parties and we may share your personal information if the service you have requested is fulfilled by them.  These third parties have their own security and privacy policies. You are advised to review these separately.
        </p>
        <h5>Third-party data controllers</h5>
        <p>We may share your personal information with third parties that may receive and process your personal information as a data controller in their own right. In the context of delivering an order or product to your address, we will share your shipping information with our delivery partners who will use it for the purposes of picking up, delivering and tracking your shipment. Our delivery partners may further use this information to plan delivery routes, to optimise and improve transportation operations and to enhance route and network planning tools such as maps, etc. As such, these third parties act as a data controller in their own right in respect of any shipping information that they hold. You can obtain more information about the delivery partners we use, how they process and treat your personal information and how to exercise your data rights by contacting us at <a href="mailto:dataprotection@chinaspotlight.net?Subject=Data Protection Query" target="_top">DataProtection@ChinaSpotlight.Net</a>
        </p>
        <h5>Third-party advertisers</h5>
        <p>We disclose non-personally identifiable anonymous information to third-party advertisers to help them reach the kind of audience they want to target. We share summary aggregate reports for our advertisers to measure the effectiveness of the ads they have served. These advertising parties may combine the information we send with other information that you’ve provided to them or that they’ve collected from your use of their services.
        </p>
        <h5>Social networking sites</h5>
        <p>Our website and mobile applications use interfaces with social media sites such as Facebook, LinkedIn, Twitter and others. If you choose to like or share information from our sites through these services, you should review the privacy policy of that service. If you are a member of a social media site and logged in, the interfaces configured on our Services allow the social media site to connect your site visit and browsing behaviour to the personal data held on your social media site. China Spotlight can use this information on an aggregate basis to place ads that are based on your interests on these social media sites. These practices are governed by the privacy statements of these social networking sites and you are advised to review these separately.
        </p>
        <h5>Data storage and retention</h5>
        <p>We will retain your personal information as needed to fulfil the purposes for which it was collected. We will retain and use your personal information no longer than is necessary to comply with our business and legal obligations. Where we enter into a legal contract with you, we will hold your personal information for a period of seven years from the date of completion of this contract by reference to the statute of limitation for a legal claim under contract.
        </p>

        <h5>Your data rights</h5>
        <p>You have several rights under data-protection law in relation to how we use your personal information. You have the right, free of charge, to:

        </p><p>Request a copy of the personal information we hold about you in a structured, commonly used and machine readable format
        Rectify any inaccurate personal information we hold about you
        Withdraw your consent where we have relied upon your consent to process your information
        Erase the personal information we hold about you subject to certain exceptions
        If technically feasible, have your personal information transmitted to another data controller in a machine readable format at your request
        Restrict processing of your personal information in certain circumstances
        Object to our use of your personal information for our legitimate interests, for profiling and for direct marketing purposes
        Not be subject to a decision which is based solely on automated processing where that decision produces a legal effect on you or otherwise significantly affects you. We do not make automated decisions of this nature
        </p><p>Lodge a complaint with the appropriate data-protection authority if you have concerns about how we process your personal data
        These rights are in some circumstances limited by data-protection legislation. If you wish to exercise any of these rights please contact us at <a href="mailto:dataprotection@chinaspotlight.net?Subject=Data Protection Query" target="_top">DataProtection@ChinaSpotlight.Net</a> We will take measures to verify your identify which we will do by reference to copies of acceptable identification documentation supplied by you.
        </p>
        <p>We will endeavour to respond to your request within a month.  If we are unable to deal with your request within a month we may extend this period by a further two months and we will contact you and explain why.
        </p>

        <h5>Consequences of not providing information</h5>
        <p>Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.
        </p>

        <h5>Processing for journalistic purposes</h5>
        <p>As a news publishing organisation, China Spotlight processes personal data for the purpose of exercising the right to freedom of expression and information and for journalistic purposes.
        This processing is exempt from compliance with certain obligations of the GDPR.
        </p>
      </div>
    </div>
  </div>

  <csl-footer></csl-footer>


</div><!--/#main-wrapper-->

<div class="topbar">
    <div class="container">
      <div id="date-time"></div>
      <div id="topbar-right">
        <!--div class="searchNlogin">
          <ul>
            <li class="search-icon"><span class="fa fa-search"></span></li>
            <li class="dropdown user-panel"><a href="javascript:void(0);" class="dropdown-toggle" data-bs-toggle="dropdown"><span class="fa fa-user"></span></a>
              <div class="dropdown-menu top-user-section">
                <div class="top-user-form">
                  <form id="top-login" role="form" aria-label="Login form">
                    <div class="input-group" id="top-login-username">
                      <span class="input-group-addon"><img src="./assets/images/others/user-icon.png" alt="Image" /></span>
                      <input type="text" class="form-control" placeholder="Username" required="">
                    </div>
                    <div class="input-group" id="top-login-password">
                      <span class="input-group-addon"><img src="./assets/images/others/password-icon.png" alt="Image" /></span>
                      <input type="password" class="form-control" placeholder="Password" required="">
                    </div>
                    <div>
                      <p class="reset-user">Forgot <a href="#">Password/Username?</a></p>
                      <button class="btn btn-danger" type="submit">Login</button>
                    </div>
                  </form>
                </div>
                <div class="create-account">
                  <a href="#">Create a New Account</a>
                </div>
              </div>
            </li>
          </ul>
          <div class="search">
            <form role="form" aria-label="Search form">
              <input type="text" class="search-form" autocomplete="off" placeholder="Type & Press Enter">
            </form>
          </div>
        </div --><!-- searchNlogin -->
      </div>
    </div>
  </div>

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'csl-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css']
})
export class ManageUsersComponent implements OnInit {
  users: any[];
  showSpinner: boolean;

  constructor(private authenticationService: AuthenticationService, public router: Router) {
    this.showSpinner = false;
  }

  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers(){
    this.showSpinner = true;
    this.authenticationService.getUserWithRoles()
      .subscribe(
      users => {
        this.users = users;
        this.showSpinner = false;
      }
    );
  }

  modifyRole(userId:any, userRole:any){
    this.showSpinner = true;
    this.authenticationService.putUserRole(userId, userRole)
      .subscribe(
      _response => {
        this.loadUsers();
      }
    );
  }

}

<div class="comments-wrapper">
  
  <h1 class="section-title title">
    <p><button type="button" class="btn btn-danger" (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="commentCollapseSection">
            Comments ({{commentCount}})
    </button></p>
  </h1>
  <ul class="media-list" id="commentCollapseSection" [ngbCollapse]="isCollapsed">
    <li *ngFor="let comment of comments" class="media">
      <div class="media-body">
        <h2>{{comment.name}}</h2>
        <h3 class="date">{{comment.date | date:'medium'}}</h3>
        <p>{{comment.commentText}}</p>
      </div>
    </li>
  </ul>

  <div class="comments-box"  id="commentCollapseSection" [ngbCollapse]="isCollapsed">
    <h1 class="section-title title">Leave a Comment</h1>
    <form (ngSubmit)="onSubmit()" [formGroup]="commentForm">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Name</label>
            <input type="text" id="name" class="form-control" formControlName="name" placeholder="Your name" required>
            <div *ngIf="name.invalid && (name.dirty || name.touched)"
              class="alert alert-danger">
              <div *ngIf="name.errors.required">
                Name is required.
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Email</label>
            <input type="text" id="email" class="form-control" formControlName="email" placeholder="Your email" required email>
            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger">
            <div *ngIf="email.errors.required">
              Email is required.
            </div>
            <div *ngIf="email.errors.email">
              Email must be in the correct format.
            </div>
          </div>
          </div>
          
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label>Your Text</label>
            <textarea id="commentText" class="form-control" formControlName="commentText" placeholder="Your comment" rows="5" required></textarea>
            <div *ngIf="commentText.invalid && (commentText.dirty || commentText.touched)" class="alert alert-danger">
            <div *ngIf="commentText.errors.required">
              Comment text is required.
            </div>
            <div *ngIf="commentText.errors.pattern">
              Comments cannot include URLs.
            </div>
          </div>
          </div>
          
          <div class="text-center">
            <button type="submit" class="btn btn-success" [disabled]="!commentForm.valid || submitted">Send</button>
          </div>
          <div class="alert alert-success" *ngIf="submitted">
            Your comment has been successfully sent. Thank you!
          </div>
          <div class="alert alert-danger" *ngIf="error">
            Sorry we encountered error when trying to send your comment. Please try again later!
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
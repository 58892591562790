import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
@Injectable()
export class RoleAuthGuard  {
  constructor(public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const allowedRoles: Array<string> = route.data.allowedRoles;
    if (localStorage.getItem('userToken') != null && allowedRoles.includes(localStorage.getItem('userRole'))) {
      return true;
    } else {
      return false;
    }
  }
}
import { Component, Input } from '@angular/core';
import { NewsArticle } from '@models/newsArticle';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'csl-home-latest-news',
  templateUrl: './home-latest-news.component.html'
})
export class HomeLatestNewsComponent {
  @Input()
  newsArticles: NewsArticle[];

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    autoplay: true,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    }
  };

}
